<template>
    <v-container class="mb-12 mb-lg-0 px-0 pt-0">
        <v-expand-transition>
            <v-banner v-if="idValidator === true">
                <v-icon slot="icon" color="primary" size="36">mdi-card-account-details</v-icon>
                <span v-if="show === false">Completa tus Datos
                    <v-progress-linear v-model="idAdvance" color="primary" height="32" class="mt-2">
                        <strong :class="idAdvance > 49 ? 'white--text' : 'primary--text'">{{ Math.ceil(idAdvance) }}%</strong>
                    </v-progress-linear>
                </span>
                <v-skeleton-loader min-width="300px" type="list-item" v-else/>
                <template v-slot:actions="{ dismiss }">
                    <v-btn text color="success" to="/profile/edit" v-if="show === false">Completar</v-btn>
                    <v-skeleton-loader type="button" min-width="100px" style="" v-else/>
                    <v-btn text color="error" @click="dismiss" v-if="show === false">Cerrar</v-btn>
                    <v-skeleton-loader type="button" min-width="100px" style="" v-else/>
                </template>
            </v-banner>
        </v-expand-transition>
        <v-row justify="center">
            <v-col cols="12" class="pt-0">
                <v-card class="grey lighten-3">
                    <v-skeleton-loader class="mx-auto pa-4" width="100%" type="heading" v-if="loading === true"/>
                    <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2 py-0 py-md-2"
                                  style="word-break: normal; text-transform: capitalize;" v-else>
                        <v-icon>mdi-calendar</v-icon>&nbsp;&nbsp;{{new Date() | moment("dddd - DD/MMMM/YYYY")}}
                    </v-card-title>
                    <v-divider class="mx-2 mx-sm-4"/>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="ma-0" v-if="user.role === 'Customer'">
            <v-col cols="12">
                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-three-line" v-if="loading === true"/>
                <v-card class="bg" link to="/profile/edit" v-else>
                    <v-card-text class="py-1 py-sm-2">
                        <v-list class="pa-0" color="transparent">
                            <v-list-item class="pa-0" three-line>
                                <v-list-item-avatar class="my-0 align-self-center" size="64" style="border: 1px solid #ccc">
                                    <img :src="'/' + (user.photo ? user.photo : (user.role === 'Customer' ? 'img/photo-default.png' : 'img/soporte.jpg' ))" :alt="user.name">
                                </v-list-item-avatar>
                                <v-list-item-content class="pa-0 align-self-center">
                                    <v-list-item-title class="font-weight-bold mb-1 text-sm-h6 text-lg-h5">{{user.name}}</v-list-item-title>
                                    <v-list-item-content :class="(user.status === 103 ? 'success' : (user.status === 102 ? 'info' : (user.status === 104 ? 'error' : 'tertiary'))) + '--text d-inline-block py-0 font-weight-bold mb-1'">
                                        {{ user.status === 103 ? 'Verificado' : (user.status === 102 ? 'En Revisión' : (user.status === 104 ? 'Bloqueado' : 'Sin Verificar'))  }}
                                        <v-icon small :color="(user.status === 103 ? 'success' : (user.status === 102 ? 'info' : (user.status === 104 ? 'error' : 'tertiary')))">{{ 'mdi-'+(user.status === 103 ? 'check' : (user.status === 102 ? 'sync' : (user.status === 104 ? 'close' : 'alert'))) + '-circle' }}</v-icon>
                                    </v-list-item-content>
                                    <v-list-item-subtitle class="font-weight-bold mb-1">ID: {{ user.id === null ? '' : (user.id).toString().padStart(5, 0) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-btn small depressed :color="(user.status === 103 ? 'success' : (user.status === 102 ? 'info' : (user.status === 104 ? 'error' : 'tertiary white--text')))" fab to="/profile/edit">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="ma-0" justify="center" align-content="center" v-if="user.role === 'Customer'">
            <v-col cols="6" sm="4" md="2">
                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line" v-if="loading === true"/>
                <v-card class="px-3 text-center" color="bg" @click="rate_view = true" v-else>
                    <v-icon class="pt-3" size="64">mdi-cash-multiple</v-icon>
                    <v-card-subtitle class="px-0 py-3 d-block">Tasa Actual</v-card-subtitle>
                </v-card>
            </v-col>
            <template v-for="item in actions">
                <v-col cols="6" sm="4" md="2">
                    <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line" v-if="loading === true"/>
                    <v-card class="px-3 text-center" color="bg" :to="item.link" v-else>
                        <v-icon class="pt-3" size="64">mdi-{{ item.icon }}</v-icon>
                        <v-card-subtitle class="px-0 py-3 d-block">{{ item.name }}</v-card-subtitle>
                    </v-card>
                </v-col>
            </template>
            <v-dialog v-model="rate_view" fullscreen v-if="rates">
                <v-card color="card">
                    <v-toolbar dark color="primary" class="px-2">
                        <v-btn icon large @click="rate_view = false" class="bg primary--text">
                            <v-icon>mdi-home</v-icon>
                        </v-btn>
                        <v-toolbar-title class="d-inline-block text-center font-weight-medium pl-0" style="white-space:normal;width:100%">TASA DE CAMBIO</v-toolbar-title>
                        <div class="flex-grow-1"></div>
                        <v-btn icon large @click="rate_view = false" class="bg primary--text">
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="px-0">
                        <v-container>
                            <v-row justify="center">
                                <template v-for="c in rate_countries" v-if="c.code !== user.country">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-simple-table>
                                            <tbody>
                                            <tr class="bg">
                                                <th colspan="2" class="subtitle-1 font-weight-bold text-center">
                                                    <v-avatar size="26px">
                                                        <img :src="'/img/country/' + c.code + '.png'" :title="c.name" :alt="c.name.toLowerCase()"/>
                                                    </v-avatar>&nbsp;&nbsp;{{ c.name }}
                                                </th>
                                            </tr>
                                            <template v-for="i in rates" v-if="i.country_receiver === c.code && i.bank_receiver === c.bank">
                                                <tr v-if="i.currency_send === 'COP'">
                                                    <td class="text-right">{{ Number(i.type === 'sell' ? i.value : (1 / i.value)).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} {{ i.currency_send }}</td>
                                                    <td>1 {{ i.currency_receiver }}</td>
                                                </tr>
                                                <tr v-else>
                                                    <td class="text-right">1 {{ i.currency_send }}</td>
                                                    <td>{{ Number(i.type === 'sell' ? (1 / i.value) : i.value).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 4}) }} {{ i.currency_receiver }}</td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-container grid-list-sm>
                        <v-row justify="center">
                            <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
                                <v-btn to="/about" color="primary" large style="display:inline-grid;white-space:normal;">Como depositar en nuestras cuentas</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row class="ma-0" v-if="user.role === 'Customer'">
            <v-col cols="12">
                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-two-line" v-if="loading === true"/>
                <v-card outlined class="transparent" v-else>
                    <v-card-subtitle class="font-weight-bold pb-2">
                        MIS MOVIMIENTOS <v-btn class="float-right" small text to="/history?tab=transfers">
                        <v-icon small>mdi-list-status</v-icon> Ver Todos</v-btn>
                    </v-card-subtitle>
                    <v-divider/>
                    <v-card-text class="px-0 py-2">
                        <v-skeleton-loader class="mx-auto" width="100%" type="list-item-three-line" v-if="loading === true"/>
                        <h4 class="text-center py-6" v-if="movements.length === 0">No tienes movimientos recientes</h4>
                        <template v-for="item in movements">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">ID: {{ item.id }} ➤ {{ item.status_name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.amount }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ item.date }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-btn small depressed color="tertiary" dark fab :to="'/corrections?ct=true&id=' + item.id" v-if="item.status === 253">
                                    <v-icon>mdi-text-box-edit</v-icon>
                                </v-btn>
                                <v-btn small depressed color="tertiary" dark fab :to="'/corrections?cp=true&id=' + item.payment_id" v-else-if="item.status === 207">
                                    <v-icon>mdi-image-edit</v-icon>
                                </v-btn>
                                <v-btn small depressed color="primary" fab :to="'/history/transfer/' + item.id" v-else>
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </v-list-item>
                            <v-divider/>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="ma-0" justify="center" v-if="user.role === 'Administrator'">
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'VPending' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="82">mdi-bank-transfer-in</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (nPayments).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Verificación Pendiente</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'TPending' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="82">mdi-bank-transfer-out</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (nTransfers).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Transferencias Pendientes</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'General' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="82">mdi-bank-transfer</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (nComplete).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Transferencias Completadas</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'Calculator' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="70">mdi-earth</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (countries.length).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Países Disponibles</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'CPending' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="70">mdi-face-agent</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (nTickets).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Reclamos Pendientes</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="2">
                <v-card outlined class="card" :to="{ name: 'Customers' }">
                    <div class="d-flex flex-no-wrap align-center justify-space-between">
                        <v-avatar class="ml-4" size="82" tile>
                            <v-icon size="70">mdi-account-group</v-icon>
                        </v-avatar>
                        <v-card-text>
                            <div class="text-h3 text-right">{{ (nUsers).toLocaleString("de-DE") }}</div>
                        </v-card-text>
                    </div>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-subtitle class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">Clientes Totales</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mx-sm-4 my-sm-3" v-if="user.role === 'Administrator'" v-show="$vuetify.breakpoint.smAndUp"/>
        <v-row class="ma-0" justify="center" v-if="user.role === 'Administrator'">
            <v-col cols="12" sm="6" lg="4" class="text-center">
                <v-card outlined class="card" :to="{ name: 'Customers' }">
                    <v-card-title class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">
                        <v-icon>mdi-account-group</v-icon>&nbsp;&nbsp;Clientes
                    </v-card-title>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-text class="py-0">
                        <v-boilerplate type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" class="mx-auto" width="100%" v-if="adminShow === true"/>
                        <v-simple-table v-if="adminShow === false">
                            <template v-slot:default>
                                <tbody>
                                <template v-for="i in users">
                                    <tr class="card">
                                        <td>
                                            <v-avatar size="26px">
                                                <img :src="'/img/country/' + i.code + '.png'" :title="i.name" :alt="i.name.toLowerCase()" v-if="i.code"/>
                                                <v-icon large v-else>mdi-earth</v-icon>
                                            </v-avatar>
                                        </td>
                                        <td>{{ i.name }}</td>
                                        <td class="text-right">
                                            <strong>{{ (i.total).toLocaleString("de-DE") }}</strong>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4" class="text-center">
                <v-card outlined class="card" :to="{ name: 'VPending' }">
                    <v-card-title class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">
                        <v-icon>mdi-swap-vertical-circle-outline</v-icon>&nbsp;&nbsp;Verificación
                    </v-card-title>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-text class="py-0">
                        <v-boilerplate type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" class="mx-auto" width="100%" v-if="adminShow === true"/>
                        <v-simple-table v-if="adminShow === false">
                            <template v-slot:default>
                                <tbody>
                                <template v-for="i in payments">
                                    <tr class="card">
                                        <td>
                                            <v-avatar size="26px">
                                                <img :src="'/img/country/' + i.code + '.png'" :title="i.name" :alt="i.name.toLowerCase()" v-if="i.code"/>
                                                <v-icon large v-else>mdi-earth</v-icon>
                                            </v-avatar>
                                        </td>
                                        <td>{{ i.name }}</td>
                                        <td class="text-right">
                                            <strong>{{ (i.total).toLocaleString("de-DE") }}</strong>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4" class="text-center">
                <v-card outlined class="card" :to="{ name: 'TPending' }">
                    <v-card-title class="d-block text-center font-weight-bold primary--text py-0 py-md-2 trunck-text">
                        <v-icon>mdi-swap-horizontal-circle-outline</v-icon>&nbsp;&nbsp;Transferencias
                    </v-card-title>
                    <v-divider class="mx-2 mx-sm-4"/>
                    <v-card-text class="py-0">
                        <v-boilerplate type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" class="mx-auto" width="100%" v-if="adminShow === true"/>
                        <v-simple-table v-if="adminShow === false">
                            <template v-slot:default>
                                <tbody>
                                <template v-for="i in transfers">
                                    <tr class="card">
                                        <td>
                                            <v-avatar size="26px">
                                                <img :src="'/img/country/' + i.code + '.png'" :title="i.name" :alt="i.name.toLowerCase()" v-if="i.code"/>
                                                <v-icon large v-else>mdi-earth</v-icon>
                                            </v-avatar>
                                        </td>
                                        <td>{{ i.name }}</td>
                                        <td class="text-right">
                                            <strong>{{ (i.total).toLocaleString("de-DE") }}</strong>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../helpers/form';

    export default {
        name: "Home",
        mixins: [ Form ],
        props: [ 'user' ],
        components: {
            VBoilerplate: {
                functional: true,

                render(h, {data, props, children}) {
                    return h('v-skeleton-loader', {
                        ...data,
                        props: {
                            boilerplate: true,
                            elevation: 0,
                            ...props,
                        },
                    }, children)
                },
            }
        },
        data: () => ({
            loading: true,
            show: true,
            idValidator: false,
            idAdvance: 0,
            actions: [
                { name: "Información", icon: "information", link: "about" },
                { name: "Correcciones", icon: "receipt-text-edit", link: "corrections" },
                { name: "Reclamos", icon: "format-list-bulleted", link: "claims" },
                { name: "Oficinas", icon: "store-marker", link: "offices" },
                { name: "Térm. & Cond.", icon: "script-text", link: "legal" },
            ],
            movements: null,
            rate_view: false,
            rate_countries: [
                { name: 'Venezuela (Banesco)', code: 'VEN', bank: '0134' },
                { name: 'Venezuela (Otros)', code: 'VEN', bank: null },
                { name: 'Colombia', code: 'COL', bank: null },
                { name: 'Perú', code: 'PER', bank: null },
                { name: 'Chile', code: 'CHL', bank: null },
                { name: 'Brasil', code: 'BRA', bank: null },
                { name: 'Ecuador', code: 'ECU', bank: null },
                { name: 'México', code: 'MEX', bank: null },
                { name: 'Panamá', code: 'PAN', bank: null },
                { name: 'Estados Unidos', code: 'USA', bank: null }
            ],
            rates: [],
            adminShow: true,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' },
            ],
            users: [],
            payments: [],
            transfers: [],
            nUsers: 0,
            nPayments: 0,
            nTransfers: 0,
            nComplete: 0,
            nTickets: 0
        }),
        created() {
            this.getData();
        },
        watch: {
            user() {
                this.show = true;
                this.getData();
            }
        },
        methods: {
            getData() {
                this.loading = true;
                axios.get('/api/home').then((res) => {
                    this.movements = res.data.transfers;
                    this.rates = res.data.rates;
                    this.show = false;
                    if (this.$props.user.role === 'Customer' && this.$props.user.status === 101 && this.$props.user) {
                        this.idValidator = true;
                        this.idAdvance = 0;
                        const y = 100/12;
                        if (this.$props.user.photo !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.front !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.post !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.td !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.nd !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.name !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.email !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.birthday !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.expiration !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.citizenship !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.phone !== null)
                            this.idAdvance = this.idAdvance + y;
                        if (this.$props.user.country !== null)
                            this.idAdvance = this.idAdvance + y;
                    }
                    if (this.$props.user.role === 'Administrator') {
                        axios.get('/api/admin/home').then((res) => {
                            const countries = this.countries;
                            const a = res.data.users;
                            const b = res.data.payments;
                            const c = res.data.transfers;

                            let users = [];
                            for (let i = 0; i < countries.length; i++) {
                                let n = 0;
                                for (let e = 0; e < a.length; e++) {
                                    if (countries[i].code === a[e].code) {
                                        n = a[e].total;
                                    }
                                }
                                users.push({
                                    code: countries[i].code,
                                    name: countries[i].name,
                                    total: n
                                })
                            }

                            let payments = [];
                            for (let i = 0; i < countries.length; i++) {
                                let n = 0;
                                for (let e = 0; e < b.length; e++) {
                                    if (countries[i].code === b[e].code) {
                                        n = b[e].total;
                                    }
                                }
                                payments.push({
                                    code: countries[i].code,
                                    name: countries[i].name,
                                    total: n
                                })
                            }

                            let transfers = [];
                            for (let i = 0; i < countries.length; i++) {
                                let n = 0;
                                for (let e = 0; e < c.length; e++) {
                                    if (countries[i].code === c[e].code) {
                                        n = c[e].total;
                                    }
                                }
                                transfers.push({
                                    code: countries[i].code,
                                    name: countries[i].name,
                                    total: n
                                })
                            }

                            this.users = users;
                            this.payments = payments;
                            this.transfers = transfers;
                            this.nUsers = res.data.nUsers;
                            this.nPayments = res.data.nPayments;
                            this.nTransfers = res.data.nTransfers;
                            this.nComplete = res.data.nComplete;
                            this.nTickets = res.data.nTickets;
                            this.adminShow = false;
                        });
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getData, 240000);
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>
.trunck-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
