import "babel-polyfill";

require("./bootstrap");

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import VueSweetalert2 from "vue-sweetalert2";
import VueChatScroll from 'vue-chat-scroll';
import VCurrencyField from 'v-currency-field';
import VueMoment from 'vue-moment';
import CKEditor from 'ckeditor4-vue';
import JsonViewer from 'vue-json-viewer';

window.Vue = require("vue");

const moment = require('moment');
require('moment/locale/es');

Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(VuetifyToast, {
    icon: 'mdi-information',
    timeout: 5000,
    closeIcon: 'mdi-close'
});
Vue.use(VueSweetalert2, {
    confirmButtonColor: colors.green.darken3,
    cancelButtonColor: colors.red.darken3,
    showCancelButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    reverseButtons: true
});
Vue.use(VueChatScroll);
Vue.use(VCurrencyField, {
    locale: 'de-DE',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
});
Vue.use(VueMoment, { moment });
Vue.use(Vuex);
Vue.use(CKEditor);
Vue.use(JsonViewer);

import routes from "./routes";
import store from "./store";
import App from "./components/AppComponent";
import Es from './i18n/es';

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title + ' | iCoin App';

   if (store.getters['token'] && !store.getters['check']) {
       try {
           await store.dispatch('fetchUser');
       } catch (e) {}
   }

   let route = reroute(to);
   if (route) {
        next(route);
   } else {
       next();
   }
});

const rules = {
    guest: { fail: 'Home', check: () => (!store.getters['check']) },
    auth: { fail: 'Login', check: () => (store.getters['check']) }
};

function reroute(to) {
    let failRoute = false,
        checkResult = false;

    to.meta.rules && to.meta.rules.forEach(rule => {
        let check = false;
        if (Array.isArray(rule)) {
            let checks = [];
            for (let i in rule) {
                checks[i] = rules[rule[i]].check();
                check = check || checks[i];
            }
            if (!check && !failRoute) {
                failRoute = rules[rule[checks.indexOf(false)]].fail;
            }
        } else {
            check = rules[rule].check();
            if (!check && !failRoute) {
                failRoute = rules[rule].fail;
            }
        }

        checkResult = checkResult && check;
    });

    const lp = window.location.pathname;
    const lq = window.location.search;

    if (!checkResult && failRoute) {
        if (failRoute === 'Login') {
            return {name: failRoute, query: {to: lp + lq}};
        } else {
            return {name: failRoute};
        }
    }

    return false;
}

export default router;

export const app = new Vue({
    el: '#app',
    vuetify: new Vuetify({
        breakpoint: {
            scrollBarWidth: 12,
        },
        theme: {
            dark: false,
            default: 'light',
            disable: false,
            options: {
                cspNonce: undefined,
                customProperties: undefined,
                minifyTheme: undefined,
                themeCache: undefined
            },
            themes: {
                light: {
                    primary: '#1D1D1B',
                    secondary: colors.amber.darken2,
                    tertiary: colors.amber.darken3,
                    accent: colors.lightBlue.accent4,
                    info: colors.blue.darken4,
                    error: colors.red.darken3,
                    success: colors.green.darken3,
                    warning: colors.amber.darken1,
                    card: colors.grey.lighten4,
                    bg: colors.grey.lighten3,
                    disabled: colors.grey.darken4
                },
                dark: {
                    primary: '#4E4E4E',
                    secondary: colors.amber.darken2,
                    tertiary: colors.amber.darken3,
                    accent: colors.lightBlue.accent4,
                    info: colors.blue.darken4,
                    error: colors.red.darken3,
                    success: colors.green.darken3,
                    warning: colors.amber.darken1,
                    card: colors.grey.lighten4,
                    bg: colors.grey.lighten3,
                    disabled: colors.grey.darken4
                }
            },
        },
        rtl: false,
        lang: {
            locales: { Es },
            current: 'Es'
        },
        icons: {
            iconfont: 'mdi'
        },
    }),
    router,
    store,
    components: { App }
});
