<template>
    <v-btn v-if="facebookAuth" rounded large dark color="blue darken-4" @click="login" class="px-4">
        <v-icon dark>mdi-facebook</v-icon>&nbsp;Facebook
    </v-btn>
</template>

<script>
    export default {
        name: "Facebook",
        computed: {
            facebookAuth: () => window.config.facebook,
            url: () => '/api/auth/facebook'
        },
        mounted() {
            window.addEventListener('message', this.onMessage, false)
        },
        beforeDestroy() {
            window.removeEventListener('message', this.onMessage)
        },
        methods: {
            async login() {
                const newWindow = openWindow('', 'Iniciar Sesión | Facebook');

                newWindow.location.href = await this.$store.dispatch('fetchOauthUrl', {
                    provider: 'facebook'
                });
            },
            /**
             * @param {MessageEvent} e
             */
            onMessage(e) {
                if (e.origin !== window.origin || !e.data.token) { return }

                this.$store.dispatch('saveToken', { token: e.data.token });
                this.$store.dispatch('fetchUser');

                if (this.$route.query.to) {
                    this.$router.push({path: this.$route.query.to});
                } else {
                    this.$router.push({name: 'Home'});
                }
            }
        }
    }
    /**
     * @param url
     * @param title
     * @param {Object} options
     * @return {Window}
     */
    function openWindow (url, title, options = {}) {
        if (typeof url === 'object') {
            options = url;
            url = '';
        }

        options = { url, title, width: 600, height: 720, ...options };

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
        const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
        options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;

        const optionsStr = Object.keys(options).reduce((acc, key) => {
            acc.push(`${key}=${options[key]}`);
            return acc
        }, []).join(',');

        const newWindow = window.open(url, title, optionsStr);

        if (window.focus) {
            newWindow.focus();
        }

        return newWindow;
    }
</script>

<style scoped>

</style>
