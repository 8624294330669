<template>
    <v-container>
        <v-row justify="center">
            <v-col md="10" v-if="user.status !== 103">
                <v-card flat class="px-sm-6 py-sm-4">
                    <v-row justify="center">
                        <v-col md="10" class="text-center">
                            <h2 class="mt-8">No puedes crear pagos en este momento.</h2>
                            <h3 class="mt-4">Debes completar el formulario de verificación para poder realizar pagos.</h3>
                            <v-btn class="my-12" color="primary" to="/profile/edit">Completar Perfil</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col md="10" class="pa-0" v-else>
                <v-card flat width="100%">
                    <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">FORMULARIO DE ENVÍO DE DINERO</v-card-title>
                    <v-card-subtitle class="text-center">Al seleccionar el país destino se abrirá el formulario de envio de dinero</v-card-subtitle>
                    <v-card-text class="py-0 text-center">
                        <v-row justify="center">
                            <v-col md="10" lg="8" class="text-center">
                                <v-select
                                    label="País Destino"
                                    :items="items"
                                    name="country"
                                    v-model="country"
                                    :disabled="loading"
                                    prepend-inner-icon="mdi-map-marker"
                                    item-text="name"
                                    item-value="code"
                                    hide-details
                                    outlined>
                                    <template v-slot:selection="{ item }">
                                        <v-flex>
                                            <v-avatar size="28px" class="mr-2">
                                                <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                            </v-avatar>{{ item.name }}
                                        </v-flex>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-avatar>
                                            <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"/>
                                        </v-list-item-content>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" v-if="country">
            <v-col md="10" class="pt-0">
                <Country :user="user" :destiny="country" @confirm="getResult"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Form from "../helpers/form";
import Country from "./request/Country";

export default {
    name: "Request",
    components: { Country },
    props: [ 'user' ],
    mixins: [ Form ],
    data: () => ({
        hover: true,
        label: 'Destino',
        country: null,
        countries: [
            { name: 'Brasil', code: 'BRA' },
            { name: 'Chile', code: 'CHL' },
            { name: 'Colombia', code: 'COL' },
            { name: 'Ecuador', code: 'ECU' },
            { name: 'Estados Unidos', code: 'USA' },
            { name: 'México', code: 'MEX' },
            { name: 'Panamá', code: 'PAN' },
            { name: 'Perú', code: 'PER' },
            { name: 'Venezuela', code: 'VEN' }
        ],
        items: []
    }),
    created() {
        this.$emit('success');
    },
    mounted() {
        this.country = this.$route.query.country || null;
        this.getIni();
    },
    watch: {
        'user.country'() {
            this.getIni();
        }
    },
    methods: {
        getIni() {
            this.items = [];
            let c = this.countries;
            for (let i = 0; i < c.length; i++) {
                if (c[i].code !== this.user.country) {
                    this.items.push(c[i]);
                }
            }
        },
        getResult() {
            this.$router.push({ name: 'Result' });
        }
    }
}
</script>

<style scoped>

</style>
