<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">RECLAMOS RESPONDIDOS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getClaims(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed dark :to="'/claims/v/' + item.id" style="margin: 1px">
                                <v-icon>mdi-chat</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed dark @click="closeClaim(item.id)" style="margin: 1px">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "CResponded",
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Cliente', value: 'name' },
                { text: 'Asunto', value: 'reason' },
                { text: 'Descripción', value: 'description' },
                { text: 'País', value: 'country_name' },
                { text: 'Fecha', value: 'updated_at' },
                { text: 'Acción', value: 'action', filterable: false, sortable: false }
            ],
            desserts: [],
            expanded: [],
            loading: true,
            noDataText: 'No tienes reclamos respondidos',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            claim: null
        }),
        created () {
            this.getClaims();
        },
        methods: {
            getClaims(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/claims/responded').then((res) => {
                    this.desserts = res.data.items;
                    this.loading = false;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            closeClaim(id) {
                this.$swal({
                    title: '¿Esta seguro que desea cerrar este reclamo?',
                    type: 'warning',
                    confirmButtonText: 'Si, cerrar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        this.loading = true;

                        axios.get('/api/claims/close/' + id).then((res) => {
                            if (res.data.v === 'success') {
                                this.getClaims();
                                this.$toast.success(res.data.status, {icon: 'check-circle'});
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
