<template>
    <v-layout align-sm-center justify-center>
        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
        <v-flex xs12 sm9 md10 lg8 class="py-4">
            <v-img src="/img/logo-icoin-dark.png" class="hidden-md-and-up mx-12 mb-6"/>
            <v-card flat color="transparent" class="ma-3">
                <v-toolbar flat color="primary" class="mb-4">
                    <v-toolbar-title
                        class="text-center font-weight-bold secondary--text headline"
                        style="white-space: normal; width: 100%">INICIAR SESIÓN
                    </v-toolbar-title>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card-text class="px-0 pb-0">
                        <v-text-field
                            :label="labels.email"
                            v-model="form.email"
                            type="email"
                            name="email"
                            :error-messages="errors.email"
                            :rules="[rules.required('email'), rules.mail('email')]"
                            :disabled="loading"
                            prepend-inner-icon="mdi-shield-account"
                            @input="clearErrors('email')"
                            filled/>
                        <v-text-field
                            :label="labels.password"
                            v-model="form.password"
                            :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (passwordHidden = !passwordHidden)"
                            :type="passwordHidden ? 'password' : 'text'"
                            name="password"
                            :error-messages="errors.password"
                            :disabled="loading"
                            :rules="[rules.req('password')]"
                            prepend-inner-icon="mdi-shield-key"
                            @input="clearErrors('password')"
                            filled/>
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading || !valid"
                            color="primary"
                            class="font-weight-bold"
                            block large
                        >Ingresar</v-btn>
                    </v-card-actions>
                    <v-btn
                        text block color="tertiary" :disabled="loading" large
                        :to="form.email ? {name: 'Forgot', query: {email: form.email}} : {name: 'Forgot'}"
                    ><v-icon left>mdi-lock-reset</v-icon>Recuperar mi contraseña</v-btn>
                </v-form>
            </v-card>
            <v-card flat color="transparent" class="pb-4 pb-sm-6 pt-1 pt-md-0">
                <v-card-text class="text-center title pt-1 pb-2">Ingresa con:</v-card-text>
                <v-card-actions class="justify-space-around">
                    <facebook/>
                    <google/>
                </v-card-actions>
            </v-card>
            <div class="text-center py-2 py-sm-4 hidden-xs-only">
                ¿No tienes una cuenta?&nbsp;&nbsp;
                <v-btn color="secondary" to="/register">Registrate</v-btn>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from 'axios';
    import Form from "../../helpers/form";
    import Facebook from "./providers/Facebook";
    import Google from "./providers/Google";

    export default {
        name: "Login",
        components: { Google, Facebook },
        mixins: [ Form ],
        data: () => ({
            passwordHidden: true,
            labels: {
                email: 'Correo Electrónico',
                password: 'Contraseña',
            },
            form: {
                email: null,
                password: null,
            },
            path: null
        }),
        created() {
            this.form.email = this.$route.query.email || null;
            this.path = this.$route.query.to || null;
        },
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    axios.post('/api/login', this.form)
                        .then(res => {
                            this.$toast.success('¡Bienvenido a iCoin!', {
                                icon: 'mdi-check-circle'
                            });
                            this.success(res.data);
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        })
                }
            },
            success(data) {
                this.$store.dispatch('saveToken', data);
                this.$store.dispatch('setUser', data);
                if (this.path) {
                    this.$router.push({path: this.path});
                } else {
                    this.$router.push({name: 'Home'});
                }
            }
        }
    }
</script>
