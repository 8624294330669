import formatter from "./formatter";

export default {
    data: () => ({
        loading: false,
        valid: true,
        labels: {},
        form: {},
        rules: {},
        errors: {}
    }),

    created() {
        for (let key in this.form) {
            if (this.form[key] !== null && typeof this.form[key] === 'object') {
                for (let i in this.form[key]) {
                    let key2 = key + '.' + i;
                    this.errors[key2] = [];
                    if (!this.labels[key2]) {
                        this.labels[key2] = formatter.titlecase(i);
                    }
                }
            } else {
                this.errors[key] = [];
                if (!this.labels[key]) {
                    this.labels[key] = formatter.titlecase(key);
                }
            }
        }

        this.rules.cond = (field) => ((v) => !!v || 'Debes aceptar los ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + '.');
        this.rules.req = (field) => ((v) => !!v || 'La ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' es obligatoria.');
        this.rules.reqs = (field) => ((v) => !!v || 'Los ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' son obligatorios.');
        this.rules.required = (field) => ((v) => !!v || 'El ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' es obligatorio.');
        this.rules.mail = (field) => ((v) => /.+@.+\..+/.test(v) || 'El ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' no es válido.');
        this.rules.confirm = (field) => ((v) => (v === this.password) || 'La ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' no coincide.');
        this.rules.min = (field, num, the) => ((v) => (v && v.length >= num) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe tener mas de ' + num + ' caracteres.');
        this.rules.max = (field, num, the) => ((v) => (v && v.length <= num) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe tener menos de ' + num + ' caracteres.');
        this.rules.equal = (field, num, lim, the) => ((v) => (v && v.length === num) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe tener ' + lim + ' números.');
        this.rules.minT = (field, num, lim, the) => ((v) => (v && v.length >= num) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe tener mas de ' + lim + ' números.');
        this.rules.maxT = (field, num, lim, the) => ((v) => (v && v.length <= num) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe tener menos de ' + lim + ' números.');
        this.rules.minus = (field, value, min, the) => (() => (value && value >= min) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe ser mayor o igual a ' + Number(min).toLocaleString("de-DE"));
        this.rules.maxim = (field, value, max, the) => (() => (value && value <= max) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' debe ser menor o igual a ' + Number(max).toLocaleString("de-DE"));
        this.rules.multiple = (field, value, the) => (() => !(field % value) || the + ' ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase()) + ' no es multiplo de ' + value + '.');
    },

    methods: {
        handleErrors(errors) {
            if (errors) {
                this.setErrors(errors);
            } else {
                this.clearErrors();
            }
        },

        setErrors(errors) {
            for (let key in this.errors) {
                this.errors[key] = errors[key] || [];
            }
        },

        clearErrors(key = false) {
            if (key) {
                if (this.errors[key].length) {
                    this.errors[key] = [];
                }
            }
            else {
                for (let key in this.errors) {
                    this.errors[key] = [];
                }
            }
        }
    }
}
