import { render, staticRenderFns } from "./Claims.vue?vue&type=template&id=517a258a&scoped=true"
import script from "./Claims.vue?vue&type=script&lang=js"
export * from "./Claims.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517a258a",
  null
  
)

export default component.exports