<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12" lg="11">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">FORMULARIO DE RECLAMO</v-card-title>
                    <v-card-subtitle class="text-center mt-4">Estimado cliente, por favor lea detenidamente la información que le solicitamos, en sólo 4 pasos usted nos podrá enviar los detalles del reclamo</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" lg="11">
                <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">25%</v-stepper-step>
                        <v-divider/>
                        <v-stepper-step :complete="e1 > 2" step="2">50%</v-stepper-step>
                        <v-divider/>
                        <v-stepper-step :complete="e1 > 3" step="3">75%</v-stepper-step>
                        <v-divider/>
                        <v-stepper-step step="4">100%</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                            <v-stepper-content class="px-2 px-md-4" step="1">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" md="6">
                                            <v-select
                                                :label="labels.country"
                                                :items="countries"
                                                name="country"
                                                v-model="form.country"
                                                :error-messages="errors.country"
                                                :disabled="loading"
                                                :rules="[rules.required('country')]"
                                                @select="clearErrors('country')"
                                                prepend-inner-icon="mdi-map-marker"
                                                item-text="name"
                                                item-value="code"
                                                filled>
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px">
                                                            <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()"/>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar>
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()"/>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                :label="labels.payment"
                                                :items="payments"
                                                name="payment"
                                                v-model="form.payment"
                                                :error-messages="errors.payment"
                                                :disabled="loading"
                                                :rules="[rules.required('payment')]"
                                                @select="clearErrors('payment')"
                                                prepend-inner-icon="mdi-chart-donut"
                                                item-text="text"
                                                item-value="value"
                                                filled>
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.text }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.text"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="$router.go(-1)">Salir</v-btn>
                                <v-btn class="float-right" color="primary" @click="next(1)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="2">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" sm="6" md="3" class="pr-sm-2">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.sid"
                                                v-model="form.sid"
                                                type="number"
                                                name="sid"
                                                :error-messages="errors.sid"
                                                :rules="[rules.required('sid'), rules.min('sid', 4, 'El')]"
                                                :disabled="loading"
                                                prepend-inner-icon="mdi-pound"
                                                @input="clearErrors('sid')"
                                                filled/>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pl-sm-0">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.tid"
                                                v-model="form.tid"
                                                type="number"
                                                name="tid"
                                                :error-messages="errors.tid"
                                                :rules="[rules.required('tid'), rules.min('tid', 5, 'El')]"
                                                :disabled="loading"
                                                prepend-inner-icon="mdi-asterisk"
                                                @input="clearErrors('tid')"
                                                filled/>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-if="e1 > 1"
                                                :label="labels.claim"
                                                :items="other"
                                                name="claim"
                                                v-model="form.claim"
                                                :error-messages="errors.claim"
                                                :disabled="loading"
                                                :rules="[rules.req('question')]"
                                                @select="clearErrors('claim')"
                                                prepend-inner-icon="mdi-text"
                                                item-text="text"
                                                item-value="value"
                                                filled>
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.text }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.text"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" md="11" lg="10">
                                            <p class="text-center mb-0">► Recuerde esperar un plazo de 24 horas hábiles,
                                                luego de haber enviado su primer reclamo.
                                                <br>► Revise su correo electrónico (en spam o correos no deseados si es
                                                Hotmail).</p>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="e1 = e1 - 1">Atras</v-btn>
                                <v-btn class="float-right" color="primary" @click="next(2)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="3">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" md="10">
                                            <v-select v-if="e1 > 2"
                                                :label="labels.reason"
                                                :items="reasons"
                                                name="reason"
                                                v-model="form.reason"
                                                :error-messages="errors.reason"
                                                :disabled="loading"
                                                :rules="[rules.required('reason')]"
                                                @select="clearErrors('reason')"
                                                prepend-inner-icon="mdi-text"
                                                item-text="value"
                                                item-value="value"
                                                filled>
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-check-circle</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.value }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-check-circle</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.value"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" md="10">
                                            <v-textarea
                                                v-if="e1 > 2"
                                                :label="labels.description"
                                                v-model="form.description"
                                                name="description"
                                                counter="255"
                                                :error-messages="errors.description"
                                                :disabled="loading"
                                                :rules="[rules.req('description'), rules.min('description', 12, 'La'), rules.max('description', 255, 'La')]"
                                                prepend-inner-icon="mdi-clipboard-text"
                                                @input="clearErrors('description')"
                                                no-resize
                                                rows="4"
                                                filled/>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="e1 = e1 - 1">Atras</v-btn>
                                <v-btn class="float-right" color="primary" @click="next(3)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="4">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" md="6">
                                            <v-select v-if="e1 > 3"
                                                :label="labels.connection"
                                                :items="connect"
                                                name="reason"
                                                v-model="form.connection"
                                                :error-messages="errors.connection"
                                                :disabled="loading"
                                                :rules="[rules.required('connection')]"
                                                @select="clearErrors('connection')"
                                                prepend-inner-icon="mdi-account-circle"
                                                item-text="value"
                                                item-value="value"
                                                filled>
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.value }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.value"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.phone"
                                                v-model="form.phone"
                                                type="tel"
                                                name="phone"
                                                :error-messages="errors.phone"
                                                :rules="[rules.required('phone'), rules.min('phone', 8, 'El')]"
                                                :disabled="loading"
                                                prepend-inner-icon="mdi-phone"
                                                @input="clearErrors('phone')"
                                                v-mask="mask"
                                                filled/>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="e1 = e1 - 1">Atras</v-btn>
                                <v-btn class="float-right" color="success" @click="submit">Enviar</v-btn>
                            </v-stepper-content>
                        </v-form>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../helpers/form";
    import { mask }from 'vue-the-mask';

    export default {
        name: "Claim",
        mixins: [ Form ],
        props: [ 'user' ],
        directives: { mask },
        data: () => ({
            e1:  1,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            payments: [
                { text: 'Deposito/Transferencia', value: 'Bank', icon: 'mdi-bank-transfer' },
                { text: 'Efectivo', value: 'Office', icon: 'mdi-currency-usd' }
            ],
            reasons: [
                { value: 'Ingresé los datos erroneos' },
                { value: 'Mi transferencia nunca fue realizada' },
                { value: 'Mi transferencia fue realizada, pero no se hizo efectiva' },
                { value: 'No puedo ingresar mi informacion en la app' }
            ],
            connect: [
                { value: 'Depositante', icon: 'mdi-account-minus' },
                { value: 'Beneficiario', icon: 'mdi-account-plus' }
            ],
            other: [
                { text: 'Si', value: '1', icon: 'mdi-check-circle' },
                { text: 'No', value: '0', icon: 'mdi-close-circle' }
            ],
            labels: {
                sid: 'ID Pago',
                tid: 'ID Giro',
                country: 'País donde realizó su envío',
                payment: 'Forma de pago',
                claim: '¿Has presentado otro reclamo?',
                question: 'Respuesta',
                reason: 'Motivo del Reclamo',
                description: 'Descripción del Reclamo',
                connection: 'Relación con el envío',
                phone: 'Número de teléfono/celular'
            },
            form: {
                sid: null,
                tid: null,
                country: null,
                payment: null,
                claim: null,
                reason: null,
                description: null,
                connection: null,
                phone: null
            },
            mask: '### #######'
        }),
        watch: {
            'form.country'() {
                this.maskPhone();
                this.getPayments();
            }
        },
        methods: {
            next(code) {
                if (this.$refs.form.validate()) {
                    this.e1 = code + 1;
                }
            },
            getPayments() {
                if (this.form.country === 'PAN' || this.form.country === 'USA') {
                    this.payments = [
                        { text: 'Deposito/Transferencia', value: 'Bank', icon: 'mdi-bank-transfer' },
                    ];
                    this.payment = null;
                } else {
                    this.payments = [
                        { text: 'Deposito/Transferencia', value: 'Bank', icon: 'mdi-bank-transfer' },
                        { text: 'Efectivo', value: 'Office', icon: 'mdi-currency-usd' }
                    ];
                }
            },
            maskPhone() {
                if (this.form.country === 'CHL') {
                    this.mask = '# ## ####-####';
                } else if (this.form.country === 'COL') {
                    this.mask = '### #######';
                } else if (this.form.country === 'ECU') {
                    this.mask = '## ########';
                } else if (this.form.country === 'MEX') {
                    this.mask = '### ### ####';
                } else if (this.form.country === 'PAN') {
                    this.mask = '####-####';
                } else if (this.form.country === 'PER') {
                    this.mask = '### ### ###';
                } else if (this.form.country === 'USA') {
                    this.mask = '(###) ###-####';
                } else if (this.form.country === 'VEN') {
                    this.mask = '####-###-####';
                }
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    axios.post('/api/claim', this.form)
                        .then((res) => {
                            this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                            this.$router.push({ name: 'ClaimView', params: { id: res.data.id } });
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
