import Auth from "./components/AuthComponent";
import Admin from "./components/AdminComponent";

import Wrapper from "./pages/Wrapper";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";

import Terms from "./pages/Terms";

import Home from "./pages/Home";
import Request from "./pages/Request";
import Voucher from "./pages/request/Voucher";
import Receivers from "./pages/Receivers";
import History from "./pages/History";
import Show from "./pages/Show";
import Corrections from "./pages/Corrections";
import Claim from "./pages/Claim";
import Claims from "./pages/Claims";
import ClaimView from "./pages/ClaimView";
import Calculator from "./pages/Calculator";
import Offices from "./pages/Offices";
import About from "./pages/About";
import Legal from "./pages/Legal";
import Profile from "./pages/Profile";
import Password from "./pages/Password";
import NotFound from "./pages/404";

import VPending from "./pages/admin/checker/Pending";
import VApproved from "./pages/admin/checker/Approved";
import VRejected from "./pages/admin/checker/Rejected";
import VCorrections from "./pages/admin/checker/Corrections";

import TPending from "./pages/admin/payer/Pending";
import TApproved from "./pages/admin/payer/Approved";
import TCorrections from "./pages/admin/payer/Corrections";

import CPending from "./pages/admin/claims/Pending";
import CResponded from "./pages/admin/claims/Responded";
import CClosed from "./pages/admin/claims/Closed.vue";

import Collect from "./pages/admin/cashier/Collect";
import InOut from "./pages/admin/cashier/InOut";
import Exchange from "./pages/admin/cashier/Exchange";
import CsPending from "./pages/admin/cashier/Pending";
import Balance from "./pages/admin/cashier/Balance";
import Movements from "./pages/admin/cashier/Movements";

import Accounts from "./pages/admin/accounting/Accounts";
import BankMovements from "./pages/admin/accounting/Movements";
import BankTransfer from "./pages/admin/accounting/Transfer";
import AcInOut from "./pages/admin/accounting/InOut";

import General from "./pages/admin/auditor/General";
import Completed from "./pages/admin/auditor/Completed.vue";
import ReportOffices from "./pages/admin/auditor/Offices";

import Customers from "./pages/admin/settings/Customers";
import Staff from "./pages/admin/settings/Staff";

import configAds from "./pages/admin/settings/Ads";
import configLegal from "./pages/admin/settings/Legal";
import configRate from "./pages/admin/settings/Rate";
import configGateways from "./pages/admin/settings/Gateways";
import configAccounts from "./pages/admin/settings/Accounts";
import configOffices from "./pages/admin/settings/Offices";
import configLimits from "./pages/admin/settings/Limits";
import configFee from "./pages/admin/settings/Fee";

export default [
    ...applyRules(['guest'], [
        { path: '', component: Auth, redirect: { name: 'Login' }, children:
            [
                { path: '/login', name: 'Login', component: Login, meta: { title: 'Inicio' } },
                { path: '/register', name: 'Register', component: Register, meta: { title: 'Crear Cuenta' } },
                { path: '/password', component: Wrapper, children:
                    [
                        { path: 'reset', name: 'Forgot', component: Forgot, meta: { title: 'Recuperar Contraseña' } },
                        { path: 'reset/:token', name: 'Reset', component: Reset, meta: { title: 'Cambiar Contraseña' } }
                    ]
                }
            ]
        },
    ]),
    ...applyRules(['auth'], [
        { path: '', component: Admin, redirect: { name: 'Home' }, children:
            [
                { path: '/', name: 'Home', component: Home, meta: { title: 'Inicio' } },
                { path: '/request', name: 'Request', component: Request, meta: { title: 'Crear Pago' } },

                { path: '/request/result', name: 'Result', component: Voucher, meta: { title: 'Resumen del Pago' } },

                { path: '/accounts', name: 'Receivers', component: Receivers, meta: { title: 'Mis Favoritos' } },
                { path: '/history', name: 'History', component: History, meta: { title: 'Historial' } },
                { path: '/history/payment/:id', name: 'PaymentVoucher', component: Voucher, meta: { title: 'Detalles de Pago' } },
                { path: '/history/transfer/:id', name: 'TransferVoucher', component: Show, meta: { title: 'Detalles de Giro' } },
                { path: '/corrections', name: 'Corrections', component: Corrections, meta: { title: 'Correcciones' } },
                { path: '/claim', name: 'Claim', component: Claim, meta: { title: 'Crear Reclamo' } },
                { path: '/claims', name: 'Claims', component: Claims, meta: { title: 'Reclamos' } },
                { path: '/claims/v/:id', name: 'ClaimView', component: ClaimView, meta: { title: 'Detalles del Reclamo' } },
                { path: '/calculator', name: 'Calculator', component: Calculator, meta: { title: 'Calculadora' } },
                { path: '/offices', name: 'Offices', component: Offices, meta: { title: 'Oficinas' } },
                { path: '/about', name: 'About', component: About, meta: { title: 'Información' } },
                { path: '/legal', name: 'Legal', component: Legal, meta: { title: 'Información Legal' } },
                { path: '/profile/edit', name: 'Profile', component: Profile, meta: { title: 'Mi Perfil' } },
                { path: '/profile/password', name: 'Password', component: Password, meta: { title: 'Cambiar Contraseña' } },

                { path: '/checker/pending', name: 'VPending', component: VPending, meta: { title: 'Pendientes' } },
                { path: '/checker/approved', name: 'VApproved', component: VApproved, meta: { title: 'Aprobadas' } },
                { path: '/checker/rejected', name: 'VRejected', component: VRejected, meta: { title: 'Rechazadas' } },
                { path: '/checker/corrections', name: 'VCorrections', component: VCorrections, meta: { title: 'Correcciones Pendientes' } },

                { path: '/payer/pending', name: 'TPending', component: TPending, meta: { title: 'Pendientes' } },
                { path: '/payer/approved', name: 'TApproved', component: TApproved, meta: { title: 'Realizadas' } },
                { path: '/payer/corrections', name: 'TCorrections', component: TCorrections, meta: { title: 'Correcciones Pendientes' } },

                { path: '/claims/pending', name: 'CPending', component: CPending, meta: { title: 'Reclamos Por Responder' } },
                { path: '/claims/responded', name: 'CResponded', component: CResponded, meta: { title: 'Reclamos Respondidos' } },
                { path: '/claims/closed', name: 'CClosed', component: CClosed, meta: { title: 'Reclamos Cerrados' } },

                { path: '/cashier/in', name: 'Collect', component: Collect, meta: { title: 'Pendientes por Cobrar' } },
                { path: '/cashier/out', name: 'CsPending', component: CsPending, meta: { title: 'Pagos Pendientes' } },
                { path: '/cashier/in-out', name: 'InOut', component: InOut, meta: { title: 'Entradas/Salidas' } },
                { path: '/cashier/exchange', name: 'Exchange', component: Exchange, meta: { title: 'Cambio de Divisas' } },
                { path: '/cashier/balance', name: 'Balance', component: Balance, meta: { title: 'Balance Actual' } },
                { path: '/cashier/movements', name: 'Movements', component: Movements, meta: { title: 'Movimientos' } },

                { path: '/movements/accounts', name: 'Accounts', component: Accounts, meta: { title: 'Cuentas Bancarias' } },
                { path: '/movements/accounts/:id', name: 'BankMovements', component: BankMovements, meta: { title: 'Movimientos Bancarios' } },
                { path: '/movements/transfer', name: 'BankTransfer', component: BankTransfer, meta: { title: 'Traslados' } },
                { path: '/movements/in-out', name: 'AcInOut', component: AcInOut, meta: { title: 'Entradas/Salidas' } },

                { path: '/reports/general', name: 'General', component: General, meta: { title: 'Reporte General' } },
                { path: '/reports/completed', name: 'Completed', component: Completed, meta: { title: 'Reporte Completadas' } },
                { path: '/reports/offices', name: 'ReportOffices', component: ReportOffices, meta: { title: 'Reporte de Oficinas' } },

                { path: '/users/customers', name: 'Customers', component: Customers, meta: { title: 'Listado de Customers' } },
                { path: '/users/staff', name: 'Staff', component: Staff, meta: { title: 'Listado de Empleados' } },

                { path: '/settings/ads', name: 'configAds', component: configAds, meta: { title: 'Configurar Anuncios' } },
                { path: '/settings/legal', name: 'configLegal', component: configLegal, meta: { title: 'Configurar Información Legal' } },
                { path: '/settings/rate', name: 'configRate', component: configRate, meta: { title: 'Configurar Tasa de Cambio' } },
                { path: '/settings/gateways', name: 'configGateways', component: configGateways, meta: { title: 'Registro de Bancos' } },
                { path: '/settings/accounts', name: 'configAccounts', component: configAccounts, meta: { title: 'Registro de Cuentas' } },
                { path: '/settings/offices', name: 'configOffices', component: configOffices, meta: { title: 'Registro de Oficinas' } },
                { path: '/settings/limits', name: 'configLimits', component: configLimits, meta: { title: 'Configurar Montos Minimos' } },
                { path: '/settings/fee', name: 'configFee', component: configFee, meta: { title: 'Configurar Comisiones' } },
            ]
        }
    ]),
    { path: '', component: Auth, redirect: {name: 'Login'}, children:
        [
            { path: '/terms', name: 'Terms', component: Terms, meta: { title: 'Términos & Condiciones' } }
        ]
    },
    { path: '', component: Wrapper, redirect: {name: 'Login'}, children:
        [
            { path: '/calculator/public', name: 'CalculatorPublic', component: Calculator, meta: { title: 'Calculadora' } },
        ]
    },
    { path: '/m', redirect: { name: 'Home' }, meta: { title: 'Inicio' } },
    { path: '*', component: NotFound, meta: { title: 'Página no Encontrada' } }
];

function applyRules(rules, routes) {
    for (let i in routes) {
        routes[i].meta = routes[i].meta || {};

        if (!routes[i].meta.rules) {
            routes[i].meta.rules = [];
        }
        routes[i].meta.rules.unshift(...rules);

        if (routes[i].children) {
            routes[i].children = applyRules(rules, routes[i].children);
        }
    }

    return routes
}
