    <template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block py-sm-2 text-center font-weight-bold primary warning--text" style="word-break: normal">MIS RECLAMOS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getClaims">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-btn color="primary" large block to="/claim">
                            <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Crear Reclamo
                        </v-btn>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps" dense>
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <v-chip small label :color="getColor(item.status_id)" :dark="(getColor(item.status_id) !== 'bg')">
                                <strong>{{ item.status }}</strong>
                            </v-chip>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed class="ma-1" title="Detalles" :to="'/claims/v/' + item.id">
                                <v-icon>mdi-chat</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Claims",
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Asunto', value: 'reason' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Estado', value: 'status', align: 'center', sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false },
            ],
            desserts: [],
            expanded: [],
            loading: true,
            noDataText: 'No tienes reclamos',
            expandIcon: 'mdi-chevron-down-circle',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            claim: null
        }),
        created () {
            this.getClaims();
        },
        methods: {
            getClaims() {
                this.loading = true;
                axios.get('/api/claims').then((res) => {
                    this.desserts = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getColor(status) {
                if (status === 301) return 'success';
                else if (status === 302) return 'tertiary';
                else return 'bg';
            }
        }
    }
</script>

<style scoped>

</style>
