<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CONVERSIÓN DE MONEDA
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-tabs v-model="tab" grow>
                            <v-tab @click="getData('buy')">Compra de Dolares</v-tab>
                            <v-tab @click="getData('sell')">Venta de Dolares</v-tab>
                        </v-tabs>
                        <v-divider/>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-form ref="form" @submit.prevent="submit('buy')" lazy-validation v-model="valid">
                                    <v-card flat>
                                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.currency_send }}</u></h3>
                                                    <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ form.currency_send }}</h2>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.currency_receiver }}</u></h3>
                                                    <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ form.currency_receiver }}</h2>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.amount_send }}</u></h3>
                                                    <v-currency-field
                                                        v-model="form.amount_send"
                                                        name="amount_send"
                                                        :error-messages="errors.amount_send"
                                                        :rules="[rules.required('amount_send')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('amount_send')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.amount_receiver }}</u></h3>
                                                    <v-currency-field
                                                        v-model="form.amount_receiver"
                                                        name="amount_receiver"
                                                        :error-messages="errors.amount_receiver"
                                                        :rules="[rules.required('amount_receiver')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('amount_receiver')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <v-btn type="submit" block :loading="loading" color="success" :disabled="loading || !valid">
                                                        <v-icon>mdi-hand-coin</v-icon><span>&nbsp;&nbsp;Registrar Compra</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-form>
                            </v-tab-item>
                            <v-tab-item>
                                <v-form ref="form" @submit.prevent="submit('sell')" lazy-validation v-model="valid">
                                    <v-card flat>
                                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.currency_send }}</u></h3>
                                                    <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ form.currency_send }}</h2>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.currency_receiver }}</u></h3>
                                                    <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ form.currency_receiver }}</h2>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.amount_send }}</u></h3>
                                                    <v-currency-field
                                                        v-model="form.amount_send"
                                                        name="amount_send"
                                                        :error-messages="errors.amount_send"
                                                        :rules="[rules.required('amount_send')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('amount_send')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="5">
                                                    <h3 class="text-center"><u>{{ labels.amount_receiver }}</u></h3>
                                                    <v-currency-field
                                                        v-model="form.amount_receiver"
                                                        name="amount_receiver"
                                                        :error-messages="errors.amount_receiver"
                                                        :rules="[rules.required('amount_receiver')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('amount_receiver')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <v-btn type="submit" block :loading="loading" color="success" :disabled="loading || !valid">
                                                        <v-icon>mdi-hand-coin</v-icon><span>&nbsp;&nbsp;Registrar Venta</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-form>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: "Exchange",
        mixins: [ Form ],
        props: [ 'user', 'office' ],
        data: () => ({
            tab: null,
            loading: false,
            labels: {
                currency_send: 'Moneda Inicial',
                currency_receiver: 'Moneda Final',
                amount_send: 'Valor Inicial',
                amount_receiver: 'Valor Final'
            },
            form: {
                currency_send: null,
                currency_receiver: null,
                amount_send: null,
                amount_receiver: null
            },
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0
            }
        }),
        mounted() {
            if (this.user.country) {
                this.getData();
            }
        },
        watch: {
            user() {
                this.getData();
            },
            office() {
                this.getData();
            }
        },
        methods: {
            getData(t) {
                let c = null;
                if (this.user.country === 'BRA') {
                    c = 'BRL';
                } else if (this.user.country === 'CHL') {
                    c = 'CLP';
                } else if (this.user.country === 'COL') {
                    c = 'COP';
                } else if (this.user.country === 'MEX') {
                    c = 'MXN';
                } else if (this.user.country === 'PER') {
                    c = 'PEN';
                } else if (this.user.country === 'VEN') {
                    c = 'VES';
                }

                if (c) {
                    if (t === 'sell') {
                        this.form = {
                            currency_send: 'USD',
                            currency_receiver: c,
                            amount_send: 0,
                            amount_receiver: 0
                        };
                    } else {
                        this.form = {
                            currency_send: c,
                            currency_receiver: 'USD',
                            amount_send: 0,
                            amount_receiver: 0
                        };
                    }
                    this.$refs.form.resetValidation();
                } else {
                    this.$toast.error('Esta función no esta activada en este país.', {icon: 'mdi-close-circle'});
                    this.$router.push({name: 'Home'});
                }
            },
            submit(tp) {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('tp', tp);
                    formData.append('currency_send', this.form.currency_send);
                    formData.append('currency_receiver', this.form.currency_receiver);
                    formData.append('amount_send', this.form.amount_send);
                    formData.append('amount_receiver', this.form.amount_receiver);
                    axios.post('/api/cashier/exchange', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData(tp);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
