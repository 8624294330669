<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
        <v-card>
            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
            <v-card-title class="d-block text-center font-weight-bold primary warning--text" style="word-break: normal">
                <span class="headline">Corrección de Pago Nº {{ $props.id }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" sm="10">
                            <h3 class="text-center">Agregar Foto del Voucher:</h3>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" sm="10" md="8">
                            <v-file-input
                                :label="labels.voucher"
                                v-model="form.voucher"
                                name="voucher"
                                accept="image/*"
                                :error-messages="errors.voucher"
                                :rules="[rules.req('voucher')]"
                                :disabled="loading"
                                prepend-icon="mdi-camera"
                                @input="clearErrors('voucher')"
                                @click:clear="clearVoucher"
                                show-size counter/>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="10" sm="8" md="6" lg="4" v-if="imageData !== null">
                            <img :src="imageData" width="100%" alt="voucher"
                                 style="border: 1px solid #eee; border-radius: 4px;"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions class="pa-4">
                <v-btn color="error" @click="closeP">Cerrar</v-btn>
                <v-spacer/>
                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="primary">
                    Actualizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import axios from "axios";
    import Form from "../../helpers/form";

    export default {
        name: "Payment",
        mixins: [ Form ],
        props: [ 'id' ],
        data: () => ({
            loading: false,
            labels: {
                voucher: 'Foto del Voucher'
            },
            form: {
                voucher: null
            },
            imageData: null
        }),
        watch: {
            'form.voucher'() {
                if (this.form.voucher) {
                    this.getVoucher();
                }
            }
        },
        methods: {
            getVoucher() {
                const input = this.form.voucher;

                if (input) {
                    const reader = new FileReader;
                    reader.onload = e => {
                        this.imageData = e.target.result;
                    };
                    reader.readAsDataURL(input);
                }
            },
            clearVoucher() {
                this.form.voucher = null;
                this.imageData = null;
                this.$refs.form.resetValidation();
            },
            closeP() {
                this.clearVoucher();
                this.$emit('close-payment');
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('voucher', this.form.voucher);

                    axios.post('/api/corrections/p/' + this.$props.id, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                            this.voucher = '';
                            this.imageData = null;
                            this.$emit('close-payment');
                            this.$emit('success');
                        } else {
                            this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
