<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="11">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-0"
                                  style="word-break: normal">COMPROBANTE DE PAGO Nº {{ id }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="11">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">ESTADO: {{ status ? status.toUpperCase() : '' }}
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-row justify="center" class="py-2 py-md-6">
                            <v-col cols="6" md="3" class="py-2">
                                <h3 class="text-center"><strong>Fecha y Hora:</strong></h3>
                                <p class="text-center">{{ payment.created_at }}</p>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <h3 class="text-center"><strong>Tipo de Pago:</strong></h3>
                                <p class="text-center">{{ payment.type === 'Bank' ? 'Deposito/Transferencia' : 'Efectivo' }}</p>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <h3 class="text-center"><strong>Valor Pagado:</strong></h3>
                                <p class="text-center">{{ payment.amount + ' ' + payment.currency }}</p>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <h3 class="text-center"><strong>Costo de Envío:</strong></h3>
                                <p class="text-center">{{ payment.fee + ' ' + payment.currency }}</p>
                            </v-col>
                        </v-row>
                        <template v-for="item in transfers">
                            <v-divider/>
                            <v-row justify="center" class="py-6">
                                <v-col cols="12" class="pt-0 pb-6">
                                    <h2 class="text-center pb-2">
                                        <u>Transferencia Nº {{ item.order }}</u>
                                    </h2>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Código:</strong></h3>
                                    <p class="text-center">{{ item.id }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Nombre:</strong></h3>
                                    <p class="text-center" style="text-transform: capitalize">{{ item.name ? (item.name).toLowerCase() : '' }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2" v-if="item.nd !== null">
                                    <h3 class="text-center"><strong>Documento:</strong></h3>
                                    <p class="text-center">{{ item.td }} - {{ item.nd }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Medio de Pago:</strong></h3>
                                    <p class="text-center">{{ item.bank_name }}</p>
                                </v-col>
                                <template v-if="item.nc !== null">
                                    <v-col cols="6" md="3" class="py-2">
                                        <h3 class="text-center"><strong>Cuenta {{ item.tc === 'Ahorros' ? 'de ' : '' }} {{ item.tc }}:</strong></h3>
                                        <p class="text-center" v-if="item.country_receiver === 'VEN'">{{ item.nc ? (item.bank_code + item.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : '' }}</p>
                                        <p class="text-center" v-else>{{ item.nc }}</p>
                                    </v-col>
                                </template>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Valor Enviado:</strong></h3>
                                    <p class="text-center">{{ item.amount_send + ' ' + item.currency_send }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Tasa de Cambio:</strong></h3>
                                    <p class="text-center">{{ item.fx_rate }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Costo de Pago:</strong></h3>
                                    <p class="text-center">{{ item.fee + ' ' + item.currency_receiver }}</p>
                                </v-col>
                                <v-col cols="6" md="3" class="py-2">
                                    <h3 class="text-center"><strong>Valor Destino:</strong></h3>
                                    <p class="text-center">{{ item.amount_receiver + ' ' + item.currency_receiver }}</p>
                                </v-col>
                            </v-row>
                        </template>
                        <v-row justify="center" v-if="$route.params.id">
                            <v-col cols="6" md="5" lg="4" class="text-center py-6">
                                <v-btn color="success" dark @click="$router.go(-1)">
                                    <v-icon>mdi-restore</v-icon>&nbsp;&nbsp;Regresar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-else>
                            <v-col cols="6" md="5" lg="4" class="text-center py-6">
                                <v-btn color="primary" dark to="/request">
                                    <v-icon>mdi-cash-multiple</v-icon>&nbsp;&nbsp;Otro Pago
                                </v-btn>
                            </v-col>
                            <v-col cols="6" md="5" lg="4" class="text-center py-6">
                                <v-btn color="success" dark to="/history">
                                    <v-icon>mdi-page-next</v-icon>&nbsp;&nbsp;Continuar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Voucher",
        data: () => ({
            id: null,
            status: null,
            payment: {},
            transfers: []
        }),
        mounted() {
            this.start();
        },
        methods: {
            start() {
                let id = '';
                if (this.$route.params.id) {
                    id = '?id=' + this.$route.params.id;
                }
                axios.get('/api/request/voucher' + id).then((res) => {
                        this.id = res.data.payment.id;
                        this.status = res.data.status;
                        this.payment = res.data.payment;
                        this.transfers = res.data.transfers;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
