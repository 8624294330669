<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">TRANSACCIONES PENDIENTES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getPending(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" v-if="myTransfers.length > 0">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">
                        <u>Mis Transacciones</u>
                    </v-card-title>
                    <v-card-subtitle class="mt-0">
                        <v-text-field v-model="mySearch" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-subtitle>
                    <v-data-table :headers="headersT" :items="myTransfers" :search="mySearch" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="myFooterProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.nd="{ item }">
                            <span>{{ item.td + ' ' + item.nd }}</span>
                        </template>
                        <template v-slot:item.nc="{ item }">
                            <span>{{ item.tc + ' ' + item.nc }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="success" small depressed @click="getConfirm(item.id)" style="margin: 1px">
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-btn>
                                <v-btn color="secondary" small depressed @click="getCorrection(item.id, item.user_id)" style="margin: 1px">
                                    <v-icon>mdi-alert-circle</v-icon>
                                </v-btn>
                                <v-btn color="info" small depressed @click="getEdit(item.id)" style="margin: 1px" v-if="user.role === 'Administrator'">
                                    <v-icon>mdi-pencil-circle</v-icon>
                                </v-btn>
                                <v-btn color="error" small depressed @click="getLiberty(item.id)" style="margin: 1px">
                                    <v-icon>mdi-arrow-down-bold-circle</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed :to="'/history/transfer/' + item.id" style="margin: 1px">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="confirm" scrollable persistent max-width="1200px" v-if="confirm === true">
                        <v-form ref="form" @submit.prevent="submitConfirm" lazy-validation v-model="valid">
                            <v-card>
                                <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                                <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">Formulario de Confirmación ID: {{ id }}</v-card-title>
                                <v-card-text>
                                    <v-row justify="center">
                                        <v-col cols="12" md="10">
                                            <h2 class="text-center grey--text text--darken-2">
                                                <u>Datos del Destinatario</u>
                                            </h2>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="8" md="6">
                                            <h3 class="text-center"><u>Nombres</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4" style="text-transform: capitalize">{{ details.name ? (details.name).toLowerCase() : '' }}</h2>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <h3 class="text-center"><u>Documento</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.td + ' ' + details.nd }}</h2>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <h3 class="text-center"><u>Banco</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.bank_name }}</h2>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5">
                                            <h3 class="text-center"><u>Tipo de Cuenta</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.tc }}</h2>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5">
                                            <h3 class="text-center"><u>Número de Cuenta</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.nc }}</h2>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <h3 class="text-center"><u>Tasa de Cambio</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.fx_rate }}</h2>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5">
                                            <h3 class="text-center"><u>Valor Recibido</u></h3>
                                            <h1 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ details.amount_send_text + ' ' + details.currency_send }}</h1>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5">
                                            <h3 class="text-center"><u>Valor a Transferir</u></h3>
                                            <h1 class="text-center error--text pa-3 grey lighten-4">{{ details.amount_receiver_text + ' ' + details.currency_receiver }}</h1>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" md="10">
                                            <h3 class="text-center"><u>Cuenta Abierta</u></h3>
                                            <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4"
                                                v-if="user.account_id !== 0">{{ account }}</h2>
                                            <h2 class="text-center error--text pa-3 grey lighten-4"
                                                v-else>Debe seleccionar una cuenta para poder registrar la transferencia</h2>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="6" lg="4">
                                            <v-text-field
                                                :label="labels.comp"
                                                v-model="form.comp"
                                                name="comp"
                                                :error-messages="errors.comp"
                                                :rules="[rules.required('comp')]"
                                                :disabled="loading"
                                                @change="clearErrors('comp')"
                                                prepend-icon="mdi-hash"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="space-around" v-if="details.country_receiver === 'VEN'">
                                        <v-checkbox
                                            :label="labels.add"
                                            v-model="form.add"
                                            class="mx-2 pb-0"
                                            color="success"
                                            :disabled="loading"/>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" md="8">
                                            <h2 class="text-center grey--text text--darken-2 pb-4" v-if="voucher === null">Agregar Comprobante (Ctrl + V)</h2>
                                            <h2 class="text-center grey--text text--darken-2 pb-4" v-else>
                                                <u>Comprobante Agregado</u>
                                            </h2>
                                            <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="voucher === null"/>
                                            <img :src="voucherUrl" :alt="id" width="100%" v-else/>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn color="error" @click="closeConfirm">
                                        <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn type="submit" :loading="loading" :disabled="loading || !valid || voucher === null" color="success">
                                        <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;Confirmar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="correction" scrollable persistent max-width="800px">
                        <v-form ref="form" @submit.prevent="submitCorrection" lazy-validation v-model="valid">
                            <v-card v-if="correction === true">
                                <v-progress-linear indeterminate fixed v-if="loading" style="top: 0"/>
                                <v-card-title class="text-center headline primary warning--text  d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">Formulario de Correcciones ID: {{ correction_id }}</v-card-title>
                                <v-card-text class="py-4">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h2 class="text-center">Seleccione el Tipo de Corrección:</h2>
                                        </v-col>
                                        <v-radio-group
                                            v-model="form.code"
                                            class="px-4"
                                            name="code"
                                            :disabled="loading"
                                            :error-messages="errors.code"
                                            :rules="[rules.required('code')]"
                                            @select="clearErrors('code')"
                                            row>
                                            <v-radio label="Error de Documento" color="secondary" value="4" class="py-2"/>
                                            <v-radio label="Error de Cuenta" color="info" value="5" class="py-2"/>
                                        </v-radio-group>
                                        <v-col cols="12" md="10">
                                            <v-textarea
                                                outlined name="message" :error-messages="errors.message"
                                                :rules="[rules.required('message')]" label="Mensaje (Obligatorio)"
                                                v-model="form.message"/>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn color="error" @click="closeCorrection"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                                    <v-spacer/>
                                    <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="secondary">Solicitar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="edit" scrollable persistent max-width="800px" v-if="user.role === 'Administrator'">
                        <v-form ref="form" @submit.prevent="submitEdit" lazy-validation v-model="valid">
                            <v-card v-if="edit === true">
                                <v-progress-linear indeterminate fixed v-if="loading" style="top: 0"/>
                                <v-card-title class="text-center headline primary warning--text d-inline-block"
                                              style="color: #ffffff; word-break: normal; width: 100%">Corrección de Datos ID: {{ idEdit }}</v-card-title>
                                <v-card-text class="py-4">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="12" :sm="form.bn === 'Nuestras Oficinas' ? 12 : 6">
                                                <v-text-field
                                                    :label="labels.name"
                                                    v-model="form.name"
                                                    type="text"
                                                    name="name"
                                                    :counter="100"
                                                    :error-messages="errors.name"
                                                    :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-account-circle"
                                                    @input="clearErrors('name')"/>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    :label="labels.bank"
                                                    :items="banks"
                                                    name="bank"
                                                    v-model="form.bank"
                                                    :error-messages="errors.bank"
                                                    :disabled="loading"
                                                    :rules="[rules.required('bank')]"
                                                    @select="clearErrors('bank')"
                                                    prepend-icon="mdi-domain"
                                                    item-text="name"
                                                    item-value="code"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-if="form.bn === 'Nuestras Oficinas'">
                                                <v-autocomplete
                                                    :label="labels.branch"
                                                    :items="branches"
                                                    name="branch"
                                                    v-model="form.branch"
                                                    :error-messages="errors.branch"
                                                    :disabled="loading"
                                                    :rules="[rules.required('branch')]"
                                                    @select="clearErrors('branch')"
                                                    prepend-icon="mdi-store"
                                                    item-text="name"
                                                    item-value="id"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    :label="labels.td"
                                                    :items="td"
                                                    name="td"
                                                    v-model="form.td"
                                                    :error-messages="errors.td"
                                                    :disabled="loading"
                                                    :rules="[rules.required('td')]"
                                                    @select="clearErrors('td')"
                                                    prepend-icon="mdi-clipboard-text"
                                                    item-text="name"
                                                    item-value="value">
                                                    <template v-slot:selection="{ item }">
                                                        <v-flex class='ml-1'>{{ item.value + ' - ' + item.name }}</v-flex>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.value + ' - ' + item.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-if="form.country === 'CHL' && (form.td === 'CI' || form.td === 'CE')">
                                                <v-text-field
                                                    :label="labels.nd"
                                                    v-model="form.nd"
                                                    type="text"
                                                    name="nd"
                                                    :error-messages="errors.nd"
                                                    :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-ballot"
                                                    @input="clearErrors('nd')"
                                                    v-mask="rut"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-else-if="form.td === 'PAS'">
                                                <v-text-field
                                                    :label="labels.nd"
                                                    v-model="form.nd"
                                                    type="text"
                                                    name="nd"
                                                    :error-messages="errors.nd"
                                                    :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-ballot"
                                                    @input="clearErrors('nd')"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-else>
                                                <v-text-field
                                                    :label="labels.nd"
                                                    v-model="form.nd"
                                                    type="number"
                                                    name="nd"
                                                    :error-messages="errors.nd"
                                                    :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-ballot"
                                                    @input="clearErrors('nd')"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-if="form.bn !== 'Nuestras Oficinas'">
                                                <v-select
                                                    :label="labels.tc"
                                                    :items="tc"
                                                    name="tc"
                                                    v-model="form.tc"
                                                    :error-messages="errors.tc"
                                                    :disabled="loading"
                                                    :rules="[rules.req('tc')]"
                                                    @select="clearErrors('tc')"
                                                    prepend-icon="mdi-wallet"
                                                    item-text="name"
                                                    item-value="value"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" v-if="form.country === 'VEN' && form.bn !== 'Nuestras Oficinas'">
                                                <v-text-field
                                                    :label="labels.nc"
                                                    v-model="form.nc"
                                                    type="tel"
                                                    name="nc"
                                                    :prefix="form.bank"
                                                    :error-messages="errors.nc"
                                                    :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-dialpad"
                                                    @input="clearErrors('nc')"
                                                    v-mask="mask"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6" v-else-if="form.bn !== 'Nuestras Oficinas'">
                                                <v-text-field
                                                    :label="labels.nc"
                                                    v-model="form.nc"
                                                    type="text"
                                                    inputmode="numeric"
                                                    name="nc"
                                                    :error-messages="errors.nc"
                                                    :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                    :disabled="loading"
                                                    prepend-icon="mdi-dialpad"
                                                    @input="clearErrors('nc')"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn color="error" @click="closeEdit">
                                        <v-icon>mdi-close-circle</v-icon> Cerrar
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">
                                        <v-icon>mdi-check-circle</v-icon> Actualizar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">
                        <u>Transacciones Generales</u>
                    </v-card-title>
                    <v-card-subtitle class="mt-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-subtitle>
                    <v-data-table :headers="headers" :items="transfers" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="success" small depressed dark @click="getSelect(item.id)" style="margin: 1px">
                                    <v-icon>mdi-arrow-up-bold-circle</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed dark :to="'/history/transfer/' + item.id" style="margin: 1px">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';
    import { mask }from 'vue-the-mask';
    import Transfer from "../../correction/Transfer.vue";

    export default {
        name: "TPending",
        components: { Transfer },
        mixins: [ Form ],
        props: [ 'user', 'account' ],
        directives: { mask },
        data: () => ({
            search: '',
            mySearch: '',
            headersT: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Nombre', value: 'name', align: 'left', sortable: false },
                { text: 'Documento', value: 'nd', align: 'left', sortable: false },
                { text: 'Banco', value: 'bank_name', align: 'left', sortable: false },
                { text: 'Cuenta', value: 'nc', align: 'left', sortable: false },
                { text: 'Valor', value: 'amount_receiver_text', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency_receiver', align: 'left', sortable: false },
                { text: 'Destino', value: 'country_receiver_text', align: 'left', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', align: 'right', filterable: false, sortable: false }
            ],
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Nombre', value: 'name', align: 'left', sortable: false },
                { text: 'Banco', value: 'bank_name', align: 'left', sortable: false },
                { text: 'Valor', value: 'amount_receiver_text', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency_receiver', align: 'left', sortable: false },
                { text: 'Destino', value: 'country_receiver_text', align: 'left', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', align: 'left', filterable: false, sortable: false }
            ],
            myTransfers: [],
            transfers: [],
            loading: true,
            noDataText: 'No hay transferencias pendientes',
            myFooterProps: {
                itemsPerPageOptions: [10, 25, 50, 100, -1]
            },
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            id: null,
            voucher: null,
            voucherUrl: null,
            confirm: false,
            correction_id: null,
            correction: false,
            idEdit: null,
            edit: false,
            td: [],
            tc: [],
            banks: [],
            branches: [],
            labels: {
                add: 'Interbancaria',
                name: 'Nombre',
                td: 'Tipo de Documento',
                nd: 'Número de Documento',
                bank: 'Entidad Bancaria',
                branch: 'Oficina de Pago',
                tc: 'Tipo de Cuenta',
                nc: 'Número de Cuenta',
                comp: 'Nº de Comprobante',
                error: 'Tipo de Error'
            },
            form: {
                add: false,
                country: null,
                name: null,
                td: null,
                nd: null,
                bank: null,
                branch: null,
                bn: null,
                br: null,
                tc: null,
                nc: null,
                comp: null,
                code: null,
                error: null,
                message: null
            },
            details: {},
            mask: '#### #### #### #### ####',
            rut: '########-X'
        }),
        created () {
            this.getPending();
        },
        mounted() {
            window.addEventListener("paste", this.getPasteVoucher);
        },
        watch: {
            'user.country'() {
                this.getPending();
            },
            'form.nc'() {
                if (this.form.country === 'VEN') {
                    this.form.nc = this.form.nc ? (this.form.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : null;
                }
            },
            'form.code'() {
                if (this.form.code === '4') {
                    this.form.error = 'Error de Documento';
                } else if (this.form.code === '5') {
                    this.form.error = 'Error de Cuenta';
                }
            },
            'form.bank'() {
                if (this.form.bank) {
                    this.getBank();
                } else {
                    this.form.bn = null;
                }
            },
            'form.branch'() {
                if (this.form.branch) {
                    this.getBranch();
                } else {
                    this.form.br = null;
                }
            }
        },
        methods: {
            getBanks(c) {
                axios.get('/api/banks/' + c).then((res) => {
                    this.banks = res.data.banks;
                    this.branches = res.data.branches;
                });
            },
            getPending(v) {
                this.loading = true;
                if (v === 1) {
                    this.myTransfers = [];
                    this.transfers = [];
                }
                axios.get('/api/payer/pending').then((res) => {
                    if (res.data.v === 'success') {
                        this.myTransfers = res.data.myTransfers;
                        this.transfers = res.data.transfers;
                        this.loading = false;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getPending, 120000);
            },
            getSelect(id) {
                this.loading = true;
                axios.get('/api/payer/select/' + id).then((res) => {
                    if (res.data.v === 'success') {
                        this.getPending();
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else if (res.data.v === 'warning') {
                        this.getPending();
                        this.$toast.warning(res.data.status, {icon: 'info-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getConfirm(id) {
                this.id = id;
                this.confirm = true;

                const myTransfers = this.myTransfers;

                for (let i = 0; i < myTransfers.length; i++) {
                    if (myTransfers[i].id === id) {
                        this.details = myTransfers[i];
                    }
                }
            },
            getPasteVoucher(e) {
                for (let i = 0; i < e.clipboardData.items.length; i++) {
                    let clipboardItem = e.clipboardData.items[0];
                    let type = clipboardItem.type;

                    if (type.indexOf("image") !== -1) {
                        let blob = clipboardItem.getAsFile();
                        this.voucherUrl = URL.createObjectURL(blob);

                        let reader = new window.FileReader();
                        reader.readAsDataURL(clipboardItem.getAsFile());

                        reader.onload = e => {
                            this.voucher = e.target.result;
                        }
                    } else {
                        console.log('Los datos tipo ' + type + ' no son soportados');
                    }
                }
            },
            submitConfirm() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('id', this.id);
                    formData.append('comp', this.form.comp);
                    formData.append('voucher', this.voucher);
                    if (this.details.country_receiver === 'VEN') {
                        formData.append('add', (this.form.add === true ? '1' : '0'));
                    }
                    axios.post('/api/payer/confirm', formData, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    }).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getPending();
                            const myTransfers = this.myTransfers;
                            for (let i = 0; i < myTransfers.length; i++) {
                                if (myTransfers[i].id === this.id) {
                                    this.myTransfers.splice(i, 1);
                                }
                            }
                            this.closeConfirm();
                        } else if (res.data.v === 'info') {
                            this.$toast.info(res.data.status, {icon: 'mdi-information'});
                        } else if (res.data.v === 'warning') {
                            this.$toast.warning(res.data.status, {icon: 'mdi-alert-circle'});
                        } else {
                            this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            closeConfirm() {
                this.confirm = false;
                this.form.comp = null;
                this.voucherUrl = null;
                this.voucher = null;
                this.id = null;
                this.details = {};
                this.$refs.form.reset();
            },
            getLiberty(id) {
                this.loading = true;

                axios.get('/api/payer/liberty/' + id).then((res) => {
                    if (res.data.v === 'success') {
                        this.getPending();
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else if (res.data.v === 'warning') {
                        this.$toast.warning(res.data.status, {icon: 'alert-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getCorrection(id, uid) {
                this.correction_id = id;
                this.correction = true;
                this.correction_uid = uid;

                const myTransfers = this.myTransfers;

                for (let i = 0; i < myTransfers.length; i++) {
                    if (myTransfers[i].id === id) {
                        this.form.country = myTransfers[i].country_receiver;
                        this.getBanks(this.form.country);
                    }
                }
            },
            closeCorrection() {
                this.correction = false;
                this.correction_id = null;
                this.correction_uid = null;
                this.form.country = null;
                this.form.error = null;
                this.form.message = null;
                this.$refs.form.reset();
            },
            submitCorrection() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('id',  this.correction_id);
                    formData.append('user_id',  this.correction_uid);
                    formData.append('type',  'Transfer');
                    formData.append('code',  this.form.code);
                    formData.append('error',  this.form.error);
                    if (this.form.message) {
                        formData.append('message', this.form.message);
                    }

                    axios.post('/api/transfers/correction', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.closeCorrection();
                            this.getPending();
                        } else {
                            this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            getEdit(id) {
                this.idEdit = id;
                this.edit = true;

                const myTransfers = this.myTransfers;

                for (let i = 0; i < myTransfers.length; i++) {
                    if (myTransfers[i].id === id) {
                        this.form.name = myTransfers[i].name;
                        this.form.td = myTransfers[i].td;
                        this.form.nd = myTransfers[i].nd;
                        this.form.bank = myTransfers[i].bank_code;
                        this.form.branch = myTransfers[i].account_out;
                        this.form.tc = myTransfers[i].tc;
                        this.form.nc = myTransfers[i].nc;
                        this.form.country = myTransfers[i].country_receiver;
                        this.getBanks(this.form.country);
                        this.getTypeDoc();
                        this.getTypeAcc();
                    }
                }
            },
            getTypeDoc() {
                let c = this.form.country;
                if (c === 'MEX' || c === 'USA') {
                    this.td = [
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'PER') {
                    this.td = [
                        { name: 'Documento Nacional de Identidad', value: 'DNI' },
                        { name: 'Carnet de Extranjeria', value: 'CE' },
                        { name: 'Permiso Temporal de Permanencia', value: 'PTP' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'COL') {
                    this.td = [
                        { name: 'Cedula de Ciudadania', value: 'CC' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Número de Identificación Tributaria', value: 'NIT' },
                        { name: 'Permiso por Protección Temporal', value: 'PPT' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'VEN') {
                    this.td = [
                        { name: 'Cedula de Identidad', value: 'V' },
                        { name: 'Carnet de Extranjeria', value: 'E' },
                        { name: 'RIF', value: 'J' },
                        { name: 'Gubernamental', value: 'G' },
                        { name: 'Firma Personal', value: 'M' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'CHL') {
                    this.td = [
                        {name: 'Rol Único Tributario', value: 'RUT'},
                        {name: 'Rol Único Nacional', value: 'RUN'},
                        {name: 'Cedula de Identidad', value: 'CI'},
                        {name: 'Cedula de Extranjeria', value: 'CE'},
                        {name: 'Licencia de Conducir', value: 'LIC'},
                        {name: 'Pasaporte', value: 'PAS'}
                    ]
                } else {
                    this.td = [
                        { name: 'Cedula de Identidad', value: 'CI' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                }
            },
            getTypeAcc() {
                let c = this.form.country;
                if (c === 'CHL') {
                    this.tc = [
                        { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                        { name: 'Cuenta Corriente', value: 'Corriente' },
                        { name: 'Cuenta Vista', value: 'Vista' },
                        { name: 'Cuenta RUT', value: 'RUT' }
                    ]
                } else {
                    this.tc = [
                        { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                        { name: 'Cuenta Corriente', value: 'Corriente' }
                    ]
                }
            },
            getBank() {
                const banks = this.banks;

                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc = null;
                            this.form.nc = null;
                        } else {
                            this.form.branch = null;
                        }
                        this.form.bn = banks[i].name;
                    }
                }
            },
            getBranch() {
                const branches = this.branches;

                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].code === this.form.branch) {
                        this.form.br = branches[i].name;
                    }
                }
            },
            closeEdit() {
                this.edit = false;
                this.idEdit = null;
                this.form.name = null;
                this.form.td = null;
                this.form.nd = null;
                this.form.bank = null;
                this.form.branch = null;
                this.form.bn = null;
                this.form.br = null;
                this.form.tc = null;
                this.form.nc = null;
                this.form.country = null;
                this.$refs.form.reset();
            },
            submitEdit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('name',  this.form.name);
                    formData.append('td',  this.form.td);
                    formData.append('nd',  this.form.nd);
                    formData.append('bank',  this.form.bank);
                    formData.append('branch',  this.form.branch);
                    formData.append('tc',  this.form.tc);
                    formData.append('nc',  this.form.nc ? (this.form.nc).replace(/ /g, '') : null);

                    axios.post('/api/transfers/update/' + this.idEdit, formData)
                        .then(res => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                                this.getPending();
                                this.closeEdit();
                            } else {
                                this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                }
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
            window.removeEventListener('paste', this.getPasteVoucher);
        }
    }
</script>

<style scoped>

</style>
