<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">NUESTRAS OFICINAS</v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="6" md="4" xl="3" v-if="c === 0">
                <h1 v-if="prox" class="text-center grey--text text--darken-2 pb-4">... PROXIMAMENTE ...</h1>
                <v-skeleton-loader ref="skeleton" type="card" class="mx-auto"/>
            </v-col>
            <template v-for="(v, k) in offices" v-else>
                <v-col cols="12" sm="6" md="4" xl="3" :key="k">
                    <v-card>
                        <v-img :src="'/' + v.img" :alt="v.name"/>
                        <v-card-title>{{ v.name }}</v-card-title>
                        <v-card-text>{{ v.address }}, {{ v.city }} - {{ v.country_name }}</v-card-text>
                        <v-card-actions>
                            <v-btn outlined block :href="'https://maps.app.goo.gl/' + v.gmaps" target="_blank">
                                <v-icon>mdi-crosshairs-gps</v-icon>&nbsp;&nbsp;Ver en Google Maps
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Offices",
        props: [ 'user' ],
        data: () => ({
            offices: [],
            prox: false,
            c: 0
        }),
        created () {
            this.getOffices();
        },
        watch: {
            user() {
                this.getOffices();
            }
        },
        methods: {
            getOffices() {
                axios.get('/api/offices').then((res) => {
                    this.offices = res.data.offices;
                    this.prox = res.data.c === 0;
                    this.c = res.data.c;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            }
        }
    }
</script>

<style scoped>

</style>
