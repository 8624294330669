<template>
    <v-card>
        <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
            <v-toolbar dark color="primary">
                <v-btn class="ml-1" icon dark @click="closeEdit">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer/>
                <v-toolbar-title class="warning--text text-center font-weight-bold text-uppercase">Actualizar Contacto</v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items>
                    <v-btn dark text type="submit" :loading="loading" :disabled="loading || !valid">Guardar</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text v-if="charge">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-text v-else>
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" sm="6">
                            <v-select
                                :label="labels.country"
                                :items="countries"
                                name="country"
                                v-model="form.country"
                                :error-messages="errors.country"
                                :disabled="loading"
                                :rules="[rules.required('country')]"
                                @select="clearErrors('country')"
                                prepend-icon="mdi-map-marker"
                                item-text="name"
                                item-value="code">
                                <template v-slot:selection="{ item }">
                                    <v-flex>
                                        <v-avatar size="28px">
                                            <img :src="'/img/country/'+item.code+'.png'" :alt="item.name.toLowerCase()"/>
                                        </v-avatar>
                                        <span class='ml-1'>{{ item.name }}</span>
                                    </v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar>
                                        <img :src="'/img/country/'+item.code+'.png'" :alt="item.name.toLowerCase()"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"/>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :label="labels.name"
                                v-model="form.name"
                                type="text"
                                name="name"
                                :error-messages="errors.name"
                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-account-circle"
                                @input="clearErrors('name')"
                            />
                        </v-col>
                        <v-col cols="12" :sm="form.bn === 'Nuestras Oficinas' ? 6 : 12">
                            <v-autocomplete
                                :label="labels.bank"
                                :items="banks"
                                name="bank"
                                v-model="form.bank"
                                :error-messages="errors.bank"
                                :disabled="loading"
                                :rules="[rules.required('bank')]"
                                @select="clearErrors('bank')"
                                prepend-icon="mdi-domain"
                                item-text="name"
                                item-value="code"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.bn === 'Nuestras Oficinas'">
                            <v-autocomplete
                                :label="labels.branch"
                                :items="branches"
                                name="branch"
                                v-model="form.branch"
                                :error-messages="errors.branch"
                                :disabled="loading"
                                :rules="[rules.required('branch')]"
                                @select="clearErrors('branch')"
                                prepend-icon="mdi-store"
                                item-text="name"
                                item-value="id"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                :label="labels.td"
                                :items="td"
                                name="td"
                                v-model="form.td"
                                :error-messages="errors.td"
                                :disabled="loading"
                                :rules="[rules.required('td')]"
                                @select="clearErrors('td')"
                                prepend-icon="mdi-clipboard-text"
                                item-text="name"
                                item-value="value">
                                <template v-slot:selection="{ item }">
                                    <v-flex class='ml-1'>{{ item.value + ' - ' + item.name }}</v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.value + ' - ' + item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-if="form.country === 'CHL' && (form.td === 'CI' || form.td === 'CE')"
                                :label="labels.nd"
                                v-model="form.nd"
                                type="text"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"
                                v-mask="rut"/>
                            <v-text-field
                                v-else-if="form.td === 'PAS'"
                                :label="labels.nd"
                                v-model="form.nd"
                                type="text"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"/>
                            <v-text-field
                                v-else
                                :label="labels.nd"
                                v-model="form.nd"
                                type="number"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.bn !== 'Nuestras Oficinas'">
                            <v-select
                                :label="labels.tc"
                                :items="tc"
                                name="tc"
                                v-model="form.tc"
                                :error-messages="errors.tc"
                                :disabled="loading"
                                :rules="[rules.req('tc')]"
                                @select="clearErrors('tc')"
                                prepend-icon="mdi-wallet"
                                item-text="name"
                                item-value="value"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.bn !== 'Nuestras Oficinas'">
                            <v-text-field
                                :label="labels.nc"
                                v-model="form.nc"
                                name="nc"
                                :error-messages="errors.nc"
                                :rules="[rules.required('nc'), rules.min('nc', 6, 'El'), rules.max('nc', 20, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-dialpad"
                                @input="clearErrors('nc')"
                                v-if="form.country !== 'VEN'"/>
                            <v-text-field
                                :label="labels.nc"
                                v-model="form.nc"
                                name="nc"
                                :prefix="form.bank"
                                :error-messages="errors.nc"
                                :rules="[rules.required('nc'), rules.minT('nc', 19, 16, 'El'), rules.maxT('nc', 19, 16, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-dialpad"
                                @input="clearErrors('nc')"
                                v-mask="ven"
                                v-else/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
import axios from "axios";
import Form from "../../helpers/form";
import {mask} from 'vue-the-mask';

export default {
    name: "Edit",
    mixins: [ Form ],
    props: [ 'receiver' ],
    directives: { mask },
    data: () => ({
        banks: [],
        branches: [],
        countries: [
            { name: 'Brasil', code: 'BRA' },
            { name: 'Chile', code: 'CHL' },
            { name: 'Colombia', code: 'COL' },
            { name: 'Ecuador', code: 'ECU' },
            { name: 'Mexico', code: 'MEX' },
            { name: 'Panama', code: 'PAN' },
            { name: 'Peru', code: 'PER' },
            { name: 'Estados Unidos', code: 'USA' },
            { name: 'Venezuela', code: 'VEN' }
        ],
        td: [],
        tc: [],
        labels: {
            country: 'País',
            name: 'Nombre',
            td: 'Tipo de Documento',
            nd: 'Número de Documento',
            bank: 'Entidad Bancaria',
            branch: 'Oficina de Pago',
            tc: 'Tipo de Cuenta',
            nc: 'Número de Cuenta'
        },
        charge: true,
        loading: false,
        form: {
            country: null,
            name: null,
            td: null,
            nd: null,
            bank: null,
            branch: null,
            bn: null,
            br: null,
            tc: null,
            nc: null
        },
        c: 0,
        ven: '#### #### #### ####',
        rut: '########-X'
    }),
    mounted() {
        this.getReceiver();
    },
    watch: {
        receiver() {
            this.getReceiver();
        },
        'form.country'() {
            if (this.form.country === null || this.form.country === undefined) {
                this.td = [];
                this.banks = [];
            } else {
                this.getTypeDoc(this.form.country);
                this.getBanks(this.form.country);
                this.getTypeAcc(this.form.country);
            }
            if (this.c === 0) {
                this.form.td = null;
                this.form.nd = null;
                this.form.bank = null;
                this.form.tc = null;
                this.form.nc = null;
                this.$refs.form.resetValidation();
            }
            this.c = 0;
        },
        'form.nd'() {
            if (this.form.td === 'PAS') {
                this.form.nd = (this.form.nd).toUpperCase();
            }
        },
        'form.bank'() {
            if (this.form.bank) {
                this.getBank();
            } else {
                this.form.bn = null;
            }
        },
        'form.branch'() {
            if (this.form.branch) {
                this.getBranch();
            } else {
                this.form.br = null;
            }
        }
    },
    methods: {
        getBanks(country) {
            axios.get('/api/banks/' + country).then((res) => {
                this.banks = res.data.banks;
                this.branches = res.data.branches;
            });
        },
        getReceiver() {
            if (this.$props.receiver !== null && this.$props.receiver !== undefined) {
                this.c = 1;
                this.loading = true;
                axios.get('/api/receivers/edit/' + this.$props.receiver).then((res) => {
                    this.form = res.data.receiver;
                    this.form.nc = res.data.receiver.country === 'VEN' && res.data.receiver.nc ? (res.data.receiver.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : res.data.receiver.nc;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.charge = false;
                    this.loading = false;
                });
            }
        },
        getTypeDoc(c) {
            if (c === 'MEX' || c === 'USA') {
                this.td = [
                    {name: 'Licencia de Conducir', value: 'LIC'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            } else if (c === 'PER') {
                this.td = [
                    {name: 'Documento Nacional de Identidad', value: 'DNI'},
                    {name: 'Carnet de Extranjeria', value: 'CE'},
                    {name: 'Permiso Temporal de Permanencia', value: 'PTP'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            } else if (c === 'COL') {
                this.td = [
                    {name: 'Cedula de Ciudadania', value: 'CC'},
                    {name: 'Cedula de Extranjeria', value: 'CE'},
                    {name: 'Número de Identificación Tributaria', value: 'NIT'},
                    {name: 'Permiso por Protección Temporal', value: 'PPT'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            } else if (c === 'VEN') {
                this.td = [
                    {name: 'Cedula de Identidad', value: 'V'},
                    {name: 'Carnet de Extranjeria', value: 'E'},
                    {name: 'RIF', value: 'J'},
                    { name: 'Gubernamental', value: 'G' },
                    { name: 'Firma Personal', value: 'M' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            } else if (c === 'CHL') {
                this.td = [
                    {name: 'Rol Único Tributario', value: 'RUT'},
                    {name: 'Rol Único Nacional', value: 'RUN'},
                    {name: 'Cedula de Identidad', value: 'CI'},
                    {name: 'Cedula de Extranjeria', value: 'CE'},
                    {name: 'Licencia de Conducir', value: 'LIC'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            } else {
                this.td = [
                    {name: 'Cedula de Identidad', value: 'CI'},
                    {name: 'Cedula de Extranjeria', value: 'CE'},
                    {name: 'Licencia de Conducir', value: 'LIC'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            }
        },
        getTypeAcc(c) {
            if (c === 'CHL') {
                this.tc = [
                    {name: 'Cuenta de Ahorros', value: 'Ahorros'},
                    {name: 'Cuenta Corriente', value: 'Corriente'},
                    {name: 'Cuenta Vista', value: 'Vista'},
                    {name: 'Cuenta RUT', value: 'RUT'}
                ]
            } else {
                this.tc = [
                    {name: 'Cuenta de Ahorros', value: 'Ahorros'},
                    {name: 'Cuenta Corriente', value: 'Corriente'}
                ]
            }
        },
        getBank() {
            const banks = this.banks;

            for (let i = 0; i < banks.length; i++) {
                if (banks[i].code === this.form.bank) {
                    if (banks[i].name === 'Nuestras Oficinas') {
                        this.form.tc = null;
                        this.form.nc = null;
                    } else {
                        this.form.branch = null;
                    }
                    this.form.bn = banks[i].name;
                }
            }
        },
        getBranch() {
            const branches = this.branches;

            for (let i = 0; i < branches.length; i++) {
                if (branches[i].code === this.form.branch) {
                    this.form.br = branches[i].name;
                }
            }
        },
        closeEdit() {
            this.$emit('close-edit');
            this.$refs.form.reset();
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let formData = new FormData();

                formData.append('country', this.form.country);
                formData.append('name', this.form.name);
                formData.append('td', this.form.td);
                formData.append('nd', this.form.nd);
                formData.append('bank', this.form.bank);
                formData.append('branch',  this.form.branch);
                formData.append('tc', this.form.tc);
                formData.append('nc', this.form.nc ? (this.form.nc).replace(/ /g, '') : null);

                axios.post('/api/receivers/edit/' + this.$props.receiver, formData)
                    .then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.$refs.form.reset();
                            this.$emit('close-edit');
                            this.$emit('success');
                        } else {
                            this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                        }
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    })
                    .then(() => {
                        this.loading = false;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
