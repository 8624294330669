<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-0"
                                  style="word-break: normal">TICKET Nº {{ id.length < 5 ? ('0000' + id).slice(-4) : id }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="6" align-self="stretch">
                <v-card height="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text" style="word-break: normal">DETALLES
                        <v-btn outlined fab small class="float-right grey--text text--darken-2" @click="details = !details"
                               v-show="$vuetify.breakpoint.smAndDown">
                            <v-icon>{{details ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider/>
                    <v-card-subtitle v-show="$vuetify.breakpoint.smAndDown ? details : true" class="text-center pb-6">
                        <strong>Transacción Nº {{ claim.transfer_id }}</strong>
                    </v-card-subtitle>
                    <v-card-text v-show="$vuetify.breakpoint.smAndDown ? details : true">
                        <v-row justify="center">
                            <v-col cols="6" class="py-0">
                                <h3 class="text-center"><strong>País</strong></h3>
                                <p class="text-center">{{ claim.country }}</p>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <h3 class="text-center"><strong>Relación</strong></h3>
                                <p class="text-center">{{ claim.role }}</p>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <h3 class="text-center"><strong>Teléfono</strong></h3>
                                <p class="text-center">{{ claim.phone }}</p>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <h3 class="text-center"><strong>Estado</strong></h3>
                                <p class="text-center" v-if="claim.status === 301">Reclamo Enviado</p>
                                <p class="text-center" v-else-if="claim.status === 302">Respuesta Recibida</p>
                                <p class="text-center" v-else>Reclamo Cerrado</p>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <h3 class="text-center"><strong>Forma de Pago</strong></h3>
                                <p class="text-center">{{ claim.pay_form === 'office' ? 'Oficina' : 'Deposito/Transferencia' }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <h3 class="text-center"><strong>Fecha de Creación</strong></h3>
                                <p class="text-center">{{ claim.created_at }}</p>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <h3 class="text-center"><strong>Motivo</strong></h3>
                                <p class="text-center">{{ claim.reason }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" align-self="stretch">
                <v-card height="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text" style="word-break: normal">MENSAJES</v-card-title>
                    <v-divider/>
                    <v-card-text style="height: 320px; overflow-y: auto; overflow-x: hidden; padding: 0" v-chat-scroll>
                        <template v-for="msg in messages">
                            <v-row v-if="msg.user_id === user.id">
                                <v-col cols="11" offset="1">
                                    <v-list-item class="px-2">
                                        <v-list-item-content class="float-right py-0">
                                            <v-list-item-title>
                                                <b class="float-right grey--text text--darken-2">
                                                    <v-icon size="20" v-if="msg.role !== 'Customer'">mdi-face-agent</v-icon>
                                                    {{ msg.name }} (Tú)
                                                </b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="px-4 py-2 light-blue lighten-4 primary--text" style="border-radius: 6px; white-space: normal">{{ msg.text }}</v-list-item-subtitle>
                                            <v-list-item-action-text>
                                                <span class="caption">{{ msg.created_at }}</span>
                                            </v-list-item-action-text>
                                        </v-list-item-content>
                                        <v-list-item-avatar class="ml-2" size="48">
                                            <img src="/img/cliente.png" :alt="msg.name" style="border: 1px solid #9e9e9e" v-if="msg.role === 'Customer'">
                                            <img src="/img/soporte.jpg" :alt="msg.name" style="border: 1px solid #9e9e9e" v-else>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="11">
                                    <v-list-item class="px-2">
                                        <v-list-item-avatar class="mr-2" size="48">
                                            <img src="/img/cliente.png" :alt="msg.name" style="border: 1px solid #9e9e9e" v-if="msg.role === 'Customer'">
                                            <img src="/img/soporte.jpg" :alt="msg.name" style="border: 1px solid #9e9e9e" v-else>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title>
                                                <b class="grey--text text--darken-2">{{ msg.name }}
                                                    <v-icon size="20" v-if="msg.role !== 'Customer'">mdi-face-agent</v-icon>
                                                </b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="px-4 py-2 amber lighten-4 primary--text" style="border-radius: 6px; white-space: normal">{{ msg.text }}</v-list-item-subtitle>
                                            <v-list-item-action-text>
                                                <span class="float-right caption">{{ msg.created_at }}</span>
                                            </v-list-item-action-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card-text>
                    <v-divider v-if="claim.status === 301 || claim.status === 302"/>
                    <v-card-actions class="pb-0" v-if="claim.status === 301 || claim.status === 302">
                        <v-row justify="center">
                            <v-col cols="12" class="py-0 px-4">
                                <v-form ref="form" @submit.prevent="sendMessage" lazy-validation v-model="valid">
                                    <v-text-field
                                        :label="labels.message"
                                        type="text"
                                        name="message"
                                        counter="255"
                                        v-model="form.message"
                                        placeholder="Escribe aquí"
                                        filled
                                        clearable
                                        :disabled="loading"
                                        :loading="loading"
                                        :error-messages="errors.email"
                                        :rules="[rules.min('message', 1, 'La'), rules.max('message', 255, 'La')]"
                                        @input="clearErrors('message')"
                                        clear-icon="mdi-close-circle"
                                        :append-icon="loading ? 'mdi-loading' : 'mdi-send'"
                                        prepend-icon="mdi-camera"
                                        prepend-inner-icon="mdi-emoticon"
                                        @click:prepend=""
                                        @click:prepend-inner=""
                                        @click:append="sendMessage"
                                        @click:clear="clearMessage"/>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../helpers/form";

    export default {
        name: "ClaimView",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            id: '',
            claim: {},
            messages: [],
            details: false,
            loading: false,
            labels: {
                message: 'Respuesta'
            },
            form: {
                message: ''
            }
        }),
        created () {
            this.ini();
            this.getClaim();
        },
        methods: {
            ini() {
                this.id = this.$route.params.id;
            },
            getClaim() {
                axios.get('/api/claims/view/' + this.id)
                    .then((res) => {
                        if (res.data.v === 'success') {
                            this.claim = res.data.claim;
                            this.messages = res.data.messages;
                        } else {
                            this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                        }
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    });
                this.timeout = setTimeout(this.getClaim, 60000);
            },
            sendMessage() {
                this.loading = true;
                axios.post('/api/claims/' + this.id + '/msg/send', this.form)
                    .then((res) => {
                        if (res.data.v === 'success') {
                            this.getClaim();
                            this.clearMessage();
                            this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                        } else {
                            this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                        }
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
            clearMessage() {
                this.$refs.form.reset();
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
