<template>
    <div>
        <v-card>
            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
            <v-card-title class="d-block text-center font-weight-bold primary warning--text" style="word-break: normal">
                <span class="headline">Información ID: {{ $props.info }}</span>
            </v-card-title>
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12" md="6" v-if="loading === true">
                        <v-skeleton-loader class="mx-auto" width="100%" type="card"/>
                    </v-col>
                    <template v-for="item in inf" v-else>
                        <v-col cols="12" md="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th>Transacción ID</th>
                                        <th>{{ item.id }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Nombres</td>
                                        <td>{{ item.name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Documento</td>
                                        <td>{{ item.td + ' ' + item.nd }}</td>
                                    </tr>
                                    <tr>
                                        <td>Banco</td>
                                        <td>{{ item.bank }}</td>
                                    </tr>
                                    <tr v-if="item.branch">
                                        <td>Sucursal</td>
                                        <td>{{ item.branch }}</td>
                                    </tr>
                                    <tr>
                                        <td>Valor Enviado</td>
                                        <td>{{ item.amount_send }}</td>
                                    </tr>
                                    <tr>
                                        <td>Tasa de Cambio</td>
                                        <td>{{ item.fx_rate }}</td>
                                    </tr>
                                    <tr>
                                        <td>Costo de Pago</td>
                                        <td>{{ item.fee }}</td>
                                    </tr>
                                    <tr>
                                        <td>Valor Destino</td>
                                        <td>{{ item.amount_receiver }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn color="success" dark :to="'/history/payment/' + $props.info" style="margin: 1px">Ver Todo</v-btn>
                <v-spacer/>
                <v-btn color="error" @click="closeInfo">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Info",
        props: [ 'info' ],
        data: () => ({
            loading: false,
            inf: null
        }),
        mounted() {
            this.getData();
        },
        watch: {
            info() {
                if (this.info !== null) {
                    this.getData();
                }
            }
        },
        methods: {
            getData() {
                this.loading = true;
                axios.get('/api/transfers/details/' + this.$props.info).then((res) => {
                    this.inf = res.data.info;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            closeInfo() {
                this.inf = null;
                this.$emit('close-info');
            },
        }
    }
</script>

<style scoped>

</style>
