-<template>
    <v-container class="mb-12 mb-lg-0">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="6" size="48"></v-progress-circular>
        </v-overlay>
        <v-row justify="center">
            <v-col cols="12">
                <v-form ref="formPass" @submit.prevent="changePass" lazy-validation v-model="valid">
                    <v-card class="mb-8">
                        <v-card-title class="d-block py-sm-2 text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">
                            <v-icon color="primary">mdi-shield-lock</v-icon>&nbsp;&nbsp;CAMBIAR CONTRASEÑA
                        </v-card-title>
                        <v-card-text class="pt-8">
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.password"
                                        name="password"
                                        v-model="form.password"
                                        :append-icon="newPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="() => (newPasswordHidden = !newPasswordHidden)"
                                        :type="newPasswordHidden ? 'password' : 'text'"
                                        :error-messages="errors.password"
                                        :disabled="loading"
                                        :rules="[rules.req('password'), rules.min('password', 8, 'La')]"
                                        hint="Mínimo 8 caracteres"
                                        @input="clearErrors('password')"
                                        prepend-icon="mdi-lock"/>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.password_confirmation"
                                        name="password_confirmation"
                                        v-model="form.password_confirmation"
                                        :type="newPasswordHidden ? 'password' : 'text'"
                                        :error-messages="errors.password_confirmation"
                                        :disabled="loading"
                                        :rules="[rules.req('password_conf')]"
                                        @input="clearErrors('password_confirmation')"
                                        prepend-icon="mdi-lock"/>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.old_password"
                                        name="old_password"
                                        v-model="form.old_password"
                                        :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="() => (passwordHidden = !passwordHidden)"
                                        :type="passwordHidden ? 'password' : 'text'"
                                        :error-messages="errors.old_password"
                                        :disabled="loading"
                                        :rules="[rules.req('old_password')]"
                                        @input="clearErrors('old_password')"
                                        prepend-icon="mdi-lock"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="align-content-center">
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="5" lg="4">
                                    <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="primary" large block>
                                        <v-icon>mdi-lock-check</v-icon>&nbsp;&nbsp;Actualizar Contraseña
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../helpers/form";
    import { mask }from "vue-the-mask";

    export default {
        name: "Password",
        mixins: [Form],
        props: [ 'user' ],
        data: () => ({
            passwordHidden: true,
            newPasswordHidden: true,
            labels: {
                old_password: 'Contraseña Actual',
                password: 'Nueva Contraseña',
                password_conf: 'confirmación de la contraseña',
                password_confirmation: 'Confirmar Contraseña',
            },
            form: {
                old_password: null,
                password: null,
                password_confirmation: null,
            }
        }),
        created () {
            this.$emit('success');
        },
        methods: {
            changePass() {
                if (this.$refs.formPass.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('old_password', this.form.old_password);
                    formData.append('password', this.form.password);
                    formData.append('password_confirmation', this.form.password_confirmation);

                    axios.post('/api/password/update', formData)
                        .then((res) => {
                            if (res.data.v === 'info') {
                                this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                            } else {
                                this.$toast.success('¡Tu perfil se ha actualizado exitosamente!', {icon: 'mdi-check-circle'});
                                this.$store.dispatch('setUser', res.data);
                                this.$emit('success');
                                this.form.old_password = null;
                                this.form.password = null;
                                this.form.password_confirmation = null;
                                this.$refs.formPass.reset();
                            }
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>
