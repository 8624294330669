<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">PENDIENTES POR PAGAR</v-card-title>
                    <v-btn bottom color="warning primary--text" dark depressed fab absolute right small @click="getPayments(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize;">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="success" small depressed dark @click="getPay(item.id)" style="margin: 1px">
                                <v-icon>mdi-cash</v-icon>
                            </v-btn>
                            <v-btn color="primary" small depressed dark :to="'/history/transfer/' + item.id" style="margin: 1px">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="payment" scrollable persistent max-width="800px">
                    <v-form ref="form" @submit.prevent="submitPay" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">Pago de Transacción (ID: {{ PayData.id }})</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" md="10">
                                        <h3 class="text-center"><u>Nombres</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4" style="text-transform: capitalize">{{ PayData.name ? (PayData.name).toLowerCase() : '' }}</h2>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5" v-if="PayData.td">
                                        <h3 class="text-center"><u>Tipo de Documento</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.td }}</h2>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5" v-if="PayData.nd">
                                        <h3 class="text-center"><u>Número de Documento</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.td === 'PAS' || PayData.td === 'RUT' ? PayData.nd : Number(PayData.nd).toLocaleString("de-DE") }}</h2>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <h3 class="text-center"><u>Tasa de Cambio</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.fx_rate }}</h2>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <h3 class="text-center"><u>Valor Enviado</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.amount_send_text }}</h2>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <h3 class="text-center"><u>Valor a Pagar</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.amount_receiver_text }}</h2>
                                    </v-col>
                                    <v-col cols="12" md="10" v-if="PayData.additional !== null">
                                        <h3 class="text-center"><u>Datos Adicionales</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ PayData.additional }}</h2>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" md="10">
                                        <h3 class="text-center"><u>Oficina Abierta</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4" v-if="user.office_id !== 0">{{ office.name }}</h2>
                                        <h2 class="text-center red--text text--darken-1 pa-3 grey lighten-4" v-else>Debe seleccionar una oficina para poder registrar el pago</h2>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="closePay">
                                    <v-icon>mdi-close-circle</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Cerrar</span>
                                </v-btn>
                                <v-spacer/>
                                <v-btn color="primary" @click="print" :disabled="office.name === null">
                                    <v-icon>mdi-printer</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Imprimir</span>
                                </v-btn>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid || user.office_id === 0" color="success">
                                    <v-icon>mdi-hand-coin</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Pagar</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <div id="ticket" style="width: 280px; max-width: 280px; font-size: 14px; margin: 0;">
                            <img src="/img/logo-icoin-dark.png" alt="logo icoin" title="Logo iCoin" style="max-width: 280px"/>
                            <p style="font-family: 'Trebuchet MS', Helvetica, sans-serif; text-align: center; width: 280px; margin: 0;">
                                <strong><u>TICKET #{{ PayData.payment_id }}</u></strong>
                                <br><br>
                                <span v-if="office">{{ office.name }}</span>
                                <br>
                                <span v-if="office">{{ office.address + ' - ' }}{{ office.city + ', ' }}{{ office.country }}</span>
                                <br><br>
                                <span style="text-transform: capitalize;">Cajero: {{ user.name ? (user.name).toLowerCase() : '' }}</span>
                                <br>
                                <span>{{ new Date() | moment("DD/MM/YYYY hh:mm:ss A") }}</span>
                                <br>
                                <span>Válido por 30 Días</span>
                                <br><br>
                            </p>
                            <table style="font-family: 'Trebuchet MS', Helvetica, sans-serif; margin: 0; border-top: 1px solid black; border-collapse: collapse;">
                                <thead>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <th style="width: 120px; max-width: 120px;">ID Transacción</th>
                                    <th style="width: 160px; max-width: 160px;">{{ PayData.id }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <td style="width: 120px; max-width: 120px;">Beneficiario</td>
                                    <td style="width: 160px; max-width: 160px; text-transform: capitalize;">{{ PayData.name ? (PayData.name).toLowerCase() : '' }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.nd">
                                    <td style="width: 120px; max-width: 120px;">Documento</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.td + ' ' + (PayData.td === 'PAS' || PayData.td === 'RUT' ? PayData.nd : Number(PayData.nd).toLocaleString("de-DE")) }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <td style="width: 120px; max-width: 120px;">Banco</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.bank_name }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.tc">
                                    <td style="width: 120px; max-width: 120px;">Tipo de Cuenta</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.tc }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.nc">
                                    <td style="width: 120px; max-width: 120px;">Número de Cuenta</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.nc }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <td style="width: 120px; max-width: 120px;">Valor Enviado</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.amount_send_text }} {{ PayData.currency_send }}</td>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <td style="width: 120px; max-width: 120px;">Tasa de Cambio</td>
                                    <td style="width: 160px; max-width: 160px;">{{ PayData.fx_rate }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <th style="width: 120px; max-width: 120px;">Valor a Pagar</th>
                                    <th style="width: 160px; max-width: 160px;">{{ PayData.amount_receiver_text }} {{ PayData.currency_receiver }}</th>
                                </tr>
                                <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                    <th colspan="2"/>
                                </tr>
                                </tfoot>
                            </table>
                            <div style="text-align: center; font-family: 'Trebuchet MS', Helvetica, sans-serif; width: 280px; margin: 0;">
                                <br>
                                <br>
                                <p style="font-size: 16px; margin: 4px 0;">
                                    <b>Firma: ________________________</b>
                                </p>
                                <br>
                                <p style="font-size: 16px; margin: 4px 0;">
                                    <b>Documento: ___________________</b>
                                </p>
                                <br>
                                <p style="align-content: center; font-size: 16px;">
                                    <b>¡GRACIAS POR PREFERIRNOS!</b>
                                </p>
                                <br>
                                <br>
                            </div>
                        </div>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: "CsPending",
        mixins: [ Form ],
        props: [ 'user', 'office' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Nombre', value: 'name' },
                { text: 'Documento', value: 'nd' },
                { text: 'Recibido', value: 'amount_send_text', align: 'end' },
                { text: 'Pagar', value: 'amount_receiver_text', align: 'end' },
                { text: 'Paises', value: 'countries' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes pagos pendientes',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            payment: false,
            PayData: {}
        }),
        created () {
            this.getPayments();
        },
        watch: {
            user() {
                this.getPayments(1);
            }
        },
        methods: {
            getPayments(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/cashier/pay').then((res) => {
                    if (res.data.transfers) {
                        this.desserts = res.data.transfers;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getPay(id) {
                this.payment = true;
                const payments = this.desserts;

                for (let i = 0; i < payments.length; i++) {
                    if (payments[i].id === id) {
                        this.PayData = payments[i];
                    }
                }
            },
            closePay() {
                this.payment = false;
                this.PayData = {};
            },
            submitPay() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let formData = new FormData();
                    formData.append('id',  this.PayData.id);
                    axios.post('/api/cashier/payment', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.print();
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.closePay();
                            this.getPayments();
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            print() {
                let printContents = document.getElementById('ticket').innerHTML;
                let w = window.open(' ', 'popimpr');
                w.document.write(printContents);
                w.document.close(); // necessary for IE >= 10
                w.focus(); // necessary for IE >= 10
                w.print();
                w.close();
            }
        }
    }
</script>

<style scoped>
    @media print {
        #ticket {}
    }
    @media screen {
        #ticket {
            display: none;
        }
    }
</style>
