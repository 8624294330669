<template>
    <v-container fluid class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12" md="11">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-0"
                                  style="word-break: normal">Transacción Nº {{ details.transfer.id }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="11">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">ESTADO ACTUAL: {{ details.transfer.status ? details.transfer.status.toUpperCase() : '' }}
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-row justify="center" v-if="user && user.role !== 'Customer'">
                            <v-col cols="12">
                                <h2 class="text-center primary--text pb-4">
                                    <u>Datos del Cliente</u>
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="user && user.role !== 'Customer'">
                            <v-col cols="12" sm="6" md="4" class="py-0">
                                <h3 class="text-center"><strong>Nombres</strong></h3>
                                <p class="text-center" style="text-transform: capitalize">{{ details.user.name ? (details.user.name).toLowerCase() : '' }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="py-0">
                                <h3 class="text-center"><strong>Correo Electrónico</strong></h3>
                                <p class="text-center">{{ details.user.email }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-6" v-if="user && user.role !== 'Customer'">
                            <v-col cols="6" md="4">
                                <h3 class="text-center"><strong>Documento</strong></h3>
                                <p class="text-center">{{ details.user.nd === null ? '**********' : details.user.td + ' ' + details.user.nd }}</p>
                            </v-col>
                            <v-col cols="6" md="4">
                                <h3 class="text-center"><strong>Categoria</strong></h3>
                                <p class="text-center">{{ details.user.role === 'Customer' ? 'Cliente' : details.user.role }}</p>
                            </v-col>
                            <v-col cols="12" md="4">
                                <h3 class="text-center"><strong>Fecha de Registro</strong></h3>
                                <p class="text-center">{{ details.user.created_at }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12">
                                <h2 class="text-center primary--text pb-4">
                                    <u>Datos del Destinatario</u>
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="6" md="4" class="py-0">
                                <h3 class="text-center"><strong>Nombres</strong></h3>
                                <p class="text-center" style="text-transform: capitalize">{{ details.transfer.name ? (details.transfer.name).toLowerCase() : '' }}</p>
                            </v-col>
                            <v-col cols="6" md="4" class="py-0">
                                <h3 class="text-center"><strong>Documento</strong></h3>
                                <p class="text-center">{{ details.transfer.nd === null ? '**********' : details.transfer.td + ' - ' + details.transfer.nd }}</p>
                            </v-col>
                            <v-col cols="6" md="4">
                                <h3 class="text-center"><strong>Medio de Pago</strong></h3>
                                <p class="text-center">{{ details.transfer.bank_name }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-6">
                            <v-col cols="6" md="4">
                                <h3 class="text-center"><strong>Tipo de Cuenta</strong></h3>
                                <p class="text-center">{{ details.transfer.tc === null ? '********' : details.transfer.tc }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <h3 class="text-center"><strong>Número de Cuenta</strong></h3>
                                <p class="text-center" v-if="details.transfer.bank_country === 'VEN'">{{ details.transfer.nc ? (details.transfer.bank_code + details.transfer.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : '' }}</p>
                                <p class="text-center" v-else>{{ details.transfer.nc === null ? '********' : details.transfer.nc }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <h3 class="text-center"><strong>Tasa de Cambio</strong></h3>
                                <p class="text-center">{{ details.transfer.fx_rate }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <h3 class="text-center"><strong>Valor Enviado</strong></h3>
                                <p class="text-center">{{ details.transfer.amount_send }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <h3 class="text-center"><strong>Costo de Pago</strong></h3>
                                <p class="text-center">{{ details.transfer.fee }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <h3 class="text-center"><strong>Valor Destino</strong></h3>
                                <p class="text-center">{{ details.transfer.amount_receiver }}</p>
                            </v-col>
                            <v-col cols="12" md="10" v-if="details.transfer.additional">
                                <h3 class="text-center"><strong>Datos Adicionales</strong></h3>
                                <p class="text-center">{{details.transfer.additional}}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="user && user.role !== 'Customer'">
                            <v-col cols="12">
                                <h2 class="text-center primary--text pb-4">
                                    <u>Datos Internos</u>
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-6" v-if="user && user.role !== 'Customer'">
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <h3 class="text-center"><strong>Valor Pago</strong></h3>
                                <p class="text-center">{{ details.payment.amount + ' ' + details.payment.currency }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <h3 class="text-center"><strong>Comisión Bancaria</strong></h3>
                                <p class="text-center">{{ details.payment.bank_fee + ' ' + details.payment.currency }}</p>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <h3 class="text-center"><strong>Comisión Transaccional</strong></h3>
                                <p class="text-center">{{ details.payment.fee + ' ' + details.payment.currency }}</p>
                            </v-col>
                            <v-col cols="6" md="4" lg="3">
                                <h3 class="text-center"><strong>País</strong></h3>
                                <p class="text-center">{{ details.transfer.countries }}</p>
                            </v-col>
                            <v-col cols="6" md="4" lg="3">
                                <h3 class="text-center"><strong>Forma de Pago</strong></h3>
                                <p class="text-center">{{ details.payment.type === 'Bank' ? 'Deposito/Transferencia' : 'Efectivo' }}</p>
                            </v-col>
                            <v-col cols="6" md="4" lg="3">
                                <h3 class="text-center"><strong>{{ details.payment.type === 'Office' ? 'Cashier' : 'Checker' }}</strong></h3>
                                <p class="text-center" style="text-transform: capitalize">{{ details.transfer.checker ? (details.transfer.checker).toLowerCase() : '' }}</p>
                            </v-col>
                            <v-col cols="6" md="4" lg="3">
                                <h3 class="text-center"><strong>Pagador</strong></h3>
                                <p class="text-center" style="text-transform: capitalize">{{ details.transfer.payer ? (details.transfer.payer).toLowerCase() : '' }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12">
                                <h2 class="text-center primary--text pb-4">
                                    <u>COMPROBANTES</u>
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="6" class="text-center py-2">
                                <v-btn color="primary" @click="voucher1 = true" v-if="details.payment.voucher">
                                    <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Voucher de Pago
                                </v-btn>
                                <v-btn color="default" disabled v-else>
                                    <v-icon>mdi-image-off</v-icon>&nbsp;&nbsp;No Disponible
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center py-2">
                                <v-btn color="primary" @click="voucher2 = true" v-if="details.transfer.voucher">
                                    <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Voucher de Giro
                                </v-btn>
                                <v-btn color="default" disabled v-else>
                                    <v-icon>mdi-image-off</v-icon>&nbsp;&nbsp;No Disponible
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="details.transfers && (details.transfers).length > 0">
                            <v-col cols="12" class="pt-6">
                                <h2 class="text-center primary--text pb-4">
                                    <u>OTRAS TRANSACCIONES</u>
                                </h2>
                                <br>
                                <v-data-table :headers="headers" :items="details.transfers" :expanded.sync="expanded"
                                              :loading="loading" :expand-icon="expandIcon" disable-pagination
                                              disable-sort disable-filtering single-expand hide-default-footer
                                              show-expand style="border: 1px solid #ddd">
                                    <template v-slot:item.id="{ item }">
                                        <strong>{{ item.id }}</strong>
                                    </template>
                                    <template v-slot:item.name="{ item }">
                                        <span style="text-transform: capitalize">{{ item.name ? (item.name).toLowerCase() : '' }}</span>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-chip :color="getColor(item.status_id)" dark>{{ item.status }}</v-chip>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn color="info" small depressed class="ma-1" :disabled="!item.voucher" title="Ver" @click="getVoucher(item.id)">
                                            <v-icon>mdi-image-area</v-icon>
                                        </v-btn>
                                        <v-btn color="primary" small depressed class="ma-1" title="Detalles" :to="'/history/transfer/' + item.id">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length" class="py-4">
                                            <v-row>
                                                <v-col cols="12" md="6" class="py-2">
                                                    <div class="text-center">
                                                        <strong>Medio de Pago: </strong>{{ item.bank_name }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6" class="py-2">
                                                    <div class="text-center" v-if="item.country_receiver === 'VEN'">
                                                        <strong>Cuenta {{ (item.tc === 'Ahorros' ? 'de ' : '') + item.tc }}:</strong> {{ (item.bank_code + item.nc).match(/.{1,4}/g).join(' ') }}
                                                    </div>
                                                    <div class="text-center" v-else-if="item.nc === null">
                                                        <strong>Documento:</strong> {{ item.td }} - {{ item.nd }}
                                                    </div>
                                                    <div class="text-center" v-else>
                                                        <strong>Cuenta {{ (item.tc === 'Ahorros' ? 'de ' : '') + item.tc }}:</strong> {{ item.nc }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="6" class="py-2">
                                                    <div class="text-center">
                                                        <strong>Fecha de Creación: </strong>{{ item.created_at }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6" class="py-2">
                                                    <div class="text-center">
                                                        <strong>Última Actualización: </strong>{{ item.updated_at }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="10" class="pt-12 pb-6 pb-md-12">
                                <h2 class="text-center primary--text">
                                    <v-btn color="primary" dark :to="(user.role === 'Customer' ? '/history?tab=transfers' : '')" @click="(user.role !== 'Customer' ? $router.go(-1) : '')">
                                        <v-icon>mdi-restore</v-icon>&nbsp;&nbsp;Regresar
                                    </v-btn>
                                </h2>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="voucher1" scrollable max-width="1000" width="80%" v-if="details.payment.voucher !== null">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                            style="color: #ffffff; word-break: normal; width: 100%">Comprobante de Pago</v-card-title>
                        <v-card-text>
                            <img :src="'/' + details.payment.voucher" :alt="details.payment.id" width="100%"/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="voucher1 = false"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                            <a class="v-btn theme--light v-size--default primary"
                               :href="'/' + details.payment.voucher"
                               :download="'Voucher_Payment_[' + details.payment.id + '].jpg'">
                                Descargar <v-icon dark>mdi-download</v-icon>
                            </a>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="voucher2" scrollable max-width="1000" width="80%" v-if="details.transfer.voucher !== null">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                            style="color: #ffffff; word-break: normal; width: 100%">Comprobante de Transacción</v-card-title>
                        <v-card-text>
                            <img :src="'/' + details.transfer.voucher" :alt="details.transfer.id" width="100%"/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="voucher2 = false"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                            <a class="v-btn theme--light v-size--default primary"
                               :href="'/' + details.transfer.voucher"
                               :download="'Voucher_Transfer_[' + details.transfer.id + '].jpg'">
                                Descargar <v-icon dark>mdi-download</v-icon>
                            </a>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="view" scrollable max-width="1000" width="80%" v-if="voucher && (details.transfers).length > 0">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                            style="color: #ffffff; word-break: normal; width: 100%">Comprobante # {{ voucherId }}</v-card-title>
                        <v-card-text>
                            <img :src="'/' + voucher" :alt="voucherId" width="100%"/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="view = false"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                            <a class="v-btn theme--light v-size--default primary"
                               :href="'/' + voucher" :download="'Voucher_Transfer_[' + voucherId + '].jpg'">
                                Descargar <v-icon dark>mdi-download</v-icon>
                            </a>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Show",
        props: ['user'],
        data: () => ({
            id: '',
            details: {
                user: {},
                payment: {},
                transfer: {},
                transfers: []
            },
            voucher1: false,
            voucher2: false,
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Beneficiario', value: 'name' },
                { text: 'Enviado', value: 'amount_send', align: 'right', sortable: false },
                { text: 'Pagado', value: 'amount_receiver', align: 'right', sortable: false },
                { text: 'Estado', value: 'status', align: 'left', sortable: false },
                { text: 'Acción', value: 'action', align: 'left', filterable: false, sortable: false },
                { text: '', value: 'data-table-expand', filterable: false }
            ],
            expanded: [],
            loading: true,
            expandIcon: 'mdi-chevron-down-circle',
            voucherId: null,
            voucher: null,
            view: false
        }),
        mounted () {
            this.getDetails();
        },
        methods: {
            getDetails() {
                this.id = this.$route.params.id;
                axios.get('/api/history/view/' + this.id)
                    .then((res) => {
                        if (res.data.v === 'success') {
                            this.details = res.data;
                            this.loading = false;
                        } else {
                            this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                        }
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    });
            },
            getColor(status) {
                if (status === 253) return 'tertiary';
                else if (status === 254) return 'success';
                else if (status === 251) return 'accent';
                else if (status === 255) return 'error';
                else return 'info';
            },
            getVoucher(id) {
                const items = this.details.transfers;

                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === id) {
                        this.voucher = items[i].voucher;
                    }
                }
                this.voucherId = id;
                this.view = true;
            }
        }
    }
</script>

<style scoped>

</style>
