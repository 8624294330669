<template>
    <v-layout align-sm-center justify-center>
        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
        <v-flex xs12 sm9 md10 lg8 class="py-4">
            <v-img src="/img/logo-icoin-dark.png" class="hidden-md-and-up mx-12 mb-6"/>
            <v-card flat color="transparent" class="ma-3">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-toolbar flat color="primary" class="mb-4">
                        <v-toolbar-title class="text-center font-weight-bold secondary--text headline" style="white-space: normal; width: 100%">RECUPERAR CONTRASEÑA</v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text class="px-0 pb-0">
                        <p class="text-center">Escriba una contraseña nueva.</p>
                        <v-text-field
                            :label="labels.password"
                            name="password"
                            v-model="form.password"
                            :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (passwordHidden = !passwordHidden)"
                            :type="passwordHidden ? 'password' : 'text'"
                            :error-messages="errors.password"
                            :disabled="loading"
                            :rules="[rules.req('password'), rules.min('password', 8, 'La')]"
                            hint="Mínimo 8 caracteres"
                            @input="clearErrors('password')"
                            prepend-inner-icon="mdi-lock"
                            filled/>
                        <v-text-field
                            :label="labels.password_confirmation"
                            name="password_confirmation"
                            v-model="form.password_confirmation"
                            :type="passwordHidden ? 'password' : 'text'"
                            :error-messages="errors.password_confirmation"
                            :disabled="loading"
                            :rules="[rules.req('password_conf')]"
                            @input="clearErrors('password')"
                            prepend-inner-icon="mdi-lock"
                            filled/>
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading || !valid"
                            color="primary"
                            class="font-weight-bold"
                            block large
                        >Actualizar contraseña</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from 'axios';
    import Form from "../../helpers/form";

    export default {
        name: "Reset",
        mixins: [ Form ],
        data: () => ({
            passwordHidden: true,
            labels: {
                password: 'Nueva Contraseña',
                password_conf: 'confirmación de la nueva contraseña',
                password_confirmation: 'Confirmar Nueva Contraseña'
            },
            form: {
                token: null,
                email: null,
                password: null,
                password_confirmation: null
            }
        }),
        created() {
          this.form.email = this.$route.query.email;
          this.form.token = this.$route.params.token;
        },
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    axios.post('/api/password/reset', this.form)
                        .then((res) => {
                            this.$toast.success('La contraseña se ha cambiado correctamente.', {
                                icon: 'mdi-check-circle'
                            });
                            this.success();
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        })
                }
            },
            success() {
                this.$router.push({ name: 'Login' });
            }
        }
    }
</script>

<style scoped>

</style>
