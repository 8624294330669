<template>
    <v-layout align-sm-center justify-center>
        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
        <v-flex xs12 sm9 md10 lg8 class="py-4">
            <v-img src="/img/logo-icoin-dark.png" class="hidden-md-and-up mx-12 mb-6"/>
            <v-card flat color="transparent" class="ma-3">
                <v-toolbar flat color="primary" class="mb-4">
                    <v-toolbar-title class="text-center font-weight-bold secondary--text headline" style="white-space: normal; width: 100%">CREAR CUENTA</v-toolbar-title>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
                <v-card-actions class="justify-space-around">
                    <facebook/>
                    <google/>
                </v-card-actions>
            </v-card>
            <v-card flat color="transparent" class="ma-3">
                <v-card-text class="text-center title py-2 pb-0">-o- Escribe tus Datos</v-card-text>
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card-text class="px-0 pb-0">
                        <v-select
                            :label="labels.country"
                            :items="items"
                            name="country"
                            v-model="form.country"
                            :error-messages="errors.country"
                            :disabled="loading"
                            :rules="[rules.required('country')]"
                            @select="clearErrors('country')"
                            prepend-inner-icon="mdi-map-marker"
                            item-text="name"
                            item-value="code"
                            filled>
                            <template v-slot:selection="{ item }">
                                <v-flex>
                                    <v-avatar size="28px" class="mr-2">
                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                    </v-avatar>{{ item.name }}
                                </v-flex>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-avatar>
                                    <img :src="'/img/country/' + item.code + '.png'" :alt="item.code.toLowerCase()" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.name"/>
                                </v-list-item-content>
                            </template>
                        </v-select>
                        <v-text-field
                            :label="labels.name"
                            type="text"
                            name="name"
                            v-model="form.name"
                            :error-messages="errors.name"
                            :rules="[rules.required('name'), rules.max('name', 160, 'El')]"
                            :disabled="loading"
                            @input="clearErrors('name')"
                            prepend-inner-icon="mdi-account-circle"
                            filled/>
                        <v-text-field
                            :label="labels.phone"
                            name="phone"
                            type="tel"
                            inputmode="tel"
                            v-model="form.phone"
                            :error-messages="errors.phone"
                            :rules="[rules.required('phone'), rules.max('phone', 255, 'El')]"
                            :disabled="loading"
                            @input="clearErrors('phone')"
                            prepend-inner-icon="mdi-cellphone"
                            hint="Ejemplo: +51960193293"
                            filled/>
                        <v-text-field
                            :label="labels.email"
                            type="email"
                            name="email"
                            inputmode="email"
                            v-model="form.email"
                            :error-messages="errors.email"
                            :rules="[rules.required('email'), rules.mail('email')]"
                            :disabled="loading"
                            @input="clearErrors('email')"
                            prepend-inner-icon="mdi-email"
                            filled/>
                        <v-text-field
                            :label="labels.password"
                            name="password"
                            v-model="form.password"
                            :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (passwordHidden = !passwordHidden)"
                            :type="passwordHidden ? 'password' : 'text'"
                            :error-messages="errors.password"
                            :disabled="loading"
                            :rules="[rules.req('password'), rules.min('password', 8, 'La')]"
                            hint="Mínimo 8 caracteres"
                            @input="clearErrors('password')"
                            prepend-inner-icon="mdi-lock"
                            filled/>
                        <v-text-field
                            :label="labels.password_confirmation"
                            name="password_confirmation"
                            v-model="form.password_confirmation"
                            :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (passwordHidden = !passwordHidden)"
                            :type="passwordHidden ? 'password' : 'text'"
                            :error-messages="errors.password_confirmation"
                            :disabled="loading"
                            :rules="[rules.req('password_conf')]"
                            @input="clearErrors('password')"
                            prepend-inner-icon="mdi-lock"
                            filled/>
                        <v-switch name="terms" v-model="form.terms" :disabled="loading"
                            :rules="[rules.cond('terms')]" :error-messages="errors.terms"
                            @input="clearErrors('terms')" color="success" readonly @click="dialog = true">
                            <template v-slot:label>
                                <div><strong @click="dialog = true">Leer y Aceptar los {{labels.terms}}</strong></div>
                            </template>
                        </v-switch>
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading || !valid"
                            color="primary"
                            class="font-weight-bold"
                            large block
                        >Crear Cuenta</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            <div class="text-center py-2 py-sm-4 hidden-xs-only">
                ¿Ya tienes una cuenta?&nbsp;&nbsp;
                <v-btn color="secondary" to="/login">Ingresar</v-btn>
            </div>
            <v-dialog v-model="dialog" persistent scrollable width="800px">
                <v-card>
                    <v-card-title class="text-center headline primary d-inline-block" primary-title style="color: #fefefe; word-break: normal; width: 100%">{{labels.terms}}</v-card-title>
                    <v-card-text class="px-0">
                        <terms :style="'margin-top: -' + mrg + 'px'" :w="'100'"/>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions class="px-sm-8">
                        <v-btn color="error" large @click="[dialog = false, form.terms = false]">
                            <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;No acepto
                        </v-btn>
                        <v-spacer/>
                        <v-btn color="success" large @click="[dialog = false, form.terms = true]">
                            <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;Acepto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from "axios";
    import Form from "../../helpers/form";
    import { isMobileOnly } from "mobile-device-detect";
    import Terms from "../Terms";
    import Facebook from "./providers/Facebook";
    import Google from "./providers/Google";

    export default {
        name: "Register",
        components: { Google, Facebook, Terms },
        mixins: [ Form ],
        data: () => ({
            dialog: false,
            passwordHidden: true,
            mrg: isMobileOnly ? '74' : '54',
            labels: {
                country: 'País de Envío',
                name: 'Nombre Completo',
                phone: 'Teléfono',
                email: 'Correo Electrónico',
                password: 'Contraseña',
                password_conf: 'confirmación de la contraseña',
                password_confirmation: 'Repetir Contraseña',
                terms: 'Términos y Condiciones'
            },
            form: {
                country: null,
                name: null,
                phone: null,
                email: null,
                password: null,
                password_confirmation: null,
                terms: false
            },
            items: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ]
        }),
        mounted() {
            this.form.country = this.$route.query.country || null;
        },
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    axios.post('/api/register', this.form)
                        .then((res) => {
                            this.$toast.success('¡Te has registrado exitosamente!', { icon: 'mdi-check-circle' });
                            this.success(res.data);
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        })
                }
            },
            success(data) {
                this.$store.dispatch('saveToken', data);
                this.$store.dispatch('setUser', data);
                this.$router.push({ name: 'Home' });
            }
        }
    }
</script>

<style scoped>

</style>
