<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CONFIGURAR COMISIONES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-row justify="center" class="mx-0">
                        <v-col cols="12">
                            <v-btn color="primary" large block depressed @click="get()" style="margin: 1px">
                                <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Agregar Comisión</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <b>{{ item.feeId }}</b>
                        </template>
                        <template v-slot:item.value="{ item }">
                            {{ Number(item.value).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                        </template>
                        <template v-slot:item.currency="{ item }">
                            {{ item.currency ? item.currency : 'Todas' }}
                        </template>
                        <template v-slot:item.lower_limit="{ item }">
                            {{ Number(item.lower_limit).toLocaleString("de-DE") }}
                        </template>
                        <template v-slot:item.upper_limit="{ item }">
                            {{ Number(item.upper_limit).toLocaleString("de-DE") }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed @click="get(item.id)" style="margin: 1px">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed @click="del(item.id, item.feeId)" style="margin: 1px">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">{{ form.feeId ? 'Editar Comisión ID: '+form.feeId : 'Agregar Comisión' }}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.category"
                                            :items="categories"
                                            name="category"
                                            v-model="form.category"
                                            :error-messages="errors.category"
                                            :disabled="loading"
                                            :rules="[rules.required('category')]"
                                            @select="clearErrors('category')"
                                            prepend-icon="mdi-sitemap"
                                            item-text="name">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px" color="primary">
                                                        <span class="warning--text">{{ item.value }}</span>
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar color="primary">
                                                    <span class="warning--text">{{ item.value }}</span>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.fee_type"
                                            :items="fee_types"
                                            name="fee_type"
                                            v-model="form.fee_type"
                                            :error-messages="errors.fee_type"
                                            :disabled="loading"
                                            :rules="[rules.required('fee_type')]"
                                            @select="clearErrors('fee_type')"
                                            prepend-icon="mdi-resistor-nodes"
                                            item-text="name">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px" color="primary">
                                                        <span class="warning--text">{{ item.value }}</span>
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar color="primary">
                                                    <span class="warning--text">{{ item.value }}</span>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5" >
                                        <v-currency-field
                                            :label="labels.value"
                                            v-model="form.value"
                                            name="value"
                                            :error-messages="errors.value"
                                            :rules="[rules.required('value')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-cash-check"
                                            @input="clearErrors('value')"
                                            v-bind="currency_config"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.currency"
                                            :items="currencies"
                                            name="currency"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.currency"
                                            :error-messages="errors.currency"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('currency')"
                                            prepend-icon="mdi-currency-sign"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country_send"
                                            :items="countries"
                                            name="country_send"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.country_send"
                                            :error-messages="errors.country_send"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('country_send')"
                                            prepend-icon="mdi-resistor-nodes"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country_receiver"
                                            :items="countries"
                                            name="country_receiver"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.country_receiver"
                                            :error-messages="errors.country_receiver"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('country_receiver')"
                                            prepend-icon="mdi-resistor-nodes"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-text-field
                                            :label="labels.lower_limit"
                                            v-model="form.lower_limit"
                                            type="number"
                                            name="lower_limit"
                                            hint="Opcional"
                                            persistent-hint
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            :error-messages="errors.lower_limit"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-trending-down"
                                            @input="clearErrors('lower_limit')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5" >
                                        <v-text-field
                                            :label="labels.upper_limit"
                                            v-model="form.upper_limit"
                                            type="number"
                                            name="upper_limit"
                                            hint="Opcional"
                                            persistent-hint
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            :error-messages="errors.upper_limit"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-trending-up"
                                            @input="clearErrors('upper_limit')"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success" :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ form.id ? 'Actualizar' : 'Guardar' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "configFee",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Valor', value: 'value', align: 'right' },
                { text: 'Categoria', value: 'type' },
                { text: 'Moneda', value: 'currency' },
                { text: 'Origen', value: 'country_send_text' },
                { text: 'Destino', value: 'country_receiver_text' },
                { text: 'Limite Menor', value: 'lower_limit', align: 'right' },
                { text: 'Limite Mayor', value: 'upper_limit', align: 'right' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: false,
            noDataText: 'No tienes comisiones registradas',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, -1]
            },
            dialog: false,
            labels: {
                id: 'ID',
                value: 'Valor',
                category: 'Categoría',
                fee_type: 'Tipo de Comisión',
                currency: 'Moneda',
                country_send: 'País Enviador',
                country_receiver: 'País Receptor',
                lower_limit: 'Limite Menor',
                upper_limit: 'Limite Mayor'
            },
            form: {
                id: null,
                feeId: null,
                value: null,
                category: null,
                fee_type: null,
                currency: null,
                country_send: null,
                country_receiver: null,
                lower_limit: null,
                upper_limit: null
            },
            categories: [
                { name: 'Origen', value: 'In' },
                { name: 'Destino', value: 'Out' }
            ],
            fee_types: [
                { name: 'Porcentaje', value: '%' },
                { name: 'Valor Fijo', value: '$' }
            ],
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            currencies: [
                { name: 'Bolivares', code: 'VES' },
                { name: 'Nuevos Soles', code: 'PEN' },
                { name: 'Pesos Chilenos', code: 'CLP' },
                { name: 'Pesos Colombianos', code: 'COP' },
                { name: 'Pesos Mexicanos', code: 'MXN' },
                { name: 'Dolares', code: 'USD' },
                { name: 'Dolares (Tether)', code: 'USDT' }
            ],
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0
            }
        }),
        created() {
            this.getData();
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/settings/fee').then((res) => {
                    this.desserts = res.data.fee;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getData, 60000);
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form = items[i];
                            this.form.value = parseFloat(items[i].value);
                        }
                    }
                }
                this.dialog = true;
            },
            close(v) {
                this.form = {
                    id: null,
                    feeId: null,
                    value: null,
                    category: null,
                    fee_type: null,
                    currency: null,
                    country_send: null,
                    country_receiver: null,
                    upper_limit: null,
                    lower_limit: null
                };
                if (v === 1) {
                    this.dialog = false;
                    this.$refs.form.reset();
                }
            },
            del(v, fv) {
                this.$swal({
                    title: '¿Esta seguro de eliminar esta configuración de comisión?',
                    text: 'Comisión ID: ' + fv,
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/settings/fee/d/' + v).then((res) => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                                this.getData();
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'La comisión con ID: ' + fv + ' se ha eliminada exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                    }
                });
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let url = '/api/settings/fee';

                    if (this.form.id) {
                        url += '/u/' + this.form.id + '?';
                    } else {
                        url += '/c?';
                    }

                    url += 'value=' + this.form.value;
                    url += '&category=' + this.form.category;
                    url += '&fee_type=' + this.form.fee_type;

                    if (this.form.currency) {
                        url += '&currency=' + this.form.currency;
                    }

                    if (this.form.country_send) {
                        url += '&country_send=' + this.form.country_send;
                    }

                    if (this.form.country_receiver) {
                        url += '&country_receiver=' + this.form.country_receiver;
                    }

                    if (this.form.lower_limit) {
                        url += '&lower_limit=' + this.form.lower_limit;
                    }

                    if (this.form.upper_limit) {
                        url += '&upper_limit=' + this.form.upper_limit;
                    }

                    axios.get(url).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData();
                            this.close(1);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
