import Vue from 'vue';
import axios from 'axios';
import Vuex from 'vuex';

Vue.use(Vuex);

export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const SET_TOKEN = 'SET_TOKEN';

export default new Vuex.Store({
    state: {
        user: null,
        token: window.localStorage.getItem('token')
    },
    getters: {
        user: state => state.user,
        check: state => state.user !== null,
        token: state => state.token
    },
    mutations: {
        SET_USER(state, { user }) {
            state.user = user;
        },
        LOGOUT(state) {
            state.user = null;
            state.token = null;
            window.localStorage.removeItem('token');
        },
        FETCH_USER_FAILURE(state) {
            state.user = null;
            window.localStorage.removeItem('token');
        },
        SET_TOKEN(state, { token }) {
            state.token = token;
            window.localStorage.setItem('token', token);
        }
    },
    actions: {
        saveToken({ commit }, payload) {
            commit(SET_TOKEN, payload);
        },
        async fetchUser({ commit }) {
            try {
                const { data } = await axios.get('/api/profile');
                commit(SET_USER, data);
            } catch (e) {
                commit(FETCH_USER_FAILURE);
            }
        },
        setUser({ commit }, payload) {
            commit(SET_USER, payload);
        },
        async logout({ commit }) {
            await axios.post('/api/logout');
            commit(LOGOUT);
        },
        destroy({ commit }) {
            commit(LOGOUT);
        },
        async fetchOauthUrl(ctx, { provider }) {
            const { data } = await axios.post('/api/auth/' + provider);
            return data.url;
        }
    }
});
