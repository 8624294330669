<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block py-sm-2 text-center font-weight-bold primary warning--text" style="word-break: normal">MIS CORRECCIONES PENDIENTES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getCorrections">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :expanded.sync="expanded"
                                  :loading="loading" :no-data-text="noDataText" :expand-icon="expandIcon"
                                  :footer-props="footerProps" single-expand show-expand class="mb-12 mb-lg-0">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="pt-2 pb-4">
                                <v-row justify="center">
                                    <v-col cols="10" class="py-2">
                                        <div class="text-center">
                                            <h3 class="pb-2">Descripción del Error:</h3>
                                            <span>{{ item.message }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="10" class="text-center py-2">
                                        <v-btn color="primary" dark @click="correctionP(item.payment_id)" v-if="item.type === 'Payment'">
                                            <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Corregir Imagen
                                        </v-btn>
                                        <v-btn color="primary" dark @click="correctionT(item.transfer_id)" v-else>
                                            <v-icon>mdi-square-edit-outline</v-icon>&nbsp;&nbsp;Corregir Datos
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="cp" scrollable persistent max-width="800px">
                    <payment v-on:close-payment="closeP" :id="id" @success="getCorrections"/>
                </v-dialog>
                <v-dialog v-model="ct" scrollable persistent max-width="1000px">
                    <transfer v-on:close-transfer="closeT" :id="id" @success="getCorrections"/>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Payment from "./correction/Payment.vue";
    import Transfer from "./correction/Transfer";

    export default {
        name: "Corrections",
        components: { Payment, Transfer },
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Código', value: 'code' },
                { text: 'Error', value: 'error' },
                { text: 'Fecha', value: 'updated_at' },
                { text: '', filterable: false, value: 'data-table-expand' }
            ],
            desserts: [],
            expanded: [],
            loading: true,
            noDataText: 'No tienes correcciones pendientes',
            expandIcon: 'mdi-chevron-down-circle',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            cp: false,
            ct: false,
            id: null
        }),
        created () {
            this.cp = this.$route.query.cp || null;
            this.ct = this.$route.query.ct || null;
            this.id = this.$route.query.id || null;
            this.getCorrections();
        },
        methods: {
            getCorrections() {
                this.loading = true;
                axios.get('/api/corrections').then((res) => {
                    this.desserts = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            correctionP(id) {
                this.id = id;
                this.cp = true;
            },
            correctionT(id) {
                this.id = id;
                this.ct = true;
            },
            closeP() {
                this.cp = false;
                this.id = null;
            },
            closeT() {
                this.ct = false;
                this.id = null;
            }
        }
    }
</script>

<style scoped>

</style>
