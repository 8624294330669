<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">BALANCE ACTUAL</v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card outlined style="border: none;">
                    <v-card-title class="d-block text-center font-weight-bold bg primary--text py-2" style="word-break: normal">
                        <u>DINERO EN CAJA</u>
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-row justify="center">
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <template v-for="i in items" v-if="show === false">
                                <v-col cols="10" md="5" align-self="center">
                                    <v-card shaped outlined class="primary py-4">
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.value }}</h1>
                                                </v-col>
                                                <v-col cols="1" class="text-center pa-0">
                                                    <v-divider vertical class="white"/>
                                                </v-col>
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.currency }}</h1>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined style="border: none;">
                    <v-card-title class="d-block text-center font-weight-bold bg primary--text py-2" style="word-break: normal">
                        <u>ENTRADAS - HOY</u>
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-row justify="center">
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <template v-for="i in input" v-if="show === false">
                                <v-col cols="10" md="5" align-self="center">
                                    <v-card shaped outlined class="success py-4">
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.value }}</h1>
                                                </v-col>
                                                <v-col cols="1" class="text-center pa-0">
                                                    <v-divider vertical class="white"/>
                                                </v-col>
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.currency }}</h1>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined style="border: none;">
                    <v-card-title class="d-block text-center font-weight-bold bg primary--text py-2" style="word-break: normal">
                        <u>SALIDAS - HOY</u>
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-row justify="center">
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <v-col cols="10" md="5" align-self="center" v-if="show === true">
                                <v-skeleton-loader class="mx-auto" width="100%" type="list-item-avatar-two-line"/>
                            </v-col>
                            <template v-for="i in output" v-if="show === false">
                                <v-col cols="10" md="5" align-self="center">
                                    <v-card shaped outlined class="error py-4">
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.value }}</h1>
                                                </v-col>
                                                <v-col cols="1" class="text-center pa-0">
                                                    <v-divider vertical class="white"/>
                                                </v-col>
                                                <v-col cols="5" class="text-center align-self-center pa-0">
                                                    <h1 class="white--text">{{ i.currency }}</h1>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Balance",
        props: [ 'user', 'office' ],
        data: () => ({
            show: true,
            items: {},
            input: {},
            output: {}
        }),
        created () {
            this.getData();
        },
        watch: {
            office() {
                this.getData();
            }
        },
        methods: {
            getData() {
                this.loading = true;
                this.show = true;
                axios.get('/api/cashier/balance').then((res) => {
                    if (res.data.v === 'success') {
                        this.items = res.data.items;
                        this.input = res.data.input;
                        this.output = res.data.output;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                    this.show = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>

<script setup>
</script>
<script setup>
</script>
