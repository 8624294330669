<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CUENTAS BANCARIAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.balance="{ item }">
                            <b>{{ item.balance }}</b>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed :to="'/movements/accounts/' + item.id" style="margin: 1px">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                            <v-btn color="success" small depressed @click="downloadReport(item.id)" style="margin: 1px">
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "Accounts",
        mixins: [Form],
        props: ['user'],
        data: () => ({
            search: '',
            headers: [
                { text: 'Pais', value: 'cname' },
                { text: 'Banco', value: 'name' },
                { text: 'Alias', value: 'alias' },
                { text: 'Código', value: 'code' },
                { text: 'Saldo', value: 'balance' },
                { text: 'Moneda', value: 'currency' },
                { text: 'Acción', value: 'action', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes cuentas registradas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            }
        }),
        created() {
            this.getData();
        },
        methods: {
            getData(v) {
                this.loading = true;

                if (v === 1) { this.desserts = []; }
                axios.get('/api/movements/accounts').then((res) => {
                    if (res.data.accounts) {
                        this.desserts = res.data.accounts;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            downloadReport(id) {
                axios.get('/api/export/reports/accounts/' + id, {
                    responseType: 'blob'
                }).then((res) => {
                    const blob = new Blob([res.data], {type: res.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = res.headers['content-disposition'].split('filename=')[1];
                    const name = 'Reporte de Cuenta ' + filename.replace(/"/gi, '');
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            }
        }
    }
</script>

<style scoped>

</style>
