<template>
    <v-main>
        <v-container fluid fill-height class="pa-0 mb-14 mb-sm-0">
            <transition name="slide" mode="out-in">
                <router-view/>
            </transition>
            <v-navigation-drawer width="50%" touchless value="true" floating hide-overlay class="hidden-sm-and-down" src="/img/bg-login.jpg">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                </template>
            </v-navigation-drawer>
        </v-container>
        <v-btn bottom left fab fixed elevation="4" color="primary" class="hidden-xs-only" to="/calculator/public">
            <v-icon large>mdi-calculator-variant</v-icon>
        </v-btn>
        <v-bottom-navigation grow fixed dark background-color="primary" color="amber lighten-1" class="hidden-sm-and-up">
            <v-btn class="px-1" to="/login">
                <span>Iniciar Sesión</span>
                <v-icon>mdi-account-key</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/register">
                <span>Registro</span>
                <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/calculator/public">
                <span>Cotizador</span>
                <v-icon>mdi-calculator-variant</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-main>
</template>

<script>
    export default {}
</script>
