<template>
    <v-layout align-sm-center justify-center :style="'width: '+width+'%'">
        <v-flex>
            <v-card flat tile color="transparent">
                <h1 class="white--text blue darken-3 py-4 text-center">Términos & Condiciones</h1>
                <v-card-text class="text-justify pa-8" v-html="terms"/>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Terms",
        props: ['w'],
        data: () => ({
            loading: true,
            width: '80',
            terms: ''
        }),
        created () {
            this.getData();
        },
        mounted() {
            this.width = this.$props.w ? this.$props.w : '80';
        },
        methods: {
            getData() {
                axios.get('/api/legal').then((res) => {
                    this.terms = res.data.terms;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
