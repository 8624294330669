<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">VERIFICACIONES PENDIENTES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getPending(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12" v-if="myPayments.length > 0">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">
                        <u>Mis Verificaciones</u>
                    </v-card-title>
                    <v-card-title>
                        <v-text-field v-model="mySearch" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="myPayments" :search="mySearch" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="myFooterProps" class="mb-12 mb-lg-0">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                            <v-icon small class="success--text" title="Verificado" v-if="item.approved === 103">mdi-check-circle</v-icon>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="success" small depressed @click="getOk(item.id)" style="margin: 1px">
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-btn>
                                <v-btn color="info" small depressed @click="getVoucher(item.id)" style="margin: 1px" v-if="item.voucher">
                                    <v-icon>mdi-panorama</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed @click="getInfo(item.id)" style="margin: 1px">
                                    <v-icon>mdi-sticker-alert</v-icon>
                                </v-btn>
                                <v-btn color="secondary" small depressed @click="getCorrection(item.id, item.user_id)" style="margin: 1px">
                                    <v-icon>mdi-alert-circle</v-icon>
                                </v-btn>
                                <v-btn color="error" small depressed dark @click="getLiberty(item.id)" style="margin: 1px">
                                    <v-icon>mdi-arrow-down-bold-circle</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="info" persistent scrollable max-width="800px">
                    <info v-on:close-info="closeInfo" :info="info_id"/>
                </v-dialog>
                <v-dialog v-model="view" persistent scrollable max-width="1000px" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline font-weight-bold primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Comprobante #{{voucher_id}}</v-card-title>
                        <v-card-text class="py-4">
                            <v-btn color="white" light depressed absolute top left fab small class="mt-7" @click="rotate(voucher_id, 'left')">
                                <v-icon>mdi-rotate-left</v-icon>
                            </v-btn>
                            <v-btn color="white" light depressed absolute top right fab small class="mt-7" @click="rotate(voucher_id, 'right')">
                                <v-icon>mdi-rotate-right</v-icon>
                            </v-btn>
                            <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="voucher === null"/>
                            <img :src="'/' + voucher" :alt="voucher_id" width="100%" v-else/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="closeVoucher"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="ok" persistent scrollable max-width="1000px">
                    <v-form ref="form" @submit.prevent="submitOk" lazy-validation v-model="valid">
                        <v-card v-if="ok === true">
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline font-weight-bold primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">Formulario de Aprobación {{ ok_id }}
                            </v-card-title>
                            <v-card-text class="py-4">
                                <v-row justify="space-around">
                                    <v-checkbox
                                        :label="labels.add"
                                        v-model="form.add"
                                        class="mx-2 pb-0"
                                        color="success"
                                        :disabled="loading"/>
                                </v-row>
                                <v-row justify="center" v-if="form.add === true">
                                    <v-col cols="12" sm="6" lg="4">
                                        <v-currency-field
                                            :label="labels.fee"
                                            v-model="form.fee"
                                            name="fee"
                                            :error-messages="errors.fee"
                                            :rules="[rules.req('fee')]"
                                            :disabled="loading"
                                            @change="clearErrors('fee')"
                                            prepend-icon="mdi-cash-minus"
                                            v-bind="currency_config"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" lg="4">
                                        <v-autocomplete
                                            :label="labels.account"
                                            :items="accounts"
                                            name="account"
                                            v-model="form.account"
                                            :error-messages="errors.account"
                                            :disabled="loading"
                                            :rules="[rules.req('account')]"
                                            @select="clearErrors('account')"
                                            prepend-icon="mdi-text"
                                            auto-select-first
                                            item-text="name"
                                            item-value="id">
                                            <template v-slot:selection="{ item }">
                                                <v-flex grow class='ml-1'><b>{{ item.code }}</b> - {{ item.alias }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="'<b>' + item.code + '</b> - ' + item.alias"/>
                                                    <v-list-item-subtitle v-html="'<b>' + item.bank_name + '</b>'"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="4">
                                        <v-dialog
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :label="labels.date"
                                                    v-model="date"
                                                    name="date"
                                                    prepend-icon="mdi-calendar"
                                                    :error-messages="errors.date"
                                                    :rules="[rules.req('date')]"
                                                    :disabled="loading"
                                                    @input="clearErrors('date')"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="form.date"
                                                scrollable
                                                :max="(new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                                :min="(new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                                @change="menu = false"
                                            ></v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" sm="6" lg="4">
                                        <v-text-field
                                            :label="labels.comp"
                                            v-model="form.comp"
                                            name="comp"
                                            :error-messages="errors.comp"
                                            :rules="[rules.required('comp')]"
                                            :disabled="loading"
                                            @change="clearErrors('comp')"
                                            prepend-icon="mdi-pound-box"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="closeOk"><v-icon>mdi-close-circle</v-icon>Cerrar</v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">Aprobar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
                <v-dialog v-model="correction" persistent scrollable max-width="800px">
                    <v-form ref="form" @submit.prevent="submitCorrection" lazy-validation v-model="valid">
                        <v-card v-if="correction === true">
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline font-weight-bold primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Formulario de Rechazo ID: {{ correction_id }}</v-card-title>
                            <v-card-text class="py-4">
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <h2 class="text-center">Seleccione el Tipo de Rechazo:</h2>
                                    </v-col>
                                    <v-radio-group
                                        v-model="form.code"
                                        class="px-4"
                                        name="code"
                                        :disabled="loading"
                                        :error-messages="errors.code"
                                        :rules="[rules.required('code')]"
                                        @select="clearErrors('code')"
                                        row>
                                        <v-radio label="Error de Imagen" color="info" value="1" class="py-2"/>
                                        <v-radio label="Imagen Duplicada" color="secondary" value="2" class="py-2"/>
                                        <v-radio label="Error de Pago" color="error" value="3" class="py-2"/>
                                    </v-radio-group>
                                    <v-col cols="12" md="10">
                                        <v-textarea
                                            outlined name="message" v-model="form.message" :error-messages="errors.message"
                                            :rules="form.code === '1' ? [rules.required('message')] : []"
                                            :label="'Mensaje ' + (form.code !== '1' ? '(Opcional)' : '(Obligatorio)')"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="closeCorrection"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">Enviar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">
                        <u>Verificación General</u>
                    </v-card-title>
                    <v-card-subtitle class="mt-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-subtitle>
                    <v-data-table :headers="headers" :items="payments" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                            <v-icon small class="success--text" title="Verificado" v-if="item.approved === 103">mdi-check-circle</v-icon>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="success" small depressed dark @click="getSelect(item.id)" style="margin: 1px">
                                    <v-icon>mdi-arrow-up-bold-circle</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed dark :to="'/history/payment/' + item.id" style="margin: 1px">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import info from '../../transfers/Info';
    import Form from '../../../helpers/form';

    export default {
        name: "VPending",
        mixins: [ Form ],
        components: { info },
        props: [ 'user' ],
        data: () => ({
            search: '',
            mySearch: '',
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Cliente', value: 'name', align: 'left', sortable: false },
                { text: 'Pais', value: 'country_name', align: 'left', sortable: false },
                { text: 'Valor', value: 'amount_text', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency', align: 'left', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', filterable: false, sortable: false }
            ],
            myPayments: [],
            payments: [],
            accounts: [],
            loading: true,
            noDataText: 'No hay pagos pendientes',
            myFooterProps: {
                itemsPerPageOptions: [10, 25, 50, 100, -1]
            },
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            info_id: null,
            info: false,
            voucher_id: null,
            voucher: null,
            view: false,
            ok_id: null,
            ok: false,
            correction_id: null,
            correction_uid: null,
            correction: false,
            labels: {
                add: 'Agregar Costo de Comisiones Bancarias',
                fee: 'Comision',
                account: 'Cuenta Bancaria',
                date: 'Fecha de Deposito',
                comp: 'Nº de Comprobante',
                code: 'Tipo de Error'
            },
            form: {
                add: false,
                fee: null,
                account: null,
                date: null,
                comp: null,
                code: null,
                error: null,
                message: null
            },
            menu: false,
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0,
                valueAsInteger: false,
                allowNegative: true
            }
        }),
        created() {
            this.getPending();
        },
        watch: {
            'form.comp'() {
                if (this.form.comp) {
                    this.form.comp = (this.form.comp).toUpperCase();
                }
            },
            'form.code'() {
                this.form.error = 'Error de Imagen';
                if (this.form.code === '2') {
                    this.form.error = 'Imagen Duplicada';
                } else if (this.form.code === '3') {
                    this.form.error = 'Error de Pago';
                }
            }
        },
        computed: {
            date() {
                return this.formatDate(this.form.date);
            },
        },
        methods: {
            getPending(v) {
                this.loading = true;
                if (v === 1) {
                    this.myPayments = [];
                    this.payments = [];
                }
                axios.get('/api/checker/pending').then((res) => {
                    if (res.data.v === 'success') {
                        this.myPayments = res.data.myPayments;
                        this.payments = res.data.payments;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getPending, 120000);
            },
            getAccounts(id) {
                axios.get('/api/transfers/c/accounts/' + id).then((res) => {
                    this.accounts = res.data.accounts;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getSelect(id) {
                this.loading = true;
                axios.get('/api/checker/select/' + id).then((res) => {
                    if (res.data.v === 'success') {
                        this.getPending();
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else if (res.data.v === 'warning') {
                        this.getPending();
                        this.$toast.warning(res.data.status, {icon: 'info-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getInfo(id) {
                this.info_id = id;
                this.info = true;
            },
            closeInfo() {
                this.info = false;
                this.info_id = null;
            },
            getVoucher(id) {
                axios.get('/api/history/v1/' + id).then((res) => {
                    let code = Math.round(Math.random()*999999);
                    this.voucher = res.data.voucher + '?v=' + code;
                    this.voucher_id = id;
                    this.view = true;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            closeVoucher() {
                this.view = false;
                this.voucher = null;
                this.voucher_id = null;
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            getLiberty(id) {
                this.loading = true;

                axios.get('/api/checker/liberty/' + id).then((res) => {
                    if (res.data.v === 'success') {
                        this.getPending();
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else if (res.data.v === 'warning') {
                        this.$toast.warning(res.data.status, {icon: 'alert-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getOk(id) {
                this.loading = true;
                this.ok_id = id;
                this.ok = true;
                const d = new Date();
                let day = 0;
                let month = 0;
                if ((d.getMonth() + 1) < 10) {
                    month = '0' + (d.getMonth() + 1);
                } else {
                    month = (d.getMonth() + 1);
                }
                if (d.getDate() < 10) {
                    day = '0' + d.getDate()
                } else {
                    day = d.getDate();
                }
                this.form.date = d.getFullYear() + '-' + month + '-' + day;
                this.getAccounts(id);
            },
            closeOk() {
                this.ok = false;
                this.ok_id = null;
                this.form.add = false;
                this.form.fee = null;
                this.form.account = null;
                this.form.comp = null;
                this.$refs.form.reset();
            },
            submitOk() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('id',  this.ok_id);
                    formData.append('type',  'Transfer');
                    formData.append('add',  this.form.add === true ? '1' : '0');
                    formData.append('fee',  this.form.fee);
                    formData.append('account',  this.form.account);
                    formData.append('date',  this.form.date);
                    formData.append('comp',  this.form.comp);

                    axios.post('/api/checker/approve', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getPending();
                            this.closeOk();
                        } else {
                            this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            getCorrection(id, uid) {
                this.correction_id = id;
                this.correction_uid = uid;
                this.correction = true;
            },
            closeCorrection() {
                this.correction = false;
                this.correction_id = null;
                this.correction_uid = null;
                this.form.error = null;
                this.form.message = null;
                this.$refs.form.reset();
            },
            submitCorrection() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('id',  this.correction_id);
                    formData.append('user_id',  this.correction_uid);
                    formData.append('type',  'Payment');
                    formData.append('code',  this.form.code);
                    formData.append('error',  this.form.error);
                    if (this.form.message) {
                        formData.append('message', this.form.message);
                    }

                    axios.post('/api/transfers/correction', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.closeCorrection();
                            this.getPending();
                        } else {
                            this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            rotate(id, dir) {
                axios.get('/api/voucher/rotate/'+dir+'/'+id).then(() => {
                    this.voucher = null;
                    this.getVoucher(id);
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
