<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">EDITAR INFORMACION LEGAL
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card>
                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col cols="12" sm="11">
                                    <h1 class="text-center">
                                        <b>{{ labels.terms }}</b>
                                    </h1>
                                    <v-divider/>
                                </v-col>
                                <v-col cols="12" sm="11">
                                    <ckeditor v-model="form.terms" :editorUrl="editorUrl" :config="editorConfig"/>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="5">
                                    <v-btn type="submit" class="mt-6" block large :loading="loading" color="success" :disabled="loading || !valid">
                                        <v-icon>mdi-reload</v-icon><span>&nbsp;&nbsp;Actualizar</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import Form from "../../../helpers/form";

export default {
    name: "Legal.vue",
    mixins: [ Form ],
    props: [ 'user' ],
    data: () => ({
        loading: false,
        labels: {
            terms: 'Terminos & Condiciones'
        },
        form: {
            terms: null
        },
        options: [],
        editorUrl: 'https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js',
        editorConfig: {
            toolbarGroups: [
                { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                { name: 'forms', groups: [ 'forms' ] },
                '/',
                { name: 'colors', groups: [ 'colors' ] },
                { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                '/',
                { name: 'links', groups: [ 'links' ] },
                { name: 'insert', groups: [ 'insert' ] },
                { name: 'styles', groups: [ 'styles' ] },
                { name: 'tools', groups: [ 'tools' ] },
                { name: 'others', groups: [ 'others' ] },
                { name: 'about', groups: [ 'about' ] }
            ],
            removeButtons: 'Save,NewPage,Preview,Print,Templates,Flash,Maximize,ShowBlocks,About',
            removeDialogTabs: 'image:advanced;link:advanced',
            autoParagraph: false,
            format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;address;div',
            extraAllowedContent: 'span;ul;li;table;th;td;center;br;style;*[id];*(*);*{*}',
            resize_enabled: false,
            enterMode: 'CKEDITOR.ENTER_P',
            shiftEnterMode: 'CKEDITOR.ENTER_BR',
            toolbarCanCollapse: true,
            language: 'es',
            height: '360'
        }
    }),
    created () {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;

            axios.get('/api/settings/legal').then((res) => {
                this.form.terms = res.data.terms;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            }).then(() => {
                this.loading = false;
            });
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                axios.post('/api/settings/legal', this.form).then(res => {
                    if (res.data.v === 'success') {
                        this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                    } else {
                        this.$toast.warning(res.data.status, {
                            timeout: 8000,
                            icon: 'mdi-information',
                            dismissable: false,
                            showClose: true
                        });
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
