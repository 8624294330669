<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold blue primary warning--text py-2"
                                  style="word-break: normal">LISTADO DE CLIENTES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <template v-for="item in counts">
                <v-col cols="6" sm="4" md="3">
                    <v-card outlined>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <div class="overline mb-4">{{ item.title }}</div>
                                <v-list-item-title class="headline mb-1">
                                    <b>{{ item.count }}</b>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-avatar size="60">
                                <v-icon x-large dark :class="item.color" v-text="item.icon"></v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>
            </template>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <template>
                    <v-tabs grow background-color="primary" dark>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab><v-icon left>mdi-account-circle</v-icon> GENERAL</v-tab>
                        <v-tab><v-icon left>mdi-sync-circle</v-icon> EN REVISIÓN</v-tab>
                        <v-tab><v-icon left>mdi-check-circle</v-icon> VERIFICADOS</v-tab>
                        <v-tab><v-icon left>mdi-close-circle</v-icon> BLOQUEADOS</v-tab>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                                </v-card-title>
                                <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                              :no-data-text="noDataText" :footer-props="footerProps">
                                    <template v-slot:item.id="{ item }">
                                        <b>{{ (item.id).toString().padStart(5, 0) }}</b>
                                    </template>
                                    <template v-slot:item.name="{ item }">
                                        <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-icon class="tertiary--text" title="Sin Verificar" v-if="item.status === 101">mdi-alert-circle</v-icon>
                                        <v-icon class="primary--text" title="En Revisión" v-else-if="item.status === 102">mdi-sync-circle</v-icon>
                                        <v-icon class="success--text" title="Verificado" v-else-if="item.status === 103">mdi-check-circle</v-icon>
                                        <v-icon class="error--text" title="Bloqueado" v-else>mdi-close-circle</v-icon>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px" title="Ver">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    <v-text-field v-model="search1" append-icon="mdi-magnify" label="Buscar"
                                                  single-line hide-details/>
                                </v-card-title>
                                <v-data-table :headers="headers" :items="reviews" :search="search1" :loading="loading"
                                              :no-data-text="noDataText" :footer-props="footerProps">
                                    <template v-slot:item.id="{ item }">
                                        <b>{{ (item.id).toString().padStart(5, 0) }}</b>
                                    </template>
                                    <template v-slot:item.name="{ item }">
                                        <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-icon class="primary--text" title="En Revisión" v-if="item.status === 102">mdi-sync-circle</v-icon>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px" title="Ver">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    <v-text-field v-model="search2" append-icon="mdi-magnify" label="Buscar"
                                                  single-line hide-details/>
                                </v-card-title>
                                <v-data-table :headers="headers" :items="approveds" :search="search2" :loading="loading"
                                              :no-data-text="noDataText" :footer-props="footerProps">
                                    <template v-slot:item.id="{ item }">
                                        <b>{{ (item.id).toString().padStart(5, 0) }}</b>
                                    </template>
                                    <template v-slot:item.name="{ item }">
                                        <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-icon class="success--text" title="Verificado" v-if="item.status === 103">mdi-check-circle</v-icon>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px" title="Ver">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    <v-text-field v-model="search3" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                                </v-card-title>
                                <v-data-table :headers="headers" :items="blockeds" :search="search3" :loading="loading"
                                              :no-data-text="noDataText" :footer-props="footerProps">
                                    <template v-slot:item.id="{ item }">
                                        <b>{{ (item.id).toString().padStart(5, 0) }}</b>
                                    </template>
                                    <template v-slot:item.name="{ item }">
                                        <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        <v-icon class="error--text" title="Bloqueado" v-if="item.status === 104">mdi-close-circle</v-icon>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px" title="Ver">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </template>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">Editar Cliente #{{ form.id }}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <h2 class="text-center"><b><v-icon>mdi-account-circle</v-icon>&nbsp;&nbsp;Datos Personales</b></h2>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.name"
                                            v-model="form.name"
                                            type="text"
                                            name="name"
                                            :error-messages="errors.name"
                                            :rules="[rules.required('name'), rules.min('name', 2, 'El'), rules.max('name', 120, 'El')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-account-circle"
                                            @input="clearErrors('name')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.email"
                                            v-model="form.email"
                                            type="email"
                                            name="email"
                                            :error-messages="errors.email"
                                            :rules="[rules.required('email'), rules.mail('email')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-gmail"
                                            @input="clearErrors('email')"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.phone"
                                            v-model="form.phone"
                                            type="tel"
                                            name="phone"
                                            placeholder="+573101234567"
                                            :error-messages="errors.phone"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-phone"
                                            @input="clearErrors('phone')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.birthday"
                                            v-model="form.birthday"
                                            type="date"
                                            name="birthday"
                                            :error-messages="errors.birthday"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-calendar"
                                            @input="clearErrors('birthday')"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <h2 class="text-center"><b><v-icon>mdi-card-account-details</v-icon>&nbsp;&nbsp;Identificación Personal</b></h2>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-autocomplete
                                            :label="labels.citizenship"
                                            :items="countries"
                                            name="citizenship"
                                            v-model="form.citizenship"
                                            :error-messages="errors.citizenship"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('citizenship')"
                                            prepend-icon="mdi-map-marker"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'https://flagcdn.com/h240/'+item.img+'.png'" :alt="item.name.toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'https://flagcdn.com/h240/'+item.img+'.png'" :alt="item.name.toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.expiration"
                                            v-model="form.expiration"
                                            type="date"
                                            name="expiration"
                                            :error-messages="errors.expiration"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-calendar"
                                            @input="clearErrors('expiration')"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.td"
                                            :items="dni"
                                            name="td"
                                            v-model="form.td"
                                            :error-messages="errors.td"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('td')"
                                            prepend-icon="mdi-clipboard-text"
                                            item-text="name"
                                            item-value="value">
                                            <template v-slot:selection="{ item }">
                                                <v-flex class='ml-1'>{{ item.name }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.nd"
                                            v-model="form.nd"
                                            type="text"
                                            name="nd"
                                            :error-messages="errors.nd"
                                            :rules="[]"
                                            :disabled="loading"
                                            prepend-icon="mdi-ballot"
                                            @input="clearErrors('nd')"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <h2 class="text-center"><b><v-icon>mdi-folder-multiple-image</v-icon>&nbsp;&nbsp;Documentos</b></h2>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-row justify="center">
                                            <v-col cols="12">
                                                <v-btn color="primary" @click="getImg(1)" large block
                                                       :disabled="form.photo === null" elevation="0">
                                                    <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver Foto Principal
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" v-if="form.photo !== null">
                                                <v-btn color="red darken-2 white--text" @click="delImg(form.id, 1)" large block
                                                       :disabled="form.photo === null" elevation="0">
                                                    <v-icon>mdi-delete</v-icon>&nbsp;&nbsp;Eliminar Foto Principal
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-row justify="center">
                                            <v-col cols="12">
                                                <v-btn color="primary" @click="getImg(2)" large block
                                                       :disabled="form.front === null" elevation="0">
                                                    <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver DNI Frontal
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" v-if="form.front !== null">
                                                <v-btn color="red darken-2 white--text" @click="delImg(form.id, 2)" large block
                                                       :disabled="form.front === null" elevation="0">
                                                    <v-icon>mdi-delete</v-icon>&nbsp;&nbsp;Eliminar DNI Frontal
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-row justify="center">
                                            <v-col cols="12">
                                                <v-btn color="primary" @click="getImg(3)" large block
                                                       :disabled="form.post === null" elevation="0">
                                                    <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver DNI Posterior
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" v-if="form.post !== null">
                                                <v-btn color="red darken-2 white--text" @click="delImg(form.id, 3)" large block
                                                       :disabled="form.post === null" elevation="0">
                                                    <v-icon>mdi-delete</v-icon>&nbsp;&nbsp;Eliminar DNI Posterior
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <h2 class="text-center"><b><v-icon>mdi-lock</v-icon>&nbsp;&nbsp;Datos Internos</b></h2>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.country"
                                            :items="items"
                                            name="country"
                                            v-model="form.country"
                                            :error-messages="errors.country"
                                            :disabled="loading"
                                            :rules="[rules.required('country')]"
                                            @select="clearErrors('country')"
                                            prepend-icon="mdi-map-marker"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.code.toLowerCase()"/>
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="item.code.toLowerCase()"/>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.status"
                                            :items="status"
                                            name="approved"
                                            v-model="form.status"
                                            :error-messages="errors.status"
                                            :disabled="loading"
                                            :rules="[rules.required('status')]"
                                            @select="clearErrors('status')"
                                            prepend-icon="mdi-shield-check"
                                            item-text="name"
                                            item-value="value">
                                            <template v-slot:selection="{ item }">
                                                <v-flex class='ml-1'><v-icon>{{ item.icon }}</v-icon> - {{ item.name }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title><v-icon>{{ item.icon }}</v-icon> - {{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" v-show="user.role === 'Administrator'">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.role"
                                            :items="categories"
                                            name="role"
                                            v-model="form.role"
                                            :error-messages="errors.role"
                                            :disabled="loading"
                                            :rules="[rules.req('role')]"
                                            @select="clearErrors('role')"
                                            prepend-icon="mdi-shape"
                                            item-text="name"
                                            item-value="value"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" md="8">
                                        <v-textarea
                                            :label="labels.msg"
                                            name="msg"
                                            filled
                                            v-model="form.msg"
                                            no-resize
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            rows="3"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success"
                                       :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ form.id ? 'Actualizar' : 'Guardar' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
                <v-dialog v-model="view" scrollable max-width="1000">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">{{ title }}</v-card-title>
                        <v-card-text class="pa-4">
                            <img :src="'/' + img" :alt="title" width="100%"/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="view = false"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "Customers",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            search1: '',
            search2: '',
            search3: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Nombre', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'País', value: 'country' },
                { text: 'Registro', value: 'created_at' },
                { text: 'Estado', value: 'status', align: 'center', filterable: false, sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            approveds: [],
            reviews: [],
            blockeds: [],
            counts: [],
            podium: [],
            loading: true,
            noDataText: 'No tienes clientes registrados',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            dialog: false,
            items: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            countries: [],
            categories: [
                { name: 'Cliente', value: 'Customer' },
                { name: 'Cajero', value: 'Cashier' },
                { name: 'Verificador', value: 'Checker' },
                { name: 'Monitor', value: 'Monitor' },
                { name: 'Pagador', value: 'Payer' },
                { name: 'Soporte', value: 'Support' },
                { name: 'Auditor', value: 'Auditor' },
                { name: 'Administrador', value: 'Administrator' }
            ],
            status: [
                {name: 'Sin Verificar', value: 101, icon: 'mdi-alert-circle'},
                {name: 'En Revision', value: 102, icon: 'mdi-sync-circle'},
                {name: 'Verificado', value: 103, icon: 'mdi-check-circle'},
                {name: 'Bloqueado', value: 104, icon: 'mdi-close-circle'}
            ],
            labels: {
                dni: 'Identificación',
                photo: 'Foto (Selfie)',
                citizenship: 'Nacionalidad',
                td: 'Tipo de Documento',
                nd: 'Número de Documento',
                name: 'Nombre',
                email: 'Correo Electrónico',
                birthday: 'Fecha de Nacimiento',
                expedition : 'Fecha de Expedición',
                phone: 'Telefono',
                country: 'Ubicación',
                role: 'Categoría',
                status: 'Estado',
                msg: 'Correcciones'
            },
            form: {
                id: null,
                front: null,
                post: null,
                photo: null,
                citizenship: null,
                td: null,
                nd: null,
                name: null,
                email: null,
                birthday: null,
                expedition : null,
                phone: null,
                country: null,
                role: null,
                status: null,
                msg: null
            },
            dni: [],
            title: '',
            img: null,
            view: false
        }),
        created() {
            this.getData();
            this.getCountries();
        },
        watch: {
            'form.citizenship'() {
                if (this.form.citizenship === null || this.form.citizenship === undefined) {
                    this.dni = [];
                } else {
                    this.getDNI(this.form.citizenship);
                }
                if (this.form.status === 101) {
                    this.form.td = null;
                    this.form.nd = null;
                    this.$refs.form.resetValidation();
                }
            },
            'form.nd'() {
                this.form.nd = this.form.nd.toUpperCase();
            }
        },
        methods: {
            getCountries() {
                axios.get('/api/countries').then((res) => {
                    this.countries = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                })
            },
            getData(v) {
                this.loading = true;

                if (v === 1) {
                    this.desserts = [];
                }

                axios.get('/api/customers').then((res) => {
                    if (res.data.users) {
                        this.desserts = res.data.users;
                        this.approveds = res.data.approveds;
                        this.reviews = res.data.reviews;
                        this.blockeds = res.data.blockeds;
                        this.podium = res.data.podium;
                        this.counts = [
                            { title: "Sin Verificar", count: res.data.pending, icon: 'mdi-alert-circle', color: 'tertiary' },
                            { title: "En Revisión", count: res.data.review, icon: 'mdi-sync-circle', color: 'primary'  },
                            { title: "Verificado", count: res.data.approved, icon: 'mdi-check-circle', color: 'success' },
                            { title: "Bloqueado", count: res.data.blocked, icon: 'mdi-close-circle', color: 'error'  }
                        ]
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form.id = items[i].id;
                            this.form.front = items[i].front;
                            this.form.post = items[i].post;
                            this.form.photo = items[i].photo;
                            this.form.citizenship = items[i].citizenship;
                            this.form.td = items[i].td;
                            this.form.nd = items[i].nd;
                            this.form.name = items[i].name;
                            this.form.email = items[i].email;
                            this.form.birthday = items[i].birthday;
                            this.form.expedition = items[i].expedition;
                            this.form.phone = items[i].phone;
                            this.form.country = items[i].country;
                            this.form.role = items[i].role;
                            this.form.status = items[i].status;
                            this.form.msg = items[i].suspendReason;
                        }
                    }
                } else {
                    this.close();
                }
                this.dialog = true;
            },
            getDNI(v) {
                if (v === 'MEX' || v === 'USA') {
                    this.dni = [
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'PER') {
                    this.dni = [
                        { name: 'Documento Nacional de Identidad', value: 'DNI' },
                        { name: 'Carnet de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Permiso Temporal de Permanencia', value: 'PTP' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'COL') {
                    this.dni = [
                        { name: 'Cedula de Ciudadania', value: 'CC' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Número de Identificación Tributaria', value: 'NIT' },
                        { name: 'Permiso por Protección Temporal', value: 'PPT' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'VEN') {
                    this.dni = [
                        { name: 'Cedula de Identidad', value: 'V' },
                        { name: 'Carnet de Extranjeria', value: 'E' },
                        { name: 'RIF', value: 'J' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'CHL') {
                    this.td = [
                        {name: 'Rol Único Tributario', value: 'RUT'},
                        {name: 'Rol Único Nacional', value: 'RUN'},
                        {name: 'Cedula de Identidad', value: 'CI'},
                        {name: 'Cedula de Extranjeria', value: 'CE'},
                        {name: 'Licencia de Conducir', value: 'LIC'},
                        {name: 'Pasaporte', value: 'PAS'}
                    ]
                } else {
                    this.dni = [
                        { name: 'Cedula de Identidad', value: 'CI' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                }
            },
            getImg(i) {
                if (i === 1) {
                    this.img = this.form.photo;
                    this.title = 'Foto Principal';
                } else if (i === 2) {
                    this.img = this.form.front;
                    this.title = 'DNI Frontal';
                } else {
                    this.img = this.form.post;
                    this.title = 'DNI Posterior';
                }

                this.view = true;
            },
            delImg(id, v) {
                let tt = '';

                if (v === 1) {
                    tt = 'foto principal';
                } else if (v === 2) {
                    tt = 'identificacion frontal';
                } else if (v === 3) {
                    tt = 'identificacion posterior';
                }

                this.$swal({
                    title: '¿Esta seguro de eliminar la ' + tt + '?',
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        this.loading = true;

                        axios.get('/api/profile/image/' + id + '/' + v).then((res) => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                                if (v === 1) {
                                    this.form.photo = null;
                                } else if (v === 2) {
                                    this.form.front = null;
                                } else if (v === 3) {
                                    this.form.post = null;
                                }
                                this.getData();
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                    }
                });
            },
            close(v) {
                if (v === 1) {
                    this.dialog = false;
                }
                this.form = {
                    id: null,
                    front: null,
                    post: null,
                    photo: null,
                    citizenship: null,
                    td: null,
                    nd: null,
                    name: null,
                    email: null,
                    birthday: null,
                    expedition : null,
                    phone: null,
                    country: null,
                    role: null,
                    status: null,
                    msg: null
                };
                this.$refs.form.resetValidation();
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    axios.post('/api/update/user', this.form).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData();
                            this.close(1);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
