<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">PENDIENTE POR COBRAR</v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="success" small depressed dark @click="getCollect(item.id)" style="margin: 1px">
                                <v-icon>mdi-cash</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed dark @click="getDelete(item.id)" style="margin: 1px">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="coins" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submitCollect" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">Cobrar (ID: {{ cData.id }})</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" md="10">
                                        <h3 class="text-center"><u>Cliente</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4" style="text-transform: capitalize">{{ cData.name ? (cData.name).toLowerCase() : '' }}</h2>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <h3 class="text-center"><u>Valor a Cobrar</u></h3>
                                        <h2 class="text-center grey--text text--darken-1 pa-3 grey lighten-4">{{ cData.amount_text }} {{ cData.currency }}</h2>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="8">
                                        <v-currency-field
                                            :label="labels.money"
                                            v-model="form.money"
                                            name="money"
                                            :error-messages="errors.money"
                                            :rules="[rules.required('money'), rules.minus('money', form.money, cData.amount, 'El')]"
                                            :disabled="loading"
                                            @input="clearErrors('money')"
                                            prepend-icon="mdi-cash-check"
                                            v-bind="currency_config"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <h2 class="text-center grey--text text--darken-2 pb-4">
                                            <u class="success--text" v-if="(form.money - cData.amount) > 0"> Cambio: {{ (form.money - cData.amount).toLocaleString('de-DE') }} {{ cData.currency }}</u>
                                            <u class="error--text" v-else> Cambio: {{ form.money ? (form.money - cData.amount).toLocaleString('de-DE') : (0 - cData.amount).toLocaleString('de-DE') }} {{ cData.currency }}</u>
                                        </h2>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="closeCollect">
                                    <v-icon>mdi-close-circle</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Cerrar</span>
                                </v-btn>
                                <v-spacer/>
                                <v-btn color="primary" @click="print" :disabled="office.name === null">
                                    <v-icon>mdi-printer</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Imprimir</span>
                                </v-btn>
                                <v-btn type="submit" :loading="loading" color="success" :disabled="loading || !valid || form.money < cData.amount">
                                    <v-icon>mdi-hand-coin</v-icon><span v-show="$vuetify.breakpoint.smAndUp">&nbsp;&nbsp;Cobrar</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <div id="ticket" style="width: 280px; max-width: 280px; font-size: 14px; margin: 0;">
                            <img src="/img/logo-icoin-dark.png" alt="logo icoin" title="Logo iCoin" style="max-width: 280px"/>
                            <p style="font-family: 'Trebuchet MS', Helvetica, sans-serif; text-align: center; width: 280px; margin: 0;">
                                <strong><u>TICKET #{{ cData.id }}</u></strong>
                                <br><br>
                                <span v-if="office">{{ office.name }}</span>
                                <br>
                                <span v-if="office">{{ office.address + ' - ' }}{{ office.city + ', ' }}{{ office.country }}</span>
                                <br><br>
                                <span style="text-transform: capitalize;">Cajero: {{ user.name ? (user.name).toLowerCase() : '' }}</span>
                                <br>
                                <span>{{ new Date() | moment("DD/MM/YYYY hh:mm:ss A") }}</span>
                                <br>
                                <span>Válido por 30 Días</span>
                                <br><br>
                                <span>Valor Recibido: <strong>{{ cData.amount_text }} {{ cData.currency }}</strong></span>
                                <br>
                                <span>Comisión Operacional: <strong>{{ cData.fee_text }} {{ cData.currency }}</strong></span>
                                <br>
                                <span>Tipo de Pago: <strong>{{ cData.type === 'Office' ? 'Oficina' : 'Deposito/Transferencia' }}</strong></span>
                                <br><br>
                            </p>
                            <template v-for="item in cData.transfers">
                                <table style="font-family: 'Trebuchet MS', Helvetica, sans-serif; margin: 0; border-top: 1px solid black; border-collapse: collapse;">
                                    <thead>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <th style="width: 120px; max-width: 120px;">ID Transacción</th>
                                        <th style="width: 160px; max-width: 160px;">{{ item.id }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <td style="width: 120px; max-width: 120px;">Beneficiario</td>
                                        <td style="width: 160px; max-width: 160px; text-transform: capitalize;">{{ item.name ? (item.name).toLowerCase() : '' }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.nd !== null">
                                        <td style="width: 120px; max-width: 120px;">Documento</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.td + ' ' + (item.td === 'PAS' || item.td === 'RUT' ? item.nd : Number(item.nd).toLocaleString("de-DE")) }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <td style="width: 120px; max-width: 120px;">Banco</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.bank_name }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.tc">
                                        <td style="width: 120px; max-width: 120px;">Tipo de Cuenta</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.tc }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.nc">
                                        <td style="width: 120px; max-width: 120px;">Número de Cuenta</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.country_receiver === 'VEN' ? (item.bank + item.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : item.nc }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <td style="width: 120px; max-width: 120px;">Valor Inicial</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.amount_send_text }} {{ item.currency_send }}</td>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <td style="width: 120px; max-width: 120px;">Tasa de Cambio</td>
                                        <td style="width: 160px; max-width: 160px;">{{ item.fx_rate }}</td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <th style="width: 120px; max-width: 120px;">Valor a Pagar</th>
                                        <th style="width: 160px; max-width: 160px;">{{ item.amount_receiver_text }} {{ item.currency_receiver }}</th>
                                    </tr>
                                    <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                        <th colspan="2"/>
                                    </tr>
                                    </tfoot>
                                </table>
                                <br>
                                <br>
                            </template>
                            <div style="text-align: center; font-family: 'Trebuchet MS', Helvetica, sans-serif; width: 280px; margin: 0;">
                                <div style="margin: -2px 0 0; border: 1px solid #333;">
                                    <p style="font-weight: 600; font-size: 16px; margin: 4px 0;">Depositante</p>
                                    <p style="font-size: 16px; margin: 4px 0; text-transform: capitalize;">{{ cData.name ? (cData.name).toLowerCase() : '' }}</p>
                                    <p style="margin: -2px 0 8px; font-size: 14px;">{{ cData.email ? (cData.email).toLowerCase() : '' }}</p>
                                </div>
                                <br>
                                <p style="align-content: center; font-size: 16px;">
                                    <b>¡GRACIAS POR PREFERIRNOS!</b>
                                </p>
                                <br>
                                <br>
                            </div>
                        </div>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: "Collect",
        mixins: [ Form ],
        props: [ 'user', 'office' ],
        data: () => ({
            search: null,
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Cliente', value: 'name' },
                { text: 'Valor', value: 'amount_text', align: 'end' },
                { text: 'Moneda', value: 'currency' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes cobros pendientes',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            coins: false,
            labels: {
                money: 'Valor Recibido'
            },
            form: {
                money: null
            },
            cData: {
                id: null,
                name: null,
                email: null,
                cashier: null,
                type: null,
                amount: null,
                amount_text: null,
                fee: null,
                fee_text: null,
                currency: null,
                transfers: [],
                created_at: null
            },
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0,
                valueAsInteger: false,
                allowNegative: false
            }
        }),
        created () {
            this.getData();
        },
        watch: {
            user() {
                this.getData(1);
            },
            office() {
                this.getData();
            }
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/cashier/in').then((res) => {
                    if (res.data.v === 'success') {
                        this.desserts = res.data.payments;
                    } else {
                        this.$toast.error(res.data.status, { icon: 'mdi-close-circle' });
                        this.$router.push({ name: 'Home' });
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getCollect(id) {
                this.coins = true;
                const items = this.desserts;

                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === id) {
                        this.cData = items[i];
                    }
                }
            },
            closeCollect() {
                this.coins = false;
                this.cData = {};
                this.form.money = null;
                this.$refs.form.reset();
            },
            submitCollect() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let formData = new FormData();
                    formData.append('id',  this.cData.id);
                    axios.post('/api/cashier/collect/', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.print();
                            this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                            this.closeCollect();
                            this.getData();
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            getDelete(id) {
                this.$swal({
                    title: '¿Esta seguro de rechazar el pago ID: ' + id + '?',
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/cashier/reject/' + id).then((res) => {
                            if (res.data.v === 'success') {
                                this.getData();
                                this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'El pago ID: ' + id + ' se ha eliminado exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            } else if (res.data.v === 'warning') {
                                this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        });
                    }
                });
            },
            print() {
                let printContents = document.getElementById('ticket').innerHTML;
                let w = window.open(' ', 'popimpr');
                w.document.write(printContents);
                w.document.close(); // necessary for IE >= 10
                w.focus(); // necessary for IE >= 10
                w.print();
                w.close();
            }
        }
    }
</script>

<style scoped>
    @media print {
        #ticket {}
    }
    @media screen {
        #ticket {
            display: none;
        }
    }
</style>
