<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col>
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text pa-1"
                                  style="word-break: normal">TÉRMINOS & CONDICIONES</v-card-title>
                    <v-boilerplate type="list-item-two-line, list-item-three-line, list-item-three-line, list-item" v-if="terms === ''"/>
                    <v-card-text class="text-justify pa-8" v-html="terms" v-else/>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: "Legal",
    props: [ 'user' ],
    components: {
        VBoilerplate: {
            functional: true,

            render(h, {data, props, children}) {
                return h('v-skeleton-loader', {
                    ...data,
                    props: {
                        boilerplate: true,
                        elevation: 0,
                        ...props,
                    },
                }, children)
            },
        }
    },
    data: () => ({
        terms: ''
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            axios.get('/api/legal').then((res) => {
                this.terms = res.data.terms;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            });
        }
    }
}
</script>

<style scoped>

</style>
