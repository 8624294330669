<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">ULTIMOS MOVIMIENTOS</v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-tabs grow>
                            <template v-for="(i, k) in items">
                            <v-tab @click="search = ''">
                                <b>{{ k }}</b>
                            </v-tab>
                            <v-tab-item>
                                <v-card>
                                    <v-card-title>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                                    </v-card-title>
                                    <v-data-table :headers="headers" :items="i" :search="search" :loading="loading"
                                                  :no-data-text="noDataText" :footer-props="footerProps">
                                        <template v-slot:item.ref="{ item }">
                                            <strong>{{ item.ref }}</strong>
                                        </template>
                                        <template v-slot:item.amount_text="{ item }">
                                            <v-chip class="error--text" v-if="item.type === '-'">
                                                <b>{{ item.amount_text }}</b>
                                            </v-chip>
                                            <v-chip class="success--text" v-else-if="item.type === '+'">
                                                <b>{{ item.amount_text }}</b>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <v-btn color="success" small depressed @click="print(item.id)"
                                                   style="margin: 1px" :disabled="!item.payment_id && !item.transfer_id">
                                                <v-icon>mdi-printer</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-tab-item>
                            </template>
                        </v-tabs>
                    </v-card-text>
                </v-card>
                <div id="Ticket-1" style="width: 280px; max-width: 280px; font-size: 14px; margin: 0;">
                    <img src="/img/logo-icoin-dark.png" alt="logo icoin" title="Logo iCoin" style="max-width: 280px">
                    <p style="font-family: 'Trebuchet MS', Helvetica, sans-serif; text-align: center; width: 280px; margin: 0;">
                        <strong><u>TICKET #{{ cData.id }}</u></strong>
                        <br><br>
                        <span v-if="office">{{ office.name }}</span>
                        <br>
                        <span v-if="office">{{ office.address + ' - ' }}{{ office.city + ', ' }}{{ office.country }}</span>
                        <br><br>
                        <span style="text-transform: capitalize;">Cajero: {{ cData.cashier ? (cData.cashier).toLowerCase() : '' }}</span>
                        <br>
                        <span>{{ cData.date }}</span>
                        <br>
                        <span>Válido por 30 Días</span>
                        <br><br>
                        <span>Valor Recibido: <strong>{{ cData.amount_text }} {{ cData.currency }}</strong></span>
                        <br>
                        <span>Comisión Operacional: <strong>{{ cData.fee_text }} {{ cData.currency }}</strong></span>
                        <br>
                        <span>Tipo de Pago: <strong>{{ cData.type === 'Office' ? 'Oficina' : 'Deposito/Transferencia' }}</strong></span>
                        <br><br>
                    </p>
                    <template v-for="item in cData.transfers">
                        <table style="font-family: 'Trebuchet MS', Helvetica, sans-serif; margin: 0; border-top: 1px solid black; border-collapse: collapse;">
                            <thead>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <th style="width: 120px; max-width: 120px;">ID Transacción</th>
                                <th style="width: 160px; max-width: 160px;">{{ item.id }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <td style="width: 120px; max-width: 120px;">Beneficiario</td>
                                <td style="width: 160px; max-width: 160px; text-transform: capitalize;">{{ item.name ? (item.name).toLowerCase() : '' }}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.nd">
                                <td style="width: 120px; max-width: 120px;">Documento</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.td + ' ' + (item.td === 'PAS' || item.td === 'RUT' ? item.nd : Number(item.nd).toLocaleString("de-DE"))}}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <td style="width: 120px; max-width: 120px;">Banco</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.bank_name }}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.tc">
                                <td style="width: 120px; max-width: 120px;">Tipo de Cuenta</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.tc }}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="item.nc">
                                <td style="width: 120px; max-width: 120px;">Número de Cuenta</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.country_receiver === 'VEN' ? (item.bank + item.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : item.nc}}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <td style="width: 120px; max-width: 120px;">Valor Inicial</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.amount_send_text }} {{ item.currency_send }}</td>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <td style="width: 120px; max-width: 120px;">Tasa de Cambio</td>
                                <td style="width: 160px; max-width: 160px;">{{ item.fx_rate }}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <th style="width: 120px; max-width: 120px;">Valor a Pagar</th>
                                <th style="width: 160px; max-width: 160px;">{{ item.amount_receiver_text }} {{ item.currency_receiver }}</th>
                            </tr>
                            <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                                <th colspan="2"/>
                            </tr>
                            </tfoot>
                        </table>
                        <br>
                        <br>
                    </template>
                    <div style="text-align: center; font-family: 'Trebuchet MS', Helvetica, sans-serif; width: 280px; margin: 0;">
                        <div style="margin: -2px 0 0; border: 1px solid #333;">
                            <p style="font-weight: 600; font-size: 16px; margin: 4px 0;">Depositante</p>
                            <p style="font-size: 16px; margin: 4px 0;">{{ cData.name ? (cData.name).toLowerCase() : '' }}</p>
                            <p style="margin: -2px 0 8px; font-size: 14px;">{{ cData.email ? (cData.email).toLowerCase() : '' }}</p>
                        </div>
                        <br>
                        <p style="align-content: center; font-size: 16px;">
                            <b>¡GRACIAS POR PREFERIRNOS!</b>
                        </p>
                        <br>
                        <br>
                    </div>
                </div>
                <div id="Ticket-2" style="width: 280px; max-width: 280px; font-size: 14px; margin: 0;">
                    <img src="/img/logo-icoin-dark.png" alt="logo icoin" title="Logo iCoin" style="max-width: 280px">
                    <p style="font-family: 'Trebuchet MS', Helvetica, sans-serif; text-align: center; width: 280px; margin: 0;">
                        <strong><u>TICKET #{{ PayData.payment_id }}</u></strong>
                        <br><br>
                        <span v-if="office">{{ office.name }}</span>
                        <br>
                        <span v-if="office">{{ office.address + ' - ' }}{{ office.city + ', ' }}{{ office.country }}</span>
                        <br><br>
                        <span style="text-transform: capitalize;">Cajero: {{ PayData.cashier ? (PayData.cashier).toLowerCase() : '' }}</span>
                        <br>
                        <span>{{ PayData.date }}</span>
                        <br>
                        <span>Válido por 30 Días</span>
                        <br><br>
                    </p>
                    <table style="font-family: 'Trebuchet MS', Helvetica, sans-serif; margin: 0; border-top: 1px solid black; border-collapse: collapse;">
                        <thead>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <th style="width: 120px; max-width: 120px;">ID Transacción</th>
                            <th style="width: 160px; max-width: 160px;">{{ PayData.id }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <td style="width: 120px; max-width: 120px;">Beneficiario</td>
                            <td style="width: 160px; max-width: 160px; text-transform: capitalize;">{{ PayData.name ? (PayData.name).toLowerCase() : '' }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.nd">
                            <td style="width: 120px; max-width: 120px;">Documento</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.td + ' ' + (PayData.td === 'PAS' || PayData.td === 'RUT' ? PayData.nd : Number(PayData.nd).toLocaleString("de-DE")) }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <td style="width: 120px; max-width: 120px;">Banco</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.bank_name }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.tc">
                            <td style="width: 120px; max-width: 120px;">Tipo de Cuenta</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.tc }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;" v-if="PayData.nc">
                            <td style="width: 120px; max-width: 120px;">Número de Cuenta</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.nc }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <td style="width: 120px; max-width: 120px;">Valor Inicial</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.amount_send_text }} {{ PayData.currency_send }}</td>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <td style="width: 120px; max-width: 120px;">Tasa de Cambio</td>
                            <td style="width: 160px; max-width: 160px;">{{ PayData.fx_rate }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <th style="width: 120px; max-width: 120px;">Valor a Pagar</th>
                            <th style="width: 160px; max-width: 160px;">{{ PayData.amount_receiver_text }} {{ PayData.currency_receiver }}</th>
                        </tr>
                        <tr style="border-top: 1px solid black; border-collapse: collapse; text-align: left;">
                            <th colspan="2"/>
                        </tr>
                        </tfoot>
                    </table>
                    <div style="text-align: center; font-family: 'Trebuchet MS', Helvetica, sans-serif; width: 280px; margin: 0;">
                        <br>
                        <br>
                        <p style="font-size: 16px; margin: 4px 0;">
                            <b>Firma: ________________________</b>
                        </p>
                        <br>
                        <p style="font-size: 16px; margin: 4px 0;">
                            <b>Documento: ___________________</b>
                        </p>
                        <br>
                        <p style="align-content: center; font-size: 16px;">
                            <b>¡GRACIAS POR PREFERIRNOS!</b>
                        </p>
                        <br>
                        <br>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Movements",
        props: [ 'user', 'office' ],
        data: () => ({
            name: null,
            search: '',
            headers: [
                { text: 'Fecha', value: 'created_at' },
                { text: 'Referencia', value: 'ref' },
                { text: 'Detalle', value: 'details' },
                { text: 'Valor', value: 'amount_text', align: 'end' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            items: [],
            loading: true,
            noDataText: 'No tienes movimientos registrados',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            cData: {},
            PayData: {},
            printer: 0
        }),
        created() {
            this.getData();
        },
        watch: {
            office() {
                this.getData(1);
            }
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.items = []; }
                axios.get('/api/cashier/movements').then((res) => {
                    if (res.data.v === 'success') {
                        this.items = res.data.items;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            cleanPrint(v) {
                if (v === 1) {
                    this.cData = {}
                } else if (v === 2) {
                    this.PayData = {};
                }
                this.printer = 0;
            },
            print(id) {
                this.loading = true;
                axios.get('/api/cashier/print/' + id).then((res) => {
                    this.printer = res.data.print;
                    if (res.data.v === 'success') {
                        if (this.printer === 1) {
                            this.cData = res.data.item;
                        } else if (this.printer === 2) {
                            this.PayData = res.data.item;
                        }
                    } else {
                        this.$toast.error(res.data.status, {
                            timeout: 8000,
                            icon: 'mdi-information',
                            dismissable: false,
                            showClose: true
                        });
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    if (this.printer === 1 || this.printer === 2) {
                        this.Print(this.printer);
                    }
                    this.cleanPrint(this.printer);
                    this.loading = false;
                });
            },
            Print(v) {
                let printContents = document.getElementById('Ticket-' + v).innerHTML;
                let w = window.open(' ', 'popimpr');
                w.document.write(printContents);
                w.document.close(); // necessary for IE >= 10
                w.focus(); // necessary for IE >= 10
                w.print();
                w.close();
            }
        }
    }
</script>

<style scoped>
    @media print {
        #Ticket-1, #Ticket-2 {}
    }
    @media screen {
        #Ticket-1, #Ticket-2 {
            display: none;
        }
    }
</style>
