window._ = require('lodash');

import axios from 'axios';
import store from "./store";
import routes from "./routes";
import {app} from "./app";

window.axios = require('axios');

axios.interceptors.request.use(config => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';

    const token = store.getters['token'];
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, async error => {
    if (store.getters['token']) {
        // TODO: Find more reliable way to determine when Token state
        if (error.response.status === 401 && error.response.data.message === 'El token ha caducado.') {
            const { data } = await axios.post('/api/login/refresh');
            await store.dispatch('saveToken', data);
            return axios.request(error.config);
        }

        if (error.response.status === 401 ||
            (error.response.status === 500 && (
                error.response.data.message === 'El token ha caducado y no se pudo actualizar.' ||
                error.response.data.message === 'El token ha sido incluido en la lista negra.'
            ))
        ) {
            await store.dispatch('destroy');
            routes.push({ name: 'Login' });
        }
    }

    error.response.data.message !== undefined && app.$toast.error(error.response.data.message || 'Algo salió mal.', { icon: 'mdi-close-circle' });
    error.response.data.error !== undefined && app.$toast.error(error.response.data.error || 'Se produjo un error.', { icon: 'mdi-close-circle' });
    return Promise.reject(error);
});
