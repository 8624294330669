<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CONFIGURAR PRECIOS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-row justify="center" class="mx-0">
                        <v-col cols="12">
                            <v-btn color="primary" large block depressed @click="get()" style="margin: 1px">
                                <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Agregar Precio</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"
                                      single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :expanded.sync="expanded"
                                  :loading="loading" :no-data-text="noDataText" :expand-icon="expandIcon"
                                  :footer-props="footerProps" single-expand show-expand>
                        <template v-slot:item.id="{ item }">
                            <b>{{ item.rateId }}</b>
                        </template>
                        <template v-slot:item.rate_type="{ item }">
                            {{ item.rate_type === 'buy' ? 'Compra' : 'Venta' }}
                        </template>
                        <template v-slot:item.value="{ item }">
                            {{ Number(item.value).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 12}) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed @click="get(item.id)" style="margin: 1px">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed @click="del(item.id, item.rateId)" style="margin: 1px">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="py-4">
                                <v-row justify="center">
                                    <v-col cols="6" sm="3" md="2" class="py-2">
                                        <div class="text-end">
                                            <strong>País Enviador</strong>
                                            <br>
                                            <span>{{ item.country_send_text }}</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="3" md="2" class="py-2">
                                        <div class="text-start">
                                            <strong>País Receptor</strong>
                                            <br>
                                            <span>{{ item.country_receiver_text }}</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="3" md="2" class="py-2">
                                        <div class="text-end">
                                            <strong>Banco Enviador</strong>
                                            <br>
                                            <span>{{ item.bank_send_text }}</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="3" md="2" class="py-2">
                                        <div class="text-start">
                                            <strong>Banco Receptor</strong>
                                            <br>
                                            <span>{{ item.bank_receiver_text }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">{{ form.rateId ? 'Editar ID: '+form.rateId : 'Agregar Precio' }}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-text-field
                                            :label="labels.value"
                                            v-model="form.value"
                                            type="number"
                                            name="value"
                                            :error-messages="errors.value"
                                            :rules="[rules.required('value')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-cash-check"
                                            @input="clearErrors('value')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.rate_type"
                                            :items="rate_types"
                                            name="order_type"
                                            v-model="form.rate_type"
                                            :error-messages="errors.rate_type"
                                            :disabled="loading"
                                            :rules="[rules.req('rate_type')]"
                                            @select="clearErrors('rate_type')"
                                            prepend-icon="mdi-autorenew"
                                            item-text="name"
                                            item-value="value"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.currency_send"
                                            :items="currencies"
                                            name="currency_send"
                                            v-model="form.currency_send"
                                            :error-messages="errors.currency_send"
                                            :disabled="loading"
                                            :rules="[rules.required('currency_send')]"
                                            @select="clearErrors('currency_send')"
                                            prepend-icon="mdi-cash-minus"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.currency_receiver"
                                            :items="currencies"
                                            name="currency_receiver"
                                            v-model="form.currency_receiver"
                                            :error-messages="errors.currency_receiver"
                                            :disabled="loading"
                                            :rules="[rules.required('currency_receiver')]"
                                            @select="clearErrors('currency_receiver')"
                                            prepend-icon="mdi-cash-plus"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country_send"
                                            :items="countries"
                                            name="country_send"
                                            v-model="form.country_send"
                                            :error-messages="errors.country_send"
                                            :disabled="loading"
                                            :rules="[]"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            @select="clearErrors('country_send')"
                                            @change="getBanksSend"
                                            prepend-icon="mdi-map-marker-up"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country_receiver"
                                            :items="countries"
                                            name="country_receiver"
                                            v-model="form.country_receiver"
                                            :error-messages="errors.country_receiver"
                                            :disabled="loading"
                                            :rules="[]"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            @select="clearErrors('country_receiver')"
                                            @change="getBanksReceiver"
                                            prepend-icon="mdi-map-marker-down"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.bank_send"
                                            :items="banks_send"
                                            name="bank_send"
                                            v-model="form.bank_send"
                                            :error-messages="errors.bank_send"
                                            :disabled="loading"
                                            :rules="[]"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            @select="clearErrors('bank_send')"
                                            prepend-icon="mdi-bank-transfer-out"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.bank_receiver"
                                            :items="banks_receiver"
                                            name="bank_receiver"
                                            v-model="form.bank_receiver"
                                            :error-messages="errors.bank_receiver"
                                            :disabled="loading"
                                            :rules="[]"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            @select="clearErrors('bank_receiver')"
                                            prepend-icon="mdi-bank-transfer-in"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success" :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ form.id ? 'Actualizar' : 'Guardar' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "configRate",
        mixins: [ Form ],
        props: ['user'],
        data: () => ({
            search: '',
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'Valor', value: 'value'},
                {text: 'Tasa', value: 'rate_type'},
                {text: 'Monedas', value: 'currencies'},
                {text: 'Paises', value: 'countries'},
                {text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false},
                {text: '', filterable: false, value: 'data-table-expand'}
            ],
            desserts: [],
            expanded: [],
            loading: false,
            noDataText: 'No tienes tasas de cambio registradas',
            expandIcon: 'mdi-chevron-down-circle',
            footerProps: {
                itemsPerPageOptions: [100, 250, 500, -1]
            },
            dialog: false,
            labels: {
                id: 'ID',
                value: 'Valor',
                rate_type: 'Tipo de Comisión',
                currency_send: 'Moneda Enviador',
                currency_receiver: 'Moneda Receptor',
                country_send: 'País Enviador',
                country_receiver: 'País Receptor',
                bank_send: 'Banco Enviador',
                bank_receiver: 'Banco Receptor'
            },
            form: {
                id: null,
                rateId: null,
                value: null,
                rate_type: null,
                currency_send: null,
                currency_receiver: null,
                country_send: null,
                country_receiver: null,
                bank_send: null,
                bank_receiver: null
            },
            rate_types: [
                { name: 'Compra', value: 'buy' },
                { name: 'Venta', value: 'sell' }
            ],
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            currencies: [
                { name: 'Bolivares', code: 'VES' },
                { name: 'Nuevos Soles', code: 'PEN' },
                { name: 'Pesos Chilenos', code: 'CLP' },
                { name: 'Pesos Colombianos', code: 'COP' },
                { name: 'Pesos Mexicanos', code: 'MXN' },
                { name: 'Dolares', code: 'USD' },
                { name: 'Dolares (Tether)', code: 'USDT' }
            ],
            banks: [],
            banks_send: [],
            banks_receiver: [],
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0
            }
        }),
        created () {
            this.getData();
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/settings/rate').then((res) => {
                    this.desserts = res.data.rate;
                    this.banks = res.data.banks;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getData, 60000);
            },
            getBanksSend() {
                const items = this.banks;
                const banks = [];

                for (let i = 0; i < items.length; i++) {
                    if (this.form.country_send) {
                        if (items[i].country === this.form.country_send) {
                            banks.push(items[i]);
                        }
                    } else {
                        if (items[i].country === null) {
                            banks.push(items[i]);
                        }
                    }
                }
                this.banks_send = banks;
            },
            getBanksReceiver() {
                const items = this.banks;
                const banks = [];

                for (let i = 0; i < items.length; i++) {
                    if (this.form.country_receiver) {
                        if (items[i].country === this.form.country_receiver) {
                            banks.push(items[i]);
                        }
                    } else {
                        if (items[i].country === null) {
                            banks.push(items[i]);
                        }
                    }
                }
                this.banks_receiver = banks;
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form = items[i];
                            this.form.value = parseFloat(items[i].value);
                            this.getBanksSend();
                            this.getBanksReceiver();
                        }
                    }
                }
                this.dialog = true;
                clearTimeout(this.timeout);
            },
            close(v) {
                this.form = {
                    id: null,
                    rateId: null,
                    value: null,
                    rate_type: null,
                    currency_send: null,
                    currency_receiver: null,
                    country_send: null,
                    country_receiver: null,
                    bank_send: null,
                    bank_receiver: null
                };
                if (v === 1) {
                    this.dialog = false;
                    this.$refs.form.reset();
                }
                this.timeout = setTimeout(this.getData, 60000);
            },
            del(v, fv) {
                this.$swal({
                    title: '¿Esta seguro de eliminar esta configuración de cambio?',
                    text: 'Comisión ID: ' + fv,
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/settings/rate/d/' + v).then((res) => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                                this.getData();
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'La tasa de cambio con ID: ' + fv + ' se ha eliminada exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                    }
                });
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let url = '/api/settings/rate';

                    if (this.form.id) {
                        url += '/u/' + this.form.id + '?';
                    } else {
                        url += '/c?';
                    }

                    url += 'value=' + this.form.value + '&rate_type=' + this.form.rate_type + '&currency_send=' +
                        this.form.currency_send + '&currency_receiver=' + this.form.currency_receiver +
                        '&country_send=' + this.form.country_send + '&country_receiver=' + this.form.country_receiver;

                    if (this.form.bank_send) {
                        url += '&bank_send=' + this.form.bank_send;
                    }

                    if (this.form.bank_receiver) {
                        url += '&bank_receiver=' + this.form.bank_receiver;
                    }

                    axios.get(url).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData();
                            this.close(1);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
