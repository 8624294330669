<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CORRECCIONES PENDIENTES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" dark depressed fab absolute right small @click="getCorrections(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps" class="mb-12 mb-lg-0">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="success" small depressed dark @click="correctionP(item.payment_id)">
                                    <v-icon>mdi-image-edit</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="cp" persistent scrollable max-width="800px">
                    <payment v-on:close-payment="closeP" :id="id" @success="getCorrections"/>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Payment from "../../correction/Payment.vue";

    export default {
        name: "VCorrections",
        components: { Payment },
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Código', value: 'code', align: 'right', sortable: false },
                { text: 'Cliente', value: 'name', align: 'left', sortable: false },
                { text: 'Error', value: 'error', align: 'left', sortable: false },
                { text: 'Descripción', value: 'message', align: 'left', sortable: false },
                { text: 'Cant.', value: 'qty', align: 'right', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No hay correcciones pendientes',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            cp: false,
            id: null
        }),
        created () {
            this.getCorrections();
        },
        methods: {
            getCorrections(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/checker/corrections').then((res) => {
                    if (res.data.items) {
                        this.desserts = res.data.items;
                    } else {
                        this.$toast.error(res.data.status, { icon: 'mdi-close-circle' });
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            correctionP(id) {
                this.id = id;
                this.cp = true;
            },
            closeP() {
                this.cp = false;
                this.id = null;
            }
        }
    }
</script>

<style scoped>

</style>

