<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col>
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text pa-1"
                                  style="word-break: normal">AVISO IMPORTANTE</v-card-title>
                    <v-boilerplate type="list-item-two-line, list-item-three-line, list-item-three-line, list-item" v-if="!info"/>
                    <v-card-text class="text-center pt-4" v-html="info" v-else/>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col>
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text pa-1"
                                  style="word-break: normal">Recomendaciones para Depositar</v-card-title>
                    <v-card-text class="text-center py-4">
                        <h2 class="my-2">ENVÍO DE DINERO POR DEPÓSITO BANCARIO</h2>
                        <p>Aquí encontrará nuestros números de cuenta, por favor lea bien la descripción detallada y sólo si está conforme realice sus depósitos.</p>
                        <template v-for="(bank, k) in banks">
                            <v-row justify="center" :key="k">
                                <v-col cols="12" md="10">
                                    <v-divider/>
                                </v-col>
                                <v-col cols="10" sm="3" align-self="center">
                                    <img :src="'/'+bank.img" width="100%" :alt="bank.name" style="border-radius: 6px">
                                </v-col>
                                <v-col cols="12" sm="7" align-self="center" v-html="bank.content"/>
                            </v-row>
                        </template>
                        <v-row justify="center">
                            <v-col cols="12" md="10">
                                <v-divider/>
                            </v-col>
                            <v-col cols="12" md="10">
                                <h4 class="text-center">
                                    <strong>
                                        <h3 class="mb-4">LIMITES POR TRANSACCIÓN</h3>
                                        <v-simple-table dense>
                                            <tbody>
                                            <tr style="background: transparent;">
                                                <td class="text-right grey--text text--darken-2" style="border: transparent; width: 50%;">
                                                    <u>MINIMO</u>
                                                </td>
                                                <td class="text-left grey--text text--darken-2" style="border: transparent; width: 50%;">
                                                    <u>MAXIMO</u>
                                                </td>
                                            </tr>
                                            <template v-for="i in limits['min']">
                                                <template v-for="e in limits['max']">
                                                    <template v-if="i.data.currency === e.data.currency">
                                                        <tr style="background: transparent;">
                                                            <td class="text-right" style="border: transparent;">{{ i.data.amount }}</td>
                                                            <td class="text-left" style="border: transparent;">{{ e.data.amount }}</td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </template>
                                            </tbody>
                                        </v-simple-table>
                                    </strong>
                                </h4>
                            </v-col>
                        </v-row>
                        <v-row justify="center" v-if="user.country === 'PER'">
                            <v-col cols="12" md="10">
                                <v-divider/>
                            </v-col>
                            <v-col cols="12" md="10">
                                <h4 class="pb-4" style="text-decoration: underline">
                                    <strong>IMPORTANTE PARA NUESTROS CLIENTES DE PROVINCIA</strong>
                                </h4>
                                <p>Todos los depósitos realizados desde provincia tienen un descuento en razón a la comisión que cobran los bancos por depósitos en el interior del país.</p>
                                <h4>
                                    <strong>
                                        <em>Las comisiones dependen de cada Banco por depósito bancario desde provincia (fuera de Lima)</em>
                                    </strong>
                                    <em>*Recomendamos realizar los depósitos desde Agentes autorizados (los encuentra en bodegas, farmacias, etc), desde Cajeros automáticos (verifique que entreguen voucher) y mediante transferencia bancaria (adjunte la captura con fecha y hora, monto y nombre del titular, NO ACEPTAMOS: transferencias interbancarias, giros internacionales y/o agencias como western). <strong><span style="text-decoration: underline;">Tenga muy en cuenta que si va a depositar desde ventanilla del banco BCP (ya sea en Lima o provincias), le haremos el descuento manual de la comisión que el banco realiza.</span></strong></em>
                                </h4>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "About",
        props: [ 'user' ],
        components: {
            VBoilerplate: {
                functional: true,

                render(h, {data, props, children}) {
                    return h('v-skeleton-loader', {
                        ...data,
                        props: {
                            boilerplate: true,
                            elevation: 0,
                            ...props,
                        },
                    }, children)
                },
            }
        },
        data: () => ({
            loading: true,
            info: null,
            banks: [],
            limits: []
        }),
        created () {
            this.getData();
        },
        watch: {
            user() {
                this.getData();
            }
        },
        methods: {
            getData() {
                axios.get('/api/about').then((res) => {
                    this.info = res.data.info;
                    this.banks = res.data.banks;
                    this.limits = res.data.limits;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
