export default {
    badge: 'Insignia',
    close: 'Cerrar',
    dataIterator: {
        noResultsText: 'Sin resultados',
        loadingText: 'Cargando... Por favor, espere',
    },
    dataTable: {
        itemsPerPageText: 'Mostrar:',
        ariaLabel: {
            sortDescending: ': Orden descendente.',
            sortAscending: ': Orden ascendente.',
            sortNone: ': Sin ordenar.',
            activateNone: 'Pulse para quitar orden.',
            activateDescending: 'Pulse para ordenar descendente.',
            activateAscending: 'Pulse para ordenar ascendente.',
        },
        sortBy: 'Ordenado por',
    },
    dataFooter: {
        itemsPerPageText: 'Mostrar:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Página siguiente',
        prevPage: 'Página anterior',
        firstPage: 'Primer página',
        lastPage: 'Última página',
        pageText: '{0} - {1} de {2}',
    },
    datePicker: {
        itemsSelected: '{0} seleccionados',
        nextMonthAriaLabel: 'Próximo mes',
        nextYearAriaLabel: 'Próximo año',
        prevMonthAriaLabel: 'Mes anterior',
        prevYearAriaLabel: 'Año anterior',
    },
    noDataText: 'No hay datos disponibles',
    carousel: {
        prev: 'Anterior',
        next: 'Siguiente',
        ariaLabel: {
            delimiter: 'Diapositiva {0} de {1}',
        },
    },
    calendar: {
        moreEvents: '{0} más',
    },
    fileInput: {
        counter: '{0} archivos',
        counterSize: '{0} archivos ({1} en total)',
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
    },
    pagination: {
        ariaLabel: {
            wrapper: 'Paginación',
            next: 'Página siguiente',
            previous: 'Página anterior',
            page: 'Ir a la página {0}',
            currentPage: 'Página actual, página {0}',
        },
    },
    rating: {
        ariaLabel: {
            icon: 'Clasificación {0} de {1}',
        },
    },
}
