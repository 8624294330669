-<template>
    <v-container class="mb-12 mb-lg-0">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="6" size="48"></v-progress-circular>
        </v-overlay>
        <v-row justify="center">
            <v-col cols="12" v-if="user.suspendReason !== null">
                <v-alert text prominent type="error" icon="mdi-comment-alert">
                    <span class="pre_wrap">{{ user.suspendReason }}</span>
                </v-alert>
            </v-col>
            <v-col cols="12">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card class="mb-8">
                        <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">
                            <v-icon>mdi-account-circle</v-icon>&nbsp;&nbsp;Datos Personales
                        </v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.name"
                                        type="text"
                                        name="name"
                                        :readonly="user.status === 103"
                                        v-model="form.name"
                                        :error-messages="errors.name"
                                        :rules="[rules.required('name'), rules.max('name', 120, 'El')]"
                                        :disabled="loading"
                                        @input="clearErrors('name')"
                                        prepend-icon="mdi-account-circle"/>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.email"
                                        type="email"
                                        name="email"
                                        :readonly="user.status === 103"
                                        v-model="form.email"
                                        :error-messages="errors.email"
                                        :rules="[rules.required('email'), rules.mail('email')]"
                                        :disabled="loading"
                                        @input="clearErrors('email')"
                                        prepend-icon="mdi-email"/>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.phone"
                                        v-model="form.phone"
                                        type="tel"
                                        name="phone"
                                        :readonly="user.status === 103"
                                        placeholder="+573101234567"
                                        :error-messages="errors.phone"
                                        :rules="[rules.required('phone')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-phone"
                                        @input="clearErrors('phone')"/>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-dialog
                                        ref="menu1"
                                        v-model="menu.birthday"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :label="labels.birthday"
                                                v-model="birthday"
                                                name="birthday"
                                                prepend-icon="mdi-calendar"
                                                :error-messages="errors.birthday"
                                                :rules="[rules.req('birthday')]"
                                                :disabled="loading"
                                                @input="clearErrors('birthday')"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-if="user.status === 101"
                                            v-model="form.birthday"
                                            :active-picker.sync="activePicker"
                                            scrollable
                                            :max="(new Date(new Date((new Date()).getFullYear() - 18, (new Date()).getMonth(), (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                            min="1950-01-01"
                                            @change="menu.birthday = false"
                                        ></v-date-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-8">
                        <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">
                            <v-icon>mdi-card-account-details</v-icon>&nbsp;&nbsp;Identificación Personal
                        </v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete
                                        :label="labels.citizenship"
                                        :items="items"
                                        name="citizenship"
                                        :readonly="user.status === 103"
                                        v-model="form.citizenship"
                                        :error-messages="errors.citizenship"
                                        :disabled="loading"
                                        :rules="[rules.required('citizenship')]"
                                        @select="clearErrors('citizenship')"
                                        prepend-icon="mdi-map-marker"
                                        item-text="name"
                                        item-value="code">
                                        <template v-slot:selection="{ item }">
                                            <v-flex>
                                                <v-avatar size="28px">
                                                    <img :src="'https://flagcdn.com/h240/'+item.img+'.png'" :alt="item.name.toLowerCase()" />
                                                </v-avatar>
                                                <span class='ml-1'>{{ item.name }}</span>
                                            </v-flex>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                                <img :src="'https://flagcdn.com/h240/'+item.img+'.png'" :alt="item.name.toLowerCase()" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="item.name"/>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-dialog
                                        ref="menu2"
                                        v-model="menu.exp"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :label="labels.exp"
                                                v-model="exp"
                                                name="exp"
                                                prepend-icon="mdi-calendar"
                                                :error-messages="errors.exp"
                                                :rules="[rules.req('exp')]"
                                                :disabled="loading"
                                                @input="clearErrors('exp')"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-if="user.status === 101"
                                            v-model="form.exp"
                                            :active-picker.sync="activePicker"
                                            scrollable
                                            :min="(new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                            @change="menu.exp = false"
                                        ></v-date-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-select
                                        :label="labels.td"
                                        :items="dni"
                                        name="td"
                                        :readonly="user.status === 103"
                                        v-model="form.td"
                                        :error-messages="errors.td"
                                        :disabled="loading"
                                        :rules="[rules.required('td')]"
                                        @select="clearErrors('td')"
                                        prepend-icon="mdi-clipboard-text"
                                        item-text="name"
                                        item-value="value">
                                        <template v-slot:selection="{ item }">
                                            <v-flex class='ml-1'>{{ item.value }} - {{ item.name }}</v-flex>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.value }} - {{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        v-if="form.citizenship === 'CHL' && (form.td === 'CI' || form.td === 'CE')"
                                        :label="labels.nd"
                                        v-model="form.nd"
                                        type="text"
                                        name="nd"
                                        :readonly="user.status === 103"
                                        :error-messages="errors.nd"
                                        :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-ballot"
                                        @input="clearErrors('nd')"
                                        v-mask="rut"/>
                                    <v-text-field
                                        v-else-if="form.td === 'PAS'"
                                        :label="labels.nd"
                                        v-model="form.nd"
                                        type="text"
                                        name="nd"
                                        :readonly="user.status === 103"
                                        :error-messages="errors.nd"
                                        :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-ballot"
                                        @input="clearErrors('nd')"/>
                                    <v-text-field
                                        v-else
                                        :label="labels.nd"
                                        v-model="form.nd"
                                        type="text"
                                        name="nd"
                                        :readonly="user.status === 103"
                                        :error-messages="errors.nd"
                                        :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-ballot"
                                        @input="clearErrors('nd')"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-8">
                        <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">
                            <v-icon>mdi-folder-multiple-image</v-icon>&nbsp;&nbsp;Documentos
                        </v-card-title>
                        <v-card-text v-if="user.status === 101">
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4" v-if="form.photo === null">
                                    <v-file-input
                                        :label="labels.photo"
                                        v-model="form.photo"
                                        name="photo"
                                        accept="image/*"
                                        capture="user"
                                        :error-messages="errors.photo"
                                        :rules="[rules.req('photo')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-camera-image"
                                        @input="clearErrors('photo')"
                                        @click:clear="clearImage(1)"
                                        show-size counter/>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4" v-if="form.front === null">
                                    <v-file-input
                                        :label="labels.dni"
                                        v-model="form.front"
                                        name="front"
                                        accept="image/*"
                                        capture="environment"
                                        placeholder="Frontal"
                                        :error-messages="errors.front"
                                        :rules="[rules.req('front')]"
                                        :disabled="loading"
                                        prepend-icon="mdi-card-account-details-star"
                                        @input="clearErrors('front')"
                                        @click:clear="clearImage(2)"
                                        show-size
                                        counter/>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" v-if="form.post === null">
                                    <v-file-input
                                        :label="labels.dni"
                                        v-model="form.post"
                                        name="post"
                                        accept="image/*"
                                        capture="environment"
                                        placeholder="Posterior"
                                        :error-messages="errors.post"
                                        :disabled="loading"
                                        prepend-icon="mdi-card-account-details"
                                        @input="clearErrors('post')"
                                        @click:clear="clearImage(3)"
                                        show-size
                                        counter/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-btn color="primary" @click="getImg(1)" large block :disabled="form.photo === null">
                                        <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver Foto Principal
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-btn color="primary" @click="getImg(2)" large block :disabled="form.front === null">
                                        <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver DNI Frontal
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-btn color="primary" @click="getImg(3)" large block :disabled="form.post === null">
                                        <v-icon>mdi-panorama</v-icon>&nbsp;&nbsp;Ver DNI Posterior
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-8" v-if="user.status === 101">
                        <v-card-title class="d-block text-center font-weight-bold grey--text text--darken-2" style="word-break: normal">
                            <v-icon>mdi-shield-check</v-icon>&nbsp;&nbsp;Confirmar
                        </v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        :label="labels.password"
                                        name="password"
                                        v-model="form.password"
                                        :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="() => (passwordHidden = !passwordHidden)"
                                        :type="passwordHidden ? 'password' : 'text'"
                                        :error-messages="errors.password"
                                        :disabled="loading"
                                        :rules="[rules.req('password')]"
                                        @input="clearErrors('password')"
                                        prepend-icon="mdi-lock"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="align-content-center">
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="5" lg="4">
                                    <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="primary" large block>
                                        <v-icon>mdi-database-sync</v-icon>&nbsp;&nbsp;Actualizar Datos
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-dialog v-model="view" scrollable max-width="800" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">{{ title }}</v-card-title>
                        <v-card-text class="pa-4">
                            <img :src="img" :alt="title" width="100%"/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="error" @click="view = false"><v-icon>mdi-close-circle</v-icon>Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../helpers/form";
    import { mask }from "vue-the-mask";

    export default {
        name: "Profile",
        mixins: [ Form ],
        props: [ 'user' ],
        directives: { mask },
        data: () => ({
            loading: true,
            passwordHidden: true,
            labels: {
                dni: 'Identificación',
                photo: 'Foto (Selfie)',
                citizenship: 'Nacionalidad',
                td: 'Tipo de Documento',
                nd: 'Número de Documento',
                name: 'Nombre Completo',
                email: 'Correo Electrónico',
                birthday: 'Fecha de Nacimiento',
                exp : 'Fecha de Vencimiento',
                phone: 'Telefono',
                password: 'Contraseña Actual'
            },
            form: {
                photo: null,
                front: null,
                post: null,
                citizenship: null,
                td: null,
                nd: null,
                name: null,
                email: null,
                birthday: (new Date(new Date((new Date()).getFullYear() - 18, (new Date()).getMonth(), (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
                exp : (new Date(new Date((new Date()).getFullYear() + 1, (new Date()).getMonth(), (new Date()).getDate()) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
                phone: null,
                password: null
            },
            activePicker: null,
            menu: {
                birthday: false,
                exp: false
            },
            items: [],
            dni: [],
            title: '',
            img: null,
            view: false,
            rut: '########-X'
        }),
        created () {
            this.getUser();
            this.getCountries();
        },
        watch: {
            user() {
                this.getUser();
            },
            'menu.birthday'(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'));
            },
            'menu.exp'(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'));
            },
            'form.citizenship'() {
                if (this.form.citizenship === null || this.form.citizenship === undefined) {
                    this.dni = [];
                } else {
                    this.getDNI(this.form.citizenship);
                }
                if (this.user.status === 101) {
                    this.form.td = null;
                    this.form.nd = null;
                    this.$refs.form.resetValidation();
                }
            },
            'form.nd'() {
                this.form.nd = this.form.nd.toUpperCase();
            }
        },
        computed: {
            birthday() {
                return this.formatDate(this.form.birthday)
            },
            exp() {
                return this.formatDate(this.form.exp)
            },
        },
        methods: {
            getCountries() {
                axios.get('/api/countries').then((res) => {
                    this.items = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                })
            },
            getUser() {
                this.loading = true;
                axios.get('/api/profile').then((res) => {
                    this.form.front = res.data.user.front;
                    this.form.post = res.data.user.post;
                    this.form.photo = res.data.user.photo;
                    this.form.citizenship = res.data.user.citizenship;
                    this.form.td = res.data.user.td;
                    this.form.nd = res.data.user.nd;
                    this.form.name = res.data.user.name;
                    this.form.email = res.data.user.email;
                    if (res.data.user.birthday !== null) {
                        this.form.birthday = res.data.user.birthday;
                    }
                    if (res.data.user.expiration !== null) {
                        this.form.exp = res.data.user.expiration;
                    }
                    this.form.phone = res.data.user.phone;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getDNI(v) {
                if (v === 'MEX' || v === 'USA') {
                    this.dni = [
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'PER') {
                    this.dni = [
                        { name: 'Documento Nacional de Identidad', value: 'DNI' },
                        { name: 'Carnet de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Permiso Temporal de Permanencia', value: 'PTP' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'COL') {
                    this.dni = [
                        { name: 'Cedula de Ciudadania', value: 'CC' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Número de Identificación Tributaria', value: 'NIT' },
                        { name: 'Permiso por Protección Temporal', value: 'PPT' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (v === 'VEN') {
                    this.dni = [
                        { name: 'Cedula de Identidad', value: 'V' },
                        { name: 'Carnet de Extranjeria', value: 'E' },
                        { name: 'RIF', value: 'J' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'CHL') {
                    this.td = [
                        {name: 'Rol Único Tributario', value: 'RUT'},
                        {name: 'Rol Único Nacional', value: 'RUN'},
                        {name: 'Cedula de Identidad', value: 'CI'},
                        {name: 'Cedula de Extranjeria', value: 'CE'},
                        {name: 'Licencia de Conducir', value: 'LIC'},
                        {name: 'Pasaporte', value: 'PAS'}
                    ]
                } else {
                    this.dni = [
                        { name: 'Cedula de Identidad', value: 'CI' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                }
            },
            getImg(i) {
                if (i === 1) {
                    if (this.$props.user.photo !== null) {
                        this.img = this.form.photo;
                    } else {
                        const input = this.form.photo;

                        if (input) {
                            const reader = new FileReader;
                            reader.onload = e => {
                                this.img = e.target.result;
                            };
                            reader.readAsDataURL(input);
                        }
                    }
                    this.title = 'Foto Principal';
                } else if (i === 2) {
                    if (this.$props.user.front !== null) {
                        this.img = this.form.front;
                    } else {
                        const input = this.form.front;

                        if (input) {
                            const reader = new FileReader;
                            reader.onload = e => {
                                this.img = e.target.result;
                            };
                            reader.readAsDataURL(input);
                        }
                    }
                    this.title = 'DNI Frontal';
                } else {
                    if (this.$props.user.post !== null) {
                        this.img = this.form.post;
                    } else {
                        const input = this.form.post;

                        if (input) {
                            const reader = new FileReader;
                            reader.onload = e => {
                                this.img = e.target.result;
                            };
                            reader.readAsDataURL(input);
                        }
                    }
                    this.title = 'DNI Posterior';
                }

                this.view = true;
            },
            clearImage(i) {
                if (i === 1)
                    this.form.photo = null;
                if (i === 2)
                    this.form.front = null;
                if (i === 3)
                    this.form.post = null;
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('photo', this.form.photo);
                    formData.append('front',  this.form.front);
                    formData.append('post',  this.form.post);
                    formData.append('citizenship', this.form.citizenship);
                    formData.append('td',  this.form.td);
                    formData.append('nd',  this.form.nd);
                    formData.append('name',  this.form.name);
                    formData.append('email',  this.form.email);
                    formData.append('birthday',  this.form.birthday);
                    formData.append('exp',  this.form.exp);
                    formData.append('phone',  this.form.phone);
                    formData.append('password', this.form.password);

                    axios.post('/api/profile/update', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then((res) => {
                            if (res.data.v === 'info') {
                                this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                            } else {
                                this.$toast.success('¡Tus datos han sido enviados correctamente, debes esperar que sean aprobados para poder usar nuestro sistema.!', { icon: 'mdi-check-circle' });
                                this.$emit('success');
                                this.form.password = null;
                                this.$refs.form.reset();
                            }
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>
