<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">REGISTRO DE CUENTAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-row justify="center" class="mx-0">
                        <v-col cols="12">
                            <v-btn color="primary" large block depressed @click="get" style="margin: 1px">
                                <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Agregar Cuenta</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.status="{ item }">
                            <v-icon :class="item.status === 1 ? 'success--text' : 'error--text'">{{ item.status === 1 ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">{{form.id ? 'Editar '+form.alias+' ('+form.code+')' : 'Agregar Cuenta'}}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.country"
                                            :items="countries"
                                            name="country"
                                            v-model="form.country"
                                            :error-messages="errors.country"
                                            :disabled="loading || form.id"
                                            :rules="[rules.required('country')]"
                                            @select="clearErrors('country')"
                                            @change="clearD()"
                                            prepend-icon="mdi-map-marker"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code+'.png'" :alt="item.name.toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code+'.png'" :alt="item.name.toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-autocomplete
                                            :label="labels.bank"
                                            :items="banks"
                                            name="bank"
                                            v-model="form.bank"
                                            :error-messages="errors.bank"
                                            :disabled="loading || form.id"
                                            :rules="[rules.required('bank')]"
                                            @select="clearErrors('bank')"
                                            prepend-icon="mdi-bank"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.currency"
                                            :items="currencies"
                                            name="currency"
                                            v-model="form.currency"
                                            :error-messages="errors.currency"
                                            :disabled="loading || form.id"
                                            :rules="[rules.req('currency')]"
                                            @select="clearErrors('currency')"
                                            prepend-icon="mdi-currency-usd-circle"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.alias"
                                            v-model="form.alias"
                                            type="text"
                                            name="alias"
                                            :error-messages="errors.alias"
                                            :rules="[rules.required('alias'), rules.min('alias', 3, 'El'), rules.max('alias', 120, 'El')]"
                                            :disabled="loading || form.id"
                                            prepend-icon="mdi-barcode"
                                            @input="clearErrors('alias')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.code"
                                            v-model="form.code"
                                            type="text"
                                            name="code"
                                            :error-messages="errors.code"
                                            :rules="[rules.required('code'), rules.min('code', 1, 'El'), rules.max('code', 120, 'El')]"
                                            :disabled="loading || form.id"
                                            prepend-icon="mdi-qrcode"
                                            @input="clearErrors('code')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.status"
                                            :items="options"
                                            name="status"
                                            v-model="form.status"
                                            :error-messages="errors.status"
                                            :disabled="loading"
                                            :rules="[rules.required('status')]"
                                            @select="clearErrors('status')"
                                            prepend-icon="mdi-bell"
                                            item-text="name"
                                            item-value="value">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success"
                                       :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{form.id ? 'Actualizar' : 'Guardar'}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "configAccounts",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            list: [],
            search: '',
            headers: [
                { text: 'Pais', value: 'cname' },
                { text: 'Banco', value: 'name' },
                { text: 'Alias', value: 'alias' },
                { text: 'Código', value: 'code' },
                { text: 'Moneda', value: 'currency' },
                { text: 'Estado', value: 'status', filterable: false, sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes cuentas registradas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            dialog: false,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Venezuela', code: 'VEN' },
                { name: 'Todos', code: '000' }
            ],
            currencies: [],
            banks: [],
            options: [
                { name: 'Activo', value: '1' },
                { name: 'Inactivo', value: '0' }
            ],
            labels: {
                country: 'País',
                currency: 'Moneda',
                bank: 'Banco',
                alias: 'Alias',
                code: 'Código',
                status: 'Estado'
            },
            form: {
                id: null,
                country: null,
                currency: null,
                bank: null,
                alias: null,
                code: null,
                status: null
            }
        }),
        created() {
            this.getData();
        },
        watch: {
            'form.country'() {
                if (this.form.country) {
                    this.getCurrency();
                    this.getBanks();
                }
            }
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/settings/accounts').then((res) => {
                    if (res.data.accounts) {
                        this.desserts = res.data.accounts;
                        this.list = res.data.banks;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getCurrency() {
                let local = null;
                let currencies = [];

                if (this.form.country === 'CHL') {
                    local = { name: 'Pesos Chilenos', code: 'CLP' };
                } else if (this.form.country === 'COL') {
                    local = { name: 'Pesos Colombianos', code: 'COP' };
                } else if (this.form.country === 'MEX') {
                    local = { name: 'Pesos Mexicanos', code: 'MXN' };
                } else if (this.form.country === 'PER') {
                    local = { name: 'Nuevos Soles', code: 'PEN' };
                } else if (this.form.country === 'VEN') {
                    local = { name: 'Bolivares', code: 'VES' };
                } else if (this.form.country === '000') {
                    local = { name: 'Dolares (Tether)', code: 'USDT' };
                }

                if (local) {
                    currencies.push(local);
                }
                if (this.form.country !== '000') {
                    currencies.push({name: 'Dolares', code: 'USD'});
                }
                this.currencies = currencies;
            },
            getBanks() {
                this.banks = [];

                const items = this.list;
                const banks = this.banks;

                for (let i = 0; i < items.length; i++) {
                    if (this.form.country === items[i].country) {
                        banks.push(items[i])
                    } else if (this.form.country === '000' && !items[i].country) {
                        banks.push(items[i])
                    }
                }
            },
            clearD() {
                this.form.currency = null;
                this.form.bank = null;
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form.id = items[i].id;
                            this.form.country = items[i].country;
                            this.form.currency = items[i].currency;
                            this.form.bank = items[i].bank;
                            this.form.alias = items[i].alias;
                            this.form.code = items[i].code;
                            this.form.status = items[i].status ? (items[i].status).toString() : '0';
                        }
                    }
                } else {
                    this.close();
                }
                this.dialog = true;
            },
            close(v) {
                if (v === 1) { this.dialog = false; }
                this.form = {
                    id: null,
                    country: null,
                    currency: null,
                    bank: null,
                    alias: null,
                    code: null,
                    status: null
                };
                this.banks = [];
                this.currencies = [];
                this.$refs.form.reset();
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    let url = '/api/settings/accounts/add';

                    if (this.form.id) {
                        url = '/api/settings/accounts/update';
                        formData.append('id', this.form.id);
                    }
                    formData.append('country', this.form.country);
                    formData.append('currency', this.form.currency);
                    formData.append('bank', this.form.bank);
                    formData.append('alias', this.form.alias);
                    formData.append('code', this.form.code);
                    formData.append('status', this.form.status);

                    axios.post(url, formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData();
                            this.close(1);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
