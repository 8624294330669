<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col cols="12" lg="11">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card width="100%">
                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                        <v-card-title class="d-block text-center font-weight-bold primary warning--text pa-1" style="word-break: normal">TRASLADO ENTRE CUENTAS
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country"
                                            :items="countries"
                                            name="country"
                                            v-model="form.country"
                                            :disabled="loading"
                                            :error-messages="errors.country"
                                            :rules="[rules.required('country')]"
                                            @input="clearErrors('country')"
                                            prepend-icon="mdi-map-marker"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px" class="mr-2">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                                    </v-avatar>{{ item.name }}
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-currency-field
                                            :label="labels.amount"
                                            v-model="form.amount"
                                            name="amount"
                                            :error-messages="errors.amount"
                                            :rules="[rules.required('amount'), rules.minus('amount', form.amount, '1', 'El')]"
                                            :disabled="loading"
                                            @input="clearErrors('amount')"
                                            prepend-icon="mdi-cash-multiple"
                                            v-bind="currency_config"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-autocomplete
                                            :label="labels.account_send"
                                            :items="accounts"
                                            name="account_send"
                                            v-model="form.account_send"
                                            :error-messages="errors.account_send"
                                            :disabled="loading"
                                            :rules="[rules.req('account_send')]"
                                            @select="clearErrors('account_send')"
                                            prepend-icon="mdi-clipboard-text"
                                            item-text="name"
                                            item-value="id">
                                            <template v-slot:selection="{ item }">
                                                <v-flex class='ml-1'><b>{{ item.code }}</b> - {{ item.alias }} - {{ item.currency }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="'<b>' + item.code + '</b> - ' + item.alias + ' - ' + item.currency"/>
                                                    <v-list-item-subtitle v-html="'<b>' + item.bank_name + '</b>'"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-autocomplete
                                            :label="labels.account_receiver"
                                            :items="accounts"
                                            name="account_receiver"
                                            v-model="form.account_receiver"
                                            :error-messages="errors.account_receiver"
                                            :disabled="loading"
                                            :rules="[rules.req('account_receiver')]"
                                            @select="clearErrors('account_receiver')"
                                            prepend-icon="mdi-clipboard-text"
                                            item-text="name"
                                            item-value="id">
                                            <template v-slot:selection="{ item }">
                                                <v-flex class='ml-1'><b>{{ item.code }}</b> - {{ item.alias }} - {{ item.currency }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="'<b>' + item.code + '</b> - ' + item.alias + ' - ' + item.currency"/>
                                                    <v-list-item-subtitle v-html="'<b>' + item.bank_name + '</b>'"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-text-field
                                            :label="labels.reference"
                                            v-model="form.reference"
                                            name="reference"
                                            :error-messages="errors.reference"
                                            :rules="[rules.req('reference')]"
                                            :disabled="loading"
                                            @change="clearErrors('reference')"
                                            prepend-icon="mdi-pound-box"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5" v-if="form.country === 'VEN'">
                                        <v-checkbox
                                            :label="labels.add"
                                            v-model="form.add"
                                            class="mx-2 pb-0"
                                            color="success"
                                            :disabled="loading"/>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <v-divider/>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <h2 class="text-center grey--text text--darken-2 pb-4" v-if="voucher === null">Agregar Comprobante (Ctrl + V)</h2>
                                        <h2 class="text-center success--text pb-4" v-else>
                                            <u>Comprobante Agregado</u>
                                        </h2>
                                        <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="voucher === null"/>
                                        <img :src="voucherUrl" alt="icoin" width="100%" v-else/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5" class="py-8">
                                        <v-btn type="submit" block :loading="loading" :disabled="loading || !valid || voucher === null" color="success">
                                            <v-icon>mdi-check-circle</v-icon> Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: 'CTransfer',
        mixins: [Form],
        props: [ 'user' ],
        data: () => ({
            loading: false,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Venezuela', code: 'VEN' },
                { name: 'Todos', code: '000' }
            ],
            accounts: [],
            labels: {
                add: 'Interbancaria',
                country: 'País',
                account_send: 'Cuenta inicial',
                account_receiver: 'Cuenta Final',
                amount: 'Valor',
                reference: 'Referencia'
            },
            form: {
                add: null,
                country: null,
                account_send: null,
                account_receiver: null,
                amount: null,
                reference: null
            },
            voucher: null,
            voucherUrl: null,
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0,
                valueAsInteger: false,
                allowNegative: false
            },
        }),
        mounted() {
            window.addEventListener("paste", this.getPasteVoucher);
        },
        watch: {
            'form.country'() {
                if (this.form.country)
                    this.getAccounts(this.form.country);
            }
        },
        methods: {
            getAccounts(c) {
                axios.get('/api/transfers/t/accounts/' + c).then((res) => {
                    this.accounts = res.data.accounts;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            getPasteVoucher(e) {
                for (let i = 0; i < e.clipboardData.items.length; i++) {
                    let clipboardItem = e.clipboardData.items[0];
                    let type = clipboardItem.type;

                    if (type.indexOf("image") !== -1) {
                        let blob = clipboardItem.getAsFile();
                        this.voucherUrl = URL.createObjectURL(blob);

                        let reader = new window.FileReader();
                        reader.readAsDataURL(clipboardItem.getAsFile());

                        reader.onload = e => {
                            this.voucher = e.target.result;
                        }
                    } else {
                        console.log('Los datos tipo ' + type + ' no son soportados');
                    }
                }
            },
            clearData() {
                this.form = {
                    country: null,
                    account_send: null,
                    account_receiver: null,
                    amount: null,
                    reference: null
                };
            },
            submit() {
                let send = this.form.account_send;
                let receiver = this.form.account_receiver;
                if (send !== receiver) {
                    if (this.$refs.form.validate()) {
                        this.loading = true;

                        let formData = new FormData();
                        formData.append('country', this.form.country);
                        formData.append('account_send', this.form.account_send);
                        formData.append('account_receiver', this.form.account_receiver);
                        formData.append('amount', this.form.amount);
                        formData.append('reference', this.form.reference);
                        formData.append('voucher', this.voucher);
                        if (this.form.country === 'VEN') {
                            formData.append('add', (this.form.add === true ? '1' : '0'));
                        }

                        axios.post('/api/movements/transfer', formData, {
                            headers: {'Content-Type': 'multipart/form-data'}
                        }).then(res => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                                this.clearData();
                                this.voucher = null;
                                this.voucherUrl = null;
                                this.$refs.form.resetValidation();
                            } else if (res.data.v === 'warning') {
                                this.$toast.warning(res.data.status, {icon: 'alert-circle'});
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                    }
                } else {
                    this.$toast.error('No puedes realizar un traslado a la misma cuenta', {icon: 'alert-circle'});
                }
            }
        },
        destroyed() {
            window.removeEventListener('paste', this.getPasteVoucher);
        }
    }
</script>

<style scoped>

</style>
