<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">REPORTE DE MOVIMIENTOS
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-card>
                        <v-card-text>
                            <v-tabs v-model="tab" grow>
                                <v-tab @click="format('-')">Reportar Salidas</v-tab>
                                <v-tab @click="format('+')">Reportar Entradas</v-tab>
                            </v-tabs>
                            <v-divider/>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-card flat v-if="tp === '-'">
                                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="12" md="8">
                                                    <v-textarea
                                                        :label="labels.detail"
                                                        v-model="form.detail1"
                                                        name="detail"
                                                        counter="255"
                                                        :error-messages="errors.detail1"
                                                        :disabled="loading"
                                                        :rules="[rules.required('detail'), rules.min('detail', 6, 'El'), rules.max('detail', 255, 'El')]"
                                                        prepend-inner-icon="mdi-text"
                                                        @input="clearErrors('detail1')"
                                                        no-resize
                                                        rows="4"
                                                        filled/>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-currency-field
                                                        :label="labels.value"
                                                        v-model="form.value1"
                                                        name="value"
                                                        :error-messages="errors.value1"
                                                        :rules="[rules.required('value'), rules.minus('value', form.value1, 1, 'El')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('value1')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-select
                                                        :label="labels.currency"
                                                        :items="currencies"
                                                        name="currency"
                                                        v-model="form.currency1"
                                                        :error-messages="errors.currency1"
                                                        :disabled="loading"
                                                        :rules="[rules.req('currency')]"
                                                        @select="clearErrors('currency1')"
                                                        prepend-inner-icon="mdi-account-cash"
                                                        item-text="name"
                                                        item-value="code"
                                                        filled>
                                                        <template v-slot:selection="{ item}">
                                                            <v-flex>
                                                                <v-avatar size="28px" color="primary">
                                                                    <span v-if="item.text === 'PEN'" class="warning--text subtitle-2 text-center"
                                                                          style="width: 100%">S/.</span>
                                                                    <v-icon v-else class="warning--text">mdi-currency-usd</v-icon>
                                                                </v-avatar>
                                                                <span class='ml-1'>{{ item.name }}</span>
                                                            </v-flex>
                                                        </template>
                                                        <template v-slot:item="{ item}">
                                                            <v-list-item-avatar color="primary">
                                                                <span v-if="item.code === 'PEN'" class="warning--text subtitle-1 text-center"
                                                                      style="width: 100%"><strong>S/.</strong></span>
                                                                <v-icon v-else class="warning--text">mdi-currency-usd</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="item.name"/>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <v-btn type="submit" block :loading="loading" color="success" :disabled="loading || !valid">
                                                        <v-icon>mdi-minus-circle-multiple</v-icon><span>&nbsp;&nbsp;Registrar Egreso</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card flat v-if="tp === '+'">
                                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                                        <v-card-text>
                                            <v-row justify="center">
                                                <v-col cols="12" md="8">
                                                    <v-textarea
                                                        :label="labels.detail"
                                                        v-model="form.detail2"
                                                        name="detail"
                                                        counter="255"
                                                        :error-messages="errors.detail2"
                                                        :disabled="loading"
                                                        :rules="[rules.required('detail'), rules.min('detail', 6, 'El'), rules.max('detail', 255, 'El')]"
                                                        prepend-inner-icon="mdi-text"
                                                        @input="clearErrors('detail2')"
                                                        no-resize
                                                        rows="4"
                                                        filled/>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-currency-field
                                                        :label="labels.value"
                                                        v-model="form.value2"
                                                        name="value"
                                                        :error-messages="errors.value2"
                                                        :rules="[rules.required('value'), rules.minus('value', form.value2, 1, 'El')]"
                                                        :disabled="loading"
                                                        @input="clearErrors('value2')"
                                                        prepend-inner-icon="mdi-cash-multiple"
                                                        v-bind="currency_config"
                                                        filled/>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-select
                                                        :label="labels.currency"
                                                        :items="currencies"
                                                        name="currency"
                                                        v-model="form.currency2"
                                                        :error-messages="errors.currency2"
                                                        :disabled="loading"
                                                        :rules="[rules.req('currency')]"
                                                        @select="clearErrors('currency2')"
                                                        prepend-inner-icon="mdi-account-cash"
                                                        item-text="name"
                                                        item-value="code"
                                                        filled>
                                                        <template v-slot:selection="{ item}">
                                                            <v-flex>
                                                                <v-avatar size="28px" color="primary">
                                                                    <span v-if="item.code === 'PEN'" class="warning--text subtitle-2 text-center"
                                                                          style="width: 100%">S/.</span>
                                                                    <v-icon v-else class="warning--text">mdi-currency-usd</v-icon>
                                                                </v-avatar>
                                                                <span class='ml-1'>{{ item.name }}</span>
                                                            </v-flex>
                                                        </template>
                                                        <template v-slot:item="{ item}">
                                                            <v-list-item-avatar color="primary">
                                                                <span v-if="item.code === 'PEN'" class="warning--text subtitle-1 text-center"
                                                                      style="width: 100%"><strong>S/.</strong></span>
                                                                <v-icon v-else class="warning--text">mdi-currency-usd</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="item.name"/>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="5">
                                                    <v-btn type="submit" block :loading="loading" color="success" :disabled="loading || !valid">
                                                        <v-icon>mdi-plus-circle-multiple</v-icon><span>&nbsp;&nbsp;Registrar Ingreso</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: "InOut",
        mixins: [ Form ],
        props: [ 'user', 'office' ],
        data: () => ({
            tab: null,
            loading: false,
            currencies: [],
            labels: {
                detail: 'Detalle',
                value: 'Valor',
                currency: 'Moneda'
            },
            form: {
                detail1: null,
                detail2: null,
                value1: null,
                value2: null,
                currency1: null,
                currency2: null
            },
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0,
                valueAsInteger: false,
                allowNegative: false
            },
            tp: '-'
        }),
        mounted() {
            this.getData();
        },
        watch: {
            user() {
                this.getData();
            },
            office() {
                this.getData();
            }
        },
        methods: {
            getData() {
                let c = null;
                let currencies = [];

                if (this.user.country === 'CHL') {
                    c = { name: 'Pesos Chilenos', code: 'CLP' };
                } else if (this.user.country === 'COL') {
                    c = { name: 'Pesos Colombianos', code: 'COP' };
                } else if (this.user.country === 'MXN') {
                    c = { name: 'Pesos Mexicanos', code: 'MXN' };
                } else if (this.user.country === 'PEN') {
                    c = { name: 'Nuevos Soles', code: 'PEN' };
                } else if (this.user.country === 'VEN') {
                    c = { name: 'Bolivares', code: 'VES' };
                }

                if (c) { currencies.push(c) }
                currencies.push({ name: 'Dolares', code: 'USD' })
                this.currencies = currencies;
            },
            format(id) {
                this.tp = id;
            },
            cleanData(tp) {
                if (tp === '-') {
                    this.form.detail1 = null;
                    this.form.value1 = null;
                    this.form.currency1 = null;
                } else if (tp === '+') {
                    this.form.detail2 = null;
                    this.form.value2 = null;
                    this.form.currency2 = null;
                }
                this.$refs.form.reset();
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let tp = this.tp;
                    let formData = new FormData();

                    formData.append('tp', tp);
                    formData.append('detail', tp === '-' ? this.form.detail1 : this.form.detail2);
                    formData.append('value', tp === '-' ? this.form.value1 : this.form.value2);
                    formData.append('currency', tp === '-' ? this.form.currency1 : this.form.currency2);
                    axios.post('/api/cashier/in-out', formData).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.cleanData(tp);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
