<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">PAGOS RECHAZADOS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" dark depressed fab absolute right small @click="getRejected(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps" class="mb-12 mb-lg-0">
                        <template v-slot:item.id="{ item }">
                            <strong>{{item.id}}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{(item.name).toLowerCase()}}</span>
                            <v-icon small class="success--text" title="Verificado" v-if="item.approved === 103">mdi-check-circle</v-icon>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="info" small depressed @click="getVoucher(item.id)" style="margin: 1px" v-if="item.voucher">
                                    <v-icon>mdi-panorama</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed @click="getInfo(item.id)" style="margin: 1px">
                                    <v-icon>mdi-information</v-icon>
                                </v-btn>
                                <v-btn color="error" small depressed @click="getRestore(item.id)" style="margin: 1px" v-if="user.role === 'Administrator'">
                                    <v-icon>mdi-restore-alert</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="info" persistent scrollable max-width="800px">
                    <info v-on:close-info="closeInfo" :info="info_id"/>
                </v-dialog>
                <v-dialog v-model="view" persistent scrollable max-width="1000px" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline font-weight-bold primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Comprobante #{{voucher_id}}</v-card-title>
                        <v-card-text class="py-4">
                            <v-btn color="white" light depressed absolute top left fab small class="mt-7" @click="rotate(voucher_id, 'left')">
                                <v-icon>mdi-rotate-left</v-icon>
                            </v-btn>
                            <v-btn color="white" light depressed absolute top right fab small class="mt-7" @click="rotate(voucher_id, 'right')">
                                <v-icon>mdi-rotate-right</v-icon>
                            </v-btn>
                            <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="voucher === null"/>
                            <img :src="'/' + voucher" :alt="voucher_id" width="100%" v-else/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="closeVoucher"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import info from '../../transfers/Info';

    export default {
        name: "VRejected",
        components: { info },
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Cliente', value: 'name', align: 'left', sortable: false },
                { text: 'Pais', value: 'country_name', align: 'left', sortable: false },
                { text: 'Valor', value: 'amount_text', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency', align: 'left', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No hay pagos rechazados',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            info_id: null,
            info: false,
            voucher_id: null,
            voucher: null,
            view: false
        }),
        created () {
            this.getRejected();
        },
        methods: {
            getRejected(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/checker/rejected').then((res) => {
                    if (res.data.transfers) {
                        this.desserts = res.data.transfers;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getInfo(v) {
                this.info_id = v;
                this.info = true;
            },
            closeInfo() {
                this.info = false;
                this.info_id = null;
            },
            getVoucher(id) {
                axios.get('/api/history/v1/' + id).then((res) => {
                    let code = Math.round(Math.random()*999999);
                    this.voucher = res.data.voucher + '?v=' + code;
                    this.voucher_id = id;
                    this.view = true;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            closeVoucher() {
                this.view = false;
                this.voucher = null;
                this.voucher_id = null;
            },
            getRestore(id) {
                this.$swal({
                    title: '¿Esta seguro de restaurar el pago #' + id + '?',
                    type: 'warning',
                    confirmButtonText: 'Si, restaurar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/transfers/restore/'+id).then((res) => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                                this.getRejected();
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'El pago #'+id+' se ha restaurado exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        });
                    }
                });
            },
            rotate(id, dir) {
                axios.get('/api/voucher/rotate/'+dir+'/'+id).then(() => {
                    this.voucher = null;
                    this.getVoucher(id);
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            }
        }
    }
</script>

<style scoped>

</style>
