<template>
    <v-layout align-sm-center justify-center>
        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
        <v-flex xs12 sm9 md10 lg8 class="py-4">
            <v-img src="/img/logo-icoin-dark.png" class="hidden-md-and-up mx-12 mb-6"/>
            <v-card flat color="transparent" class="ma-3">
                <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                    <v-toolbar flat color="primary" class="mb-4">
                        <v-toolbar-title
                            class="text-center font-weight-bold secondary--text headline"
                            style="white-space: normal; width: 100%">RECUPERAR CONTRASEÑA
                        </v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text class="px-0 pb-0">
                        <p class="text-center">Por favor, escriba su correo electrónico. Luego recibirá un enlace para crear una contraseña nueva.</p>
                        <v-text-field
                            :label="labels.email"
                            v-model="form.email"
                            type="email"
                            name="email"
                            :error-messages="errors.email"
                            :rules="[rules.required('email'), rules.mail('email')]"
                            :disabled="loading"
                            prepend-inner-icon="mdi-account-circle"
                            @input="clearErrors('email')"
                            filled/>
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading || !valid"
                            color="primary"
                            class="font-weight-bold"
                            block large
                        >Restablecer</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            <div class="text-center py-2 py-sm-4 hidden-xs-only">
                ¿Sabes la contraseña?&nbsp;&nbsp;
                <v-btn
                    color="secondary"
                    :to="form.email ? {name: 'Login', query: {email: form.email}} : {name: 'Login'}"
                >Ingresar</v-btn>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import axios from 'axios';
    import Form from "../../helpers/form";

    export default {
        name: "Forgot",
        mixins: [ Form ],
        data: () => ({
            labels: { email: 'Correo Electrónico' },
            form: { email: null }
        }),
        created() {
            this.form.email = this.$route.query.email || null;
        },
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    axios.post('/api/password/email', this.form)
                        .then((res) => {
                            this.$toast.info('Se han enviado instrucciones a su correo electrónico para restablecer la contraseña.', {
                                icon: 'mdi-information'
                            });
                            this.success();
                        })
                        .catch(err => {
                            this.handleErrors(err.response.data.errors);
                        })
                        .then(() => {
                            this.loading = false;
                        })
                }
            },
            success() {
                this.$router.push({ name: 'Login' });
            }
        }
    }
</script>

<style scoped>

</style>
