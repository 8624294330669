<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
        <v-card>
            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
            <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                          style="word-break: normal">
                <span class="headline">Corrección de Datos {{ !charge ? 'ID: ' + $props.id : '' }}</span>
            </v-card-title>
            <v-card-text v-if="charge">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" sm="8">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-skeleton-loader class="mx-auto" width="100%" type="list-item"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-text v-else>
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12" :sm="form.bn === 'Nuestras Oficinas' ? 12 : 6">
                            <v-text-field
                                :label="labels.name"
                                v-model="form.name"
                                type="text"
                                name="name"
                                :counter="100"
                                :error-messages="errors.name"
                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-account-circle"
                                @input="clearErrors('name')"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete
                                :label="labels.bank"
                                :items="banks"
                                name="bank"
                                v-model="form.bank"
                                :error-messages="errors.bank"
                                :disabled="loading"
                                :rules="[rules.required('bank')]"
                                @select="clearErrors('bank')"
                                prepend-icon="mdi-domain"
                                item-text="name"
                                item-value="code"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.bn === 'Nuestras Oficinas'">
                            <v-autocomplete
                                :label="labels.branch"
                                :items="branches"
                                name="branch"
                                v-model="form.branch"
                                :error-messages="errors.branch"
                                :disabled="loading"
                                :rules="[rules.required('branch')]"
                                @select="clearErrors('branch')"
                                prepend-icon="mdi-store"
                                item-text="name"
                                item-value="id"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                :label="labels.td"
                                :items="td"
                                name="td"
                                v-model="form.td"
                                :error-messages="errors.td"
                                :disabled="loading"
                                :rules="[rules.required('td')]"
                                @select="clearErrors('td')"
                                prepend-icon="mdi-clipboard-text"
                                item-text="name"
                                item-value="value">
                                <template v-slot:selection="{ item }">
                                    <v-flex class='ml-1'>{{ item.value + ' - ' + item.name }}</v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.value + ' - ' + item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.country === 'CHL' && (form.td === 'CI' || form.td === 'CE')">
                            <v-text-field
                                :label="labels.nd"
                                v-model="form.nd"
                                type="text"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"
                                v-mask="rut"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-else-if="form.td === 'PAS'">
                            <v-text-field
                                :label="labels.nd"
                                v-model="form.nd"
                                type="text"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-else>
                            <v-text-field
                                :label="labels.nd"
                                v-model="form.nd"
                                type="number"
                                name="nd"
                                :error-messages="errors.nd"
                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-ballot"
                                @input="clearErrors('nd')"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.bn !== 'Nuestras Oficinas'">
                            <v-select
                                :label="labels.tc"
                                :items="tc"
                                name="tc"
                                v-model="form.tc"
                                :error-messages="errors.tc"
                                :disabled="loading"
                                :rules="[rules.req('tc')]"
                                @select="clearErrors('tc')"
                                prepend-icon="mdi-wallet"
                                item-text="name"
                                item-value="value"/>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.country === 'VEN' && form.bn !== 'Nuestras Oficinas'">
                            <v-text-field
                                :label="labels.nc"
                                v-model="form.nc"
                                type="tel"
                                name="nc"
                                :prefix="form.bank"
                                :error-messages="errors.nc"
                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-dialpad"
                                @input="clearErrors('nc')"
                                v-mask="mask"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" v-else-if="form.bn !== 'Nuestras Oficinas'">
                            <v-text-field
                                :label="labels.nc"
                                v-model="form.nc"
                                type="text"
                                inputmode="numeric"
                                name="nc"
                                :error-messages="errors.nc"
                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                :disabled="loading"
                                prepend-icon="mdi-dialpad"
                                @input="clearErrors('nc')"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn color="error" @click="closeT">Cerrar</v-btn>
                <v-spacer/>
                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">Actualizar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import axios from "axios";
    import Form from "../../helpers/form";
    import { mask }from 'vue-the-mask';

    export default {
        name: "Transfer",
        mixins: [ Form ],
        props: [ 'id' ],
        directives: { mask },
        data: () => ({
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            banks: [],
            branches: [],
            td: [],
            tc: [],
            labels: {
                name: 'Nombre',
                td: 'Tipo de Documento',
                nd: 'Número de Documento',
                bank: 'Entidad Bancaria',
                branch: 'Oficina de Pago',
                tc: 'Tipo de Cuenta',
                nc: 'Número de Cuenta'
            },
            loading: false,
            form: {
                country: null,
                name: null,
                td: null,
                nd: null,
                bank: null,
                branch: null,
                bn: null,
                br: null,
                tc: null,
                nc: null,
            },
            mask: '#### #### #### #### ####',
            rut: '########-X',
            charge: true
        }),
        mounted() {
            this.getCorrection();
        },
        watch: {
            id() {
                if (this.id !== null) {
                    this.getCorrection();
                }
            },
            'form.nc'() {
                if (this.form.country === 'VEN') {
                    this.form.nc = this.form.nc ? (this.form.nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : null;
                }
            },
            'form.bank'() {
                if (this.form.bank) {
                    this.getBank();
                } else {
                    this.form.bn = null;
                }
            },
            'form.branch'() {
                if (this.form.branch) {
                    this.getBranch();
                } else {
                    this.form.br = null;
                }
            }
        },
        methods: {
            getBanks(c) {
                axios.get('/api/banks/' + c).then((res) => {
                    this.banks = res.data.banks;
                    this.branches = res.data.branches;
                });
            },
            getCorrection() {
                this.loading = true;
                axios.get('/api/corrections/t/' + this.$props.id).then((res) => {
                    if (res.data.v === 'success') {
                        this.form = res.data.transfer;
                        this.getBanks(this.form.country);
                        this.getTypeDoc();
                        this.getTypeAcc();
                    } else {
                        this.$toast.error(res.data.status, { icon: 'mdi-information' });
                        this.closeT();
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.charge = false;
                    this.loading = false;
                });
            },
            getTypeDoc() {
                let c = this.form.country;
                if (c === 'MEX' || c === 'USA') {
                    this.td = [
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'PER') {
                    this.td = [
                        { name: 'Documento Nacional de Identidad', value: 'DNI' },
                        { name: 'Carnet de Extranjeria', value: 'CE' },
                        { name: 'Permiso Temporal de Permanencia', value: 'PTP' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'COL') {
                    this.td = [
                        { name: 'Cedula de Ciudadania', value: 'CC' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Número de Identificación Tributaria', value: 'NIT' },
                        { name: 'Permiso por Protección Temporal', value: 'PPT' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'VEN') {
                    this.td = [
                        { name: 'Cedula de Identidad', value: 'V' },
                        { name: 'Carnet de Extranjeria', value: 'E' },
                        { name: 'RIF', value: 'J' },
                        { name: 'Gubernamental', value: 'G' },
                        { name: 'Firma Personal', value: 'M' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                } else if (c === 'CHL') {
                    this.td = [
                        {name: 'Rol Único Tributario', value: 'RUT'},
                        {name: 'Rol Único Nacional', value: 'RUN'},
                        {name: 'Cedula de Identidad', value: 'CI'},
                        {name: 'Cedula de Extranjeria', value: 'CE'},
                        {name: 'Licencia de Conducir', value: 'LIC'},
                        {name: 'Pasaporte', value: 'PAS'}
                    ]
                } else {
                    this.td = [
                        { name: 'Cedula de Identidad', value: 'CI' },
                        { name: 'Cedula de Extranjeria', value: 'CE' },
                        { name: 'Licencia de Conducir', value: 'LIC' },
                        { name: 'Pasaporte', value: 'PAS' }
                    ]
                }
            },
            getTypeAcc() {
                let c = this.form.country;
                if (c === 'CHL') {
                    this.tc = [
                        { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                        { name: 'Cuenta Corriente', value: 'Corriente' },
                        { name: 'Cuenta Vista', value: 'Vista' },
                        { name: 'Cuenta RUT', value: 'RUT' }
                    ]
                } else {
                    this.tc = [
                        { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                        { name: 'Cuenta Corriente', value: 'Corriente' }
                    ]
                }
            },
            getBank() {
                const banks = this.banks;

                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc = null;
                            this.form.nc = null;
                        } else {
                            this.form.branch = null;
                        }
                        this.form.bn = banks[i].name;
                    }
                }
            },
            getBranch() {
                const branches = this.branches;

                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].code === this.form.branch) {
                        this.form.br = branches[i].name;
                    }
                }
            },
            closeT() {
                this.$emit('close-transfer');
                this.$refs.form.reset();
                this.charge = true;
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('name',  this.form.name);
                    formData.append('td',  this.form.td);
                    formData.append('nd',  this.form.nd);
                    formData.append('bank',  this.form.bank);
                    formData.append('branch',  this.form.branch);
                    formData.append('tc',  this.form.tc);
                    formData.append('nc',  this.form.nc ? (this.form.nc).replace(/[^\d]/g, '') : null);

                    axios.post('/api/corrections/t/' + this.$props.id, formData)
                        .then(res => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                                this.$refs.form.reset();
                                this.$emit('close-transfer');
                                this.$emit('success');
                            } else {
                                this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>
