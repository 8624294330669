<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block py-2 text-center font-weight-bold primary warning--text" style="word-break: normal">MIS CONTACTOS FRECUENTES</v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getReceivers">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-btn color="primary" large block @click="addReceiver = true">
                            <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Nuevo Contacto
                        </v-btn>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ item.name ? (item.name).toLowerCase() : '' }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed class="ma-1" title="Editar" @click="edit(item.id)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed class="ma-1" title="Borrar" @click="trash(item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="addReceiver" n fullscreen hide-overlay transition="dialog-bottom-transition">
                    <add v-on:close-add="closeAdd" @success="getReceivers"/>
                </v-dialog>
                <v-dialog v-model="editReceiver" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <edit v-on:close-edit="closeEdit" :receiver="receiver" @success="getReceivers"/>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Add from "./receivers/Add";
    import Edit from "./receivers/Edit";

    export default {
        name: "Receivers",
        components: { Add, Edit },
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'Nombres', value: 'name' },
                { text: 'Documento', value: 'document' },
                { text: 'Detalle', value: 'bank_details' },
                { text: 'País', value: 'country_text' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            expanded: [],
            loading: true,
            noDataText: 'No tienes contactos registrados',
            expandIcon: 'mdi-chevron-down-circle',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            addReceiver: false,
            editReceiver: false,
            receiver: null
        }),
        created () {
            this.getReceivers();
        },
        methods: {
            getReceivers() {
                this.loading = true;
                axios.get('/api/receivers').then((res) => {
                    this.desserts = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            closeAdd() {
                this.addReceiver = false;
                this.receiver = null;
            },
            closeEdit() {
                this.editReceiver = false;
                this.receiver = null;
            },
            edit(id) {
                this.receiver = id;
                this.editReceiver = true;
            },
            trash(id) {
                this.$swal({
                    title: '¿Esta seguro de eliminar esta cuenta?',
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/receivers/delete/' + id)
                            .then(res => {
                                if (res.data.v === 'success') {
                                    this.getReceivers();
                                    this.$swal({
                                        title: '¡Eliminado!',
                                        text: 'La cuenta se ha eliminado exitosamente',
                                        type: 'success',
                                        showConfirmButton: false,
                                        timer: 5000
                                    });
                                } else {
                                    this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                                }
                            })
                            .catch(err => {
                                this.handleErrors(err.response.data.errors);
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
