<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">REGISTRO DE OFICINAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-row justify="center" class="mx-0">
                        <v-col cols="12">
                            <v-btn color="primary" large block depressed @click="get" style="margin: 1px">
                                <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Agregar Oficina</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.name="{ item }">
                            <strong>{{ item.name }}</strong>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <v-icon :class="item.status === 1 ? 'success--text' : 'error--text'">{{ item.status === 1 ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                            <span :class="item.status === 1 ? 'success--text' : 'error--text'">&nbsp;&nbsp;{{ item.status === 1 ? 'Visible' : 'Oculto' }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="tertiary" dark small depressed @click="get(item.id)" style="margin: 1px">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">{{ form.id ? 'Editar ' + form.name : 'Agregar Oficina' }}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="8" class="text-center">
                                        <img src="/img/no-image.png" width="318px" height="270px" alt="imagen" style="border: 1px solid #eee; border-radius: 4px;" v-if="imgData === null || imgData === 'null'"/>
                                        <img :src="imgData" width="318px" height="270px" alt="imagen" style="border: 1px solid #eee; border-radius: 4px;" v-else/>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="6">
                                        <v-file-input
                                            :label="labels.img"
                                            v-model="form.img"
                                            name="img"
                                            accept="image/*"
                                            :error-messages="errors.img"
                                            :disabled="loading"
                                            prepend-icon="mdi-panorama"
                                            @input="clearErrors('img')"
                                            @click:clear="clearImg"
                                            show-size counter/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="labels.name"
                                            v-model="form.name"
                                            type="text"
                                            name="name"
                                            :counter="120"
                                            :error-messages="errors.name"
                                            :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 120, 'El')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-office-building"
                                            @input="clearErrors('name')"/>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="labels.address"
                                            v-model="form.address"
                                            type="text"
                                            name="address"
                                            :counter="240"
                                            :error-messages="errors.address"
                                            :rules="[rules.req('address'), rules.min('address', 3, 'El'), rules.max('address', 240, 'El')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-map-marker"
                                            @input="clearErrors('address')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            :label="labels.city"
                                            v-model="form.city"
                                            type="text"
                                            name="city"
                                            :error-messages="errors.city"
                                            :rules="[rules.required('city'), rules.min('city', 3, 'El'), rules.max('city', 120, 'El')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-map-marker"
                                            @input="clearErrors('city')"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.country"
                                            :items="countries"
                                            name="country"
                                            v-model="form.country"
                                            :error-messages="errors.country"
                                            :disabled="loading"
                                            :rules="[rules.required('country')]"
                                            @select="clearErrors('country')"
                                            prepend-icon="mdi-map-marker"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code+'.png'" :alt="item.name.toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code+'.png'" :alt="item.name.toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                            :label="labels.status"
                                            :items="options"
                                            name="status"
                                            v-model="form.status"
                                            :error-messages="errors.status"
                                            :disabled="loading"
                                            :rules="[rules.required('status')]"
                                            @select="clearErrors('status')"
                                            prepend-icon="mdi-eye"
                                            item-text="name"
                                            item-value="value">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="labels.gmaps"
                                            v-model="form.gmaps"
                                            type="text"
                                            name="gmaps"
                                            :error-messages="errors.gmaps"
                                            :rules="[rules.required('gmaps'), rules.min('gmaps', 3, 'El'), rules.max('gmaps', 120, 'El')]"
                                            :disabled="loading"
                                            prefix="https://maps.app.goo.gl/"
                                            prepend-icon="mdi-google-maps"
                                            @input="clearErrors('gmaps')"/>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            :label="labels.currencies"
                                            v-model="form.currencies"
                                            type="text"
                                            name="currencies"
                                            :error-messages="errors.currencies"
                                            :rules="[rules.required('currencies'), rules.min('currencies', 3, 'Las'), rules.max('currencies', 255, 'Las')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-hand-coin"
                                            @input="clearErrors('currencies')"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success" :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ form.id ? 'Actualizar' : 'Guardar' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "configOffices",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Dirección', value: 'address' },
                { text: 'Ciudad', value: 'city' },
                { text: 'Pais', value: 'country' },
                { text: 'Monedas', value: 'currencies' },
                { text: 'Estado', value: 'status', filterable: false, sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes oficinas registradas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            dialog: false,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'México', code: 'MEX' },
                { name: 'Panamá', code: 'PAN' },
                { name: 'Perú', code: 'PER' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            options: [
                { name: 'Visible', value: '1' },
                { name: 'Oculto', value: '0' }
            ],
            labels: {
                img: 'Imagen',
                name: 'Nombre',
                address: 'Dirección',
                city: 'Ciudad',
                country: 'País',
                status: 'Estado',
                gmaps: 'Link Google Maps',
                currencies: 'Monedas'
            },
            form: {
                id: null,
                img: null,
                name: null,
                address: null,
                city: null,
                country: null,
                status: null,
                gmaps: null,
                currencies: null
            },
            imgData: null
        }),
        created() {
            this.getData();
        },
        watch: {
          'form.img'() {
              if (this.form.img) {
                  this.getImg();
              }
          }
        },
        methods: {
            getData(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/settings/offices').then((res) => {
                    if (res.data.offices) {
                        this.desserts = res.data.offices;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getImg() {
                const input = this.form.img;

                if (input) {
                    const reader = new FileReader;
                    reader.onload = e => {
                        this.imgData = e.target.result;
                    };
                    reader.readAsDataURL(input);
                }
            },
            clearImg() {
                this.imgData = null;
                this.form.img = null;
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form.id = items[i].id;
                            this.form.img = null;
                            this.form.name = items[i].name;
                            this.form.address = items[i].address;
                            this.form.city = items[i].city;
                            this.form.country = items[i].country;
                            this.form.status = items[i].status ? (items[i].status).toString() : '0';
                            this.form.gmaps = items[i].gmaps;
                            this.form.currencies = items[i].currencies;
                            this.imgData = '/'+items[i].img;
                        }
                    }
                } else {
                    this.close();
                }
                this.dialog = true;
            },
            close(v) {
                if (v === 1) { this.dialog = false; }
                this.form = {
                    id: null,
                    name: null,
                    address: null,
                    city: null,
                    country: null,
                    gmaps: null,
                    img: null,
                    status: null,
                    currencies: null
                };
                this.imgData = null;
                this.$refs.form.reset();
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    let url = '/api/settings/offices/add';

                    if (this.form.id) {
                        url = '/api/settings/offices/update';
                        formData.append('id', this.form.id);
                    }
                    if (this.form.img) {
                        formData.append('image', this.form.img);
                    }
                    formData.append('name', this.form.name);
                    formData.append('address', this.form.address);
                    formData.append('city', this.form.city);
                    formData.append('country', this.form.country);
                    formData.append('status', this.form.status);
                    formData.append('gmaps', this.form.gmaps);
                    formData.append('currencies', this.form.currencies);

                    axios.post(url, formData, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    }).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                            this.getData();
                            this.close(1);
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
