import { render, staticRenderFns } from "./Completed.vue?vue&type=template&id=bddcab6e&scoped=true"
import script from "./Completed.vue?vue&type=script&lang=js"
export * from "./Completed.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bddcab6e",
  null
  
)

export default component.exports